import React from 'react';

const SvgIconCardDefault = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <g transform="translate(11 11)" fill="none" fillRule="evenodd">
      <circle fill="#FB8F23" cx={19} cy={19} r={19} />
      <path
        d="M27.55 22.7a2 2 0 01-2 2h-13.1a2 2 0 01-2-2v-4.65h17.1v4.65zm-17.1-7.4a2 2 0 012-2h13.1a2 2 0 012 2v1.8h-17.1v-1.8z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgIconCardDefault;
