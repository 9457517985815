import React from 'react';

const SvgFacilityTowel = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round" fill="none" fillRule="evenodd">
      <path d="M8 3h10a2 2 0 012 2v15a1 1 0 01-1 1H7a1 1 0 01-1-1v-1m-1-2.5h12M5 14h12" />
      <path d="M18.558 5H18a1 1 0 00-1 1v12h0a1 1 0 01-1 1H5.5a1 1 0 01-1-1V5a2 2 0 012-2H15M2.419 7H4m13 0h4.528" />
    </g>
  </svg>
);

export default SvgFacilityTowel;
