import React from 'react';

const SvgPaymentRefundableFee = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.89 3.09H0V.77C0 .357.35.008.763.008h14.364c.222 0 .413.095.54.254a.679.679 0 01.254.54V3.09h-.032zM0 4.615V13.1c0 .223.095.414.222.572.128.128.35.255.54.255h14.365c.413 0 .762-.35.762-.763V4.615H0zm12.044 5.435H3.432l3.432-3.464v1.938h5.148l.032 1.526z"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgPaymentRefundableFee;
