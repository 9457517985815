import React from 'react';

const SvgRdAddress = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.463 2.4l-.2.016-.188.047a1.145 1.145 0 00-.766 1.075L4.306 30.47l-.752.001-.215.018c-.532.095-.94.564-.94 1.12 0 .625.514 1.139 1.14 1.139h28.906l.215-.018c.528-.094.933-.556.94-1.105l-.016-.2-.047-.187a1.145 1.145 0 00-1.075-.767l-.616-.001.002-18.336-.016-.2-.047-.188a1.145 1.145 0 00-1.075-.767l-7.285-.003.002-7.42-.017-.215a1.145 1.145 0 00-1.121-.94H5.463zm1.122 2.276H21.17v25.791H6.582l.003-25.79zm4.043 4.932l-.195.016a.999.999 0 00-.825.988 1 1 0 001.004 1.004h6.519l.195-.016a.999.999 0 00.824-.988 1 1 0 00-1.003-1.004h-6.52zm12.797 3.643l6.145.003v17.213h-6.145V13.251zm-12.797 1.972l-.195.015a.999.999 0 00-.825.988 1 1 0 001.004 1.004h6.519l.195-.016a.999.999 0 00.824-.988 1 1 0 00-1.003-1.003h-6.52zm0 5.614l-.195.015a.999.999 0 00-.825.988 1 1 0 001.004 1.003h6.519l.195-.015a.999.999 0 00.824-.988 1 1 0 00-1.003-1.003h-6.52z"
    />
  </svg>
);

export default SvgRdAddress;
