import React from 'react';

const SvgIconMore = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M24.259 14.238h-1.65c-.358 0-.699.172-.91.463l-3.698 5.112-3.699-5.111a1.121 1.121 0 00-.91-.464h-1.65c-.228 0-.361.26-.228.446l6.258 8.648a.28.28 0 00.454 0l6.258-8.648a.28.28 0 00-.226-.446z"
      fillOpacity={0.85}
    />
    <path
      d="M18 2.25C9.302 2.25 2.25 9.302 2.25 18S9.302 33.75 18 33.75 33.75 26.698 33.75 18 26.698 2.25 18 2.25zm0 28.828c-7.221 0-13.078-5.857-13.078-13.078 0-7.221 5.857-13.078 13.078-13.078 7.221 0 13.078 5.857 13.078 13.078 0 7.221-5.857 13.078-13.078 13.078z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgIconMore;
