import moment from 'moment';
import getConfig from 'next/config';
import { BillingCycleOptions } from '~graphql/__generated__/globalTypes';

type arrayKey = {
  [key: string]: boolean;
};

/**
 * Turn hump into a underline form
 *
 * example:
 * 'iconTraffic' => 'icon_traffic'
 *
 * @param {String} value
 * @return {String}
 */
export function transformHumpToUnderline(value: string) {
  return value.replace(/\B([A-Z])/g, '_$1').toLowerCase();
}

export function unique(array: Array<any>) {
  let obj: arrayKey = {};

  return array.filter(item => {
    return obj.hasOwnProperty(`${typeof item}${JSON.stringify(item)}`)
      ? false
      : (obj[`${typeof item}${JSON.stringify(item)}`] = true);
  });
}

export function notEmpty<TValue>(value: TValue | null | undefined | ''): value is TValue {
  return value !== null && value !== undefined && value !== '';
}

export function toHump(name: string) {
  return name.replace(/\_(\w)/g, function(all, letter) {
    return letter.toUpperCase();
  });
}

export function mousewheel(obj: any, downfn: Function, upfn: Function) {
  if (!obj) return;
  obj.onmousewheel = fn;
  if (obj.addEventListener) {
    obj.addEventListener('DOMMouseScroll', fn, false);
  }
  function fn(e: WheelEvent) {
    // eslint-disable-next-line no-restricted-globals
    var ev = e || event;
    var b = true;
    if (ev.deltaY) {
      b = ev.deltaY > 0 ? false : true;
    } else {
      b = ev.detail < 0 ? true : false;
    }
    if (b) {
      upfn && upfn();
    } else {
      downfn && downfn();
    }
  }
}

export function formatToUpper(value: string | null) {
  if (!value) return '';
  if (value.indexOf('_') > -1) {
    let r = value.split('_');
    let n = r.map(item => {
      return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    });
    return n.join(' ');
  }
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function getBillingCycle(type: string | null | undefined) {
  if (!type) return '';
  if (type === BillingCycleOptions.DAILY) {
    return 'select_bed.billing_daily';
  } else if (type === BillingCycleOptions.WEEKLY) {
    return 'select_bed.billing_weekly';
  } else if (type === BillingCycleOptions.MONTHLY) {
    return 'select_bed.billing_monthly';
  }
}

export function ordinalNumber(n: number) {
  return n + (['st', 'nd', 'rd'][n < 20 ? n - 1 : (n % 10) - 1] || 'th');
}

export function getPropertyImageUrl(imageFileName: string | null | undefined) {
  const { publicRuntimeConfig } = getConfig();
  const imageSize = 'max_1000x672';
  return `${publicRuntimeConfig.IMAGE_SVC_URL}/${imageSize}/${imageFileName}`;
}

export function timeSlotLabel(timeSlotFrom: string, slotLength: any) {
  const SLOT_LENGTH = {
    ONE_HOUR: 'ONE_HOUR',
    HALF_HOUR: 'HALF_HOUR',
  };
  const [hour, minutes] = timeSlotFrom?.split(':');
  const hourNumber = parseInt(hour);
  const minutesNumber = parseInt(minutes);
  const slotLengthType = slotLength;
  const addMinutes = slotLengthType === SLOT_LENGTH.HALF_HOUR ? 30 : 0;

  if (slotLengthType === SLOT_LENGTH.ONE_HOUR) {
    if (hourNumber < 9) {
      return `0${hourNumber}:00 - 0${hourNumber + 1}:00`;
    }
    if (hourNumber === 23) {
      return `${hourNumber}:00 - 00:00`;
    }
    return `${hourNumber === 9 ? `0${hourNumber}` : hourNumber}:00 - ${hourNumber + 1}:00`;
  }

  if (slotLengthType === SLOT_LENGTH.HALF_HOUR) {
    const afterAddMinutes = minutesNumber + addMinutes;
    const addHoursByAfterAddMinutes = afterAddMinutes === 60 ? 1 : 0;
    const halfHourNumber = hourNumber + addHoursByAfterAddMinutes;

    const transformMinShowLabelFrom = minutesNumber < 9 ? `0${minutesNumber}` : minutesNumber;
    const transformMinShowLabelTo = afterAddMinutes === 60 ? '00' : afterAddMinutes;

    const transformHourShowLabelFrom = hourNumber < 9 ? `0${hourNumber}` : hourNumber;
    const transformHourShowLabelTo = halfHourNumber < 9 ? `0${halfHourNumber}` : halfHourNumber;

    return `${transformHourShowLabelFrom}:${transformMinShowLabelFrom} - ${transformHourShowLabelTo}:${transformMinShowLabelTo}`;
  }
}

export function changeURLParamter(destiny: string, par: string, par_value: string) {
  var pattern = par + '=([^&]*)';
  var replaceText = par + '=' + par_value;
  if (destiny.match(pattern)) {
    var tmp = '/\\' + par + '=[^&]*/';
    // eslint-disable-next-line no-eval
    tmp = destiny.replace(eval(tmp), replaceText);
    return tmp;
  } else {
    if (destiny.match('[?]')) {
      return destiny + '&' + replaceText;
    } else {
      return destiny + '?' + replaceText;
    }
  }
}

export function toLowerCaseAndTrim(v: string) {
  if (!v) return '';
  return v
    .toLowerCase()
    .split(' ')
    .join('');
}

export function getDecodeId(v: string | undefined | null) {
  if (!v) return '';
  const r = JSON.parse(window.atob(v));

  return r?.id || '';
}

export function accSub(arg1: number, arg2: number) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

export function addSub(arg1: number, arg2: number) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m + arg2 * m) / m).toFixed(n);
}

export function getApiFormatDate(rangeDate: string) {
  const [start, end] = rangeDate.split(' - ');
  return [moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')];
}

export function getFormatDate(from: string, to: string) {
  return [moment(from).format('ll'), moment(to).format('ll')].join(' - ');
}

export function getInputFormatDate(from: string, to: string) {
  if (!from || !to) return;
  return [moment(from).format('ddd, ll'), moment(to).format('ddd, ll')].join(' - ');
}

export function removeAllSpaces(str: string | null | undefined) {
  if (!str) return '';
  return str.replace(/\s+/g, '');
}
