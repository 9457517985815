import React from 'react';

const SvgFacilityDoor = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round" fill="none" fillRule="evenodd">
      <path d="M18.5 16.53V18a1 1 0 01-1 1h-3.074M4 18V4a1 1 0 011-1h12.5a1 1 0 011 1v12.615" />
      <path d="M5.28 3.039l8.36 2.442a.5.5 0 01.36.48v14.54a.5.5 0 01-.64.48l-8.64-2.525a1 1 0 01-.72-.96V4a1 1 0 011.28-.96zm6.74 8.921v3" />
    </g>
  </svg>
);

export default SvgFacilityDoor;
