import React from 'react';

const SvgProfileTip = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <path
        d="M13.921.032C6.24.032.01 6.258.01 13.942c0 7.683 6.231 13.912 13.912 13.912 7.686 0 13.912-6.229 13.912-13.912 0-7.684-6.226-13.91-13.912-13.91z"
        fill="#FFF02C"
      />
      <path
        d="M16.103 19.062c-1.05 1.575-2.117 2.792-3.915 2.792-1.228-.2-1.729-1.08-1.463-1.975l2.312-7.657c.057-.187-.039-.388-.209-.449-.17-.059-.505.164-.797.48l-1.393 1.68c-.04-.28-.004-.749-.004-.936 1.049-1.576 2.773-2.82 3.94-2.82 1.115.114 1.642 1.002 1.446 1.98l-2.325 7.691c-.035.175.06.351.217.408.17.058.532-.164.82-.48l1.397-1.68c.039.28-.026.777-.026.966zm-.31-10.001c-.883 0-1.602-.643-1.602-1.592 0-.947.719-1.592 1.603-1.592.883 0 1.598.645 1.598 1.592 0 .95-.715 1.592-1.598 1.592z"
        fill="#4C4C4C"
      />
    </g>
  </svg>
);

export default SvgProfileTip;
