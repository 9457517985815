import React from 'react';

const SvgYugoLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 259" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M63.668 203.168c1.455 1.84 2.737 4.184 3.781 6.85 8.003-2.968 16.493-3.781 24.652-1.064 19.015 6.329 32.144 25.372 35.984 49.602a131.426 131.426 0 0019.138-1.147c.301-9.205 2.681-19.828 9.66-29.968 26.73-38.837 67.716-16.982 98.784-66.442a129.08 129.08 0 003.938-31.721c0-14.846-2.514-29.108-7.138-42.385-48.84-11.358-110.745-55.925-99.372-84.82a131.07 131.07 0 00-23.292-2.077c-5.543 0-11.004.348-16.364 1.018 12.423 15.37 12.645 40.854-9.977 45.73-14.386 3.1-30.347-1.446-47.56 1.474-.73 3.477-1.178 7.253-1.322 11.407-.704 20.366-8.944 33.97-28.667 37.92-15.08 3.021-22.183 10.78-25.32 19.296A130.46 130.46 0 000 128.63v1.311a129.162 129.162 0 002.966 26.97l.005.026c.112.51.226 1.016.343 1.523l.024.1.15.63c11.304 24.527 41.504 20.393 60.18 43.978zm91.798-14.942c-9.11 0-16.523-7.385-16.523-16.46 0-9.077 7.412-16.46 16.523-16.46s16.524 7.383 16.524 16.46c0 9.075-7.413 16.46-16.524 16.46zm49.587-80.687c12.148 0 22.031 9.845 22.031 21.947 0 12.102-9.883 21.946-22.03 21.946-12.149 0-22.032-9.844-22.032-21.946 0-12.102 9.883-21.947 22.031-21.947zm-49.587 0h24.789v10.975h-5.715a21.737 21.737 0 012.957 10.972c0 12.102-9.883 21.946-22.03 21.946-12.149 0-22.032-9.844-22.032-21.946 0-12.102 9.883-21.947 22.031-21.947zm-71.625-.002h11.015v21.949c0 6.05 4.942 10.974 11.016 10.974 6.073 0 11.015-5.15 11.015-11.2v-21.723h11.016v21.949c0 12.102-9.883 21.946-22.03 21.946-12.149 0-22.032-9.844-22.032-21.946v-21.949zm-50.262.002h11.015v10.974c0 6.05 4.942 10.973 11.016 10.973s11.015-5.15 11.015-11.2v-10.747h11.016v10.974c0 10.208-7.032 18.809-16.524 21.252v11.667H50.102v-11.667c-9.492-2.443-16.523-11.044-16.523-21.252v-10.974z"
        fill="#402E52"
      />
      <path
        d="M155.466 166.279c-3.037 0-5.508 2.462-5.508 5.487s2.471 5.488 5.508 5.488 5.508-2.463 5.508-5.488c0-3.025-2.47-5.487-5.508-5.487m49.587-25.819c6.074 0 11.016-4.924 11.016-10.974s-4.942-10.973-11.016-10.973-11.015 4.923-11.015 10.973c0 6.05 4.941 10.973 11.015 10.973m-49.477.001c6.074 0 11.015-4.923 11.015-10.974 0-6.05-4.94-10.973-11.015-10.973-6.074 0-11.016 4.923-11.016 10.973 0 6.051 4.942 10.973 11.016 10.973"
        fill="#402E52"
      />
      <path
        d="M156.883 227.441c-6.98 10.141-9.36 20.764-9.66 29.968 52.852-7.062 95.627-45.8 108.444-96.41-31.067 49.46-72.054 27.606-98.784 66.442"
        fill="#FF8C9C"
      />
      <path
        d="M9.07 81.703A128.072 128.072 0 00.592 116.84c3.137-8.517 10.241-16.276 25.321-19.295 19.723-3.95 27.962-17.556 28.667-37.92.144-4.154.591-7.932 1.321-11.408-14.757 2.502-30.434 10.49-46.83 33.485m34.611 144.314c6.57-6.736 14.867-12.698 23.766-15.998-1.043-2.665-2.325-5.011-3.78-6.85-18.676-23.583-48.876-19.451-60.18-43.978 6.048 25.694 20.36 49.364 40.194 66.826"
        fill="#2940EB"
      />
      <path
        d="M153.095 2.074c-11.373 28.893 50.532 73.461 99.372 84.818a128.039 128.039 0 00-6.352-15.07c-17.927-35.93-52.141-62.372-93.02-69.748"
        fill="#FF544A"
      />
      <path
        d="M92.1 208.953c-8.158-2.717-16.648-1.903-24.651 1.065-8.9 3.3-17.196 9.262-23.767 15.998a130.193 130.193 0 0022.975 16.249c18.223 10.125 39.148 16.003 61.428 16.29-3.84-24.228-16.97-43.272-35.984-49.602"
        fill="#FFC442"
      />
      <path
        d="M113.44 1.015A129.476 129.476 0 0092.22 5.5C54.212 16.933 23.563 45.254 9.07 81.703 25.467 58.71 41.144 50.721 55.901 48.218c17.214-2.92 33.174 1.626 47.561-1.474 22.621-4.876 22.4-30.361 9.977-45.73"
        fill="#4FE0BF"
      />
      <path
        d="M205.053 151.433c12.148 0 22.031-9.845 22.031-21.947 0-12.101-9.883-21.946-22.031-21.946s-22.031 9.845-22.031 21.946c0 12.102 9.883 21.947 22.031 21.947m0-32.92c6.074 0 11.015 4.922 11.015 10.973 0 6.05-4.941 10.973-11.015 10.973-6.074 0-11.016-4.922-11.016-10.973 0-6.05 4.942-10.974 11.016-10.974m-99.181 32.921c12.148 0 22.03-9.845 22.03-21.947V107.54h-11.015v21.72c0 6.05-4.941 11.2-11.015 11.2-6.074 0-11.016-4.922-11.016-10.973V107.54H83.84v21.947c0 12.102 9.883 21.947 22.03 21.947m-55.768-11.669v11.668h11.016v-11.668c9.49-2.443 16.523-11.045 16.523-21.253V107.54H66.625v10.745c0 6.051-4.941 11.201-11.015 11.201-6.074 0-11.016-4.923-11.016-10.974V107.54H33.579v10.972c0 10.208 7.032 18.81 16.523 21.253m105.364 15.541c-9.11 0-16.523 7.383-16.523 16.46 0 9.075 7.412 16.46 16.523 16.46s16.523-7.385 16.523-16.46c0-9.077-7.412-16.46-16.523-16.46m0 21.946c-3.037 0-5.508-2.46-5.508-5.487 0-3.025 2.471-5.485 5.508-5.485s5.508 2.46 5.508 5.485c0 3.027-2.47 5.487-5.508 5.487m.001-25.819c12.148 0 22.03-9.845 22.03-21.947a21.75 21.75 0 00-2.956-10.974h5.714V107.54h-24.788c-12.148 0-22.031 9.845-22.031 21.946 0 12.102 9.883 21.947 22.03 21.947m.11-32.92c6.075 0 11.016 4.923 11.016 10.973 0 6.05-4.941 10.973-11.015 10.973-6.074 0-11.016-4.922-11.016-10.973 0-6.05 4.942-10.974 11.016-10.974"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default SvgYugoLogo;
