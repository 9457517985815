import React from 'react';

const SvgIconEyeHide = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.243 9.68c1.949 1.877 3.63 4.304 5.042 7.28a2.422 2.422 0 010 2.069c-3.8 8.044-9.562 12.066-17.285 12.066-2.786 0-5.317-.526-7.594-1.578l2.215-2.215c1.64.6 3.433.9 5.38.9 6.484 0 11.23-3.287 14.572-10.206-1.256-2.595-2.708-4.68-4.375-6.272l2.045-2.045zm.775-7.318l1.713 1.715a.322.322 0 010 .455L4.118 33.144a.322.322 0 01-.455 0l-1.715-1.716a.322.322 0 010-.454l4.252-4.25c-2.138-1.93-3.966-4.493-5.485-7.691a2.423 2.423 0 010-2.07C4.516 8.92 10.278 4.899 18 4.899c3.023 0 5.746.619 8.169 1.856l4.394-4.392a.321.321 0 01.455 0zM18 7.791c-6.48 0-11.226 3.287-14.573 10.205 1.367 2.824 2.966 5.042 4.82 6.68l3.352-3.352a7.071 7.071 0 019.568-9.568l2.825-2.824C22.184 8.167 20.192 7.79 18 7.79zm-4.17 8.167a4.504 4.504 0 00-.277 3.412l5.66-5.66a4.504 4.504 0 00-5.384 2.248zm3.598 6.526a4.522 4.522 0 002.135-.323 4.497 4.497 0 002.758-4.57l2.122-2.122a7.074 7.074 0 01-9.137 9.137l2.122-2.122z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgIconEyeHide;
