import React from 'react';

const SvgIconCheckinDisabled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 38" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M32.51 34.301c-.897.834-2.114 1.303-3.382 1.303H10.001c-2.641 0-4.782-1.992-4.782-4.449V18.39H33.91v12.766c0 1.18-.504 2.312-1.401 3.146z"
        fill="#d9d9d9"
      />
      <path
        d="M24.706.266a1.08 1.08 0 011.081 1.08v1.35h5.653a4.05 4.05 0 014.046 3.849l.005.202v25.485a4.05 4.05 0 01-4.05 4.05H4.998a4.05 4.05 0 01-4.051-4.05V6.747a4.05 4.05 0 014.05-4.05l6.375-.001v-1.35a1.081 1.081 0 112.163 0l-.001 1.35h10.09v-1.35c0-.596.484-1.08 1.081-1.08zm8.759 16.006H2.973v15.96c0 1.068.826 1.943 1.874 2.02l.152.005H31.44a2.025 2.025 0 002.026-2.025l-.001-15.96zM31.44 4.722l-5.653-.001v2.391a1.081 1.081 0 11-2.162 0V4.721h-10.09v2.391a1.081 1.081 0 11-2.161 0l-.001-2.391H4.999a2.025 2.025 0 00-2.02 1.875l-.006.15v7.22h30.492v-7.22a2.025 2.025 0 00-1.874-2.019l-.15-.005z"
        fill="#d9d9d9"
      />
      <path
        d="M19.02 28.055v-.202c0-.346.072-.648.216-.937.13-.259.317-.504.577-.72.691-.606 1.11-.995 1.24-1.139.345-.461.532-1.052.532-1.773 0-.879-.288-1.57-.864-2.075-.577-.52-1.34-.764-2.278-.764-1.066 0-1.902.302-2.522.908-.634.605-.937 1.441-.937 2.508h1.643c0-.606.115-1.081.36-1.413.274-.403.721-.59 1.355-.59.49 0 .88.13 1.153.403.26.274.404.649.404 1.124 0 .36-.13.706-.39 1.024l-.172.201c-.937.836-1.5 1.442-1.686 1.83-.202.39-.289.866-.289 1.413v.202h1.658zm-.836 2.883c.317 0 .576-.101.793-.303.216-.202.331-.475.331-.793 0-.317-.115-.576-.317-.778a1.146 1.146 0 00-.807-.303 1.11 1.11 0 00-.793.303c-.216.202-.317.461-.317.778 0 .318.1.577.317.779.216.202.476.317.793.317z"
        fill="#F4F6F7"
      />
    </g>
  </svg>
);

export default SvgIconCheckinDisabled;
