import React from 'react';

const SvgFacilityBreakfast = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round" fill="none" fillRule="evenodd">
      <path d="M2 6h20v12H2z" />
      <path d="M5 10h6v4H5zm2.896.01v1.222" />
    </g>
  </svg>
);

export default SvgFacilityBreakfast;
