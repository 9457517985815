import React from 'react';

const SvgIconPaymentCard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FD8649" cx={20} cy={20} r={20} />
      <path
        d="M29 18H11v-2c0-1.1.9-2 2-2h14a2 2 0 012 2v2zm0 1v5a2 2 0 01-2 2H13a2 2 0 01-2-2v-5h18z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgIconPaymentCard;
