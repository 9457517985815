import React from 'react';

const SvgIconCautionAuth = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#icon-caution-auth_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.77 6.1a19.66 19.66 0 100 39.34 19.66 19.66 0 100-39.34z"
        fill="#FFC543"
      />
      <path
        d="M24.82 47.2A23.2 23.2 0 013.69 12.77a1.46 1.46 0 012.56 1.42 20.28 20.28 0 106.73-7.22 1.47 1.47 0 01-1.59-2.46A23.2 23.2 0 1124.81 47.2z"
        fill="#3F2D51"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.24 14.04l-.74 14.93h-2.49l-.73-14.93h3.96zm-.35 20.33c-.47.4-1 .61-1.65.61-.64 0-1.17-.23-1.58-.64a1.98 1.98 0 01-.68-1.59c0-.64.21-1.17.68-1.58.41-.41.94-.62 1.58-.62.65 0 1.2.2 1.65.62.4.41.64.94.64 1.58 0 .65-.23 1.2-.64 1.62z"
        fill="#3F2D51"
      />
    </g>
    <defs>
      <clipPath id="icon-caution-auth_svg__a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgIconCautionAuth;
