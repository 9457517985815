import { ModalActionType, ModalMessageType, SHOWMODAL, HIDEMODAL } from './types';

export const displayLoginModal = (message: ModalMessageType): ModalActionType => ({
  type: SHOWMODAL,
  message,
});

export const disappearLoginModal = (): ModalActionType => ({
  type: HIDEMODAL,
});
