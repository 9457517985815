import React from 'react';

const SvgShareWait = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M18 2.25C9.302 2.25 2.25 9.302 2.25 18S9.302 33.75 18 33.75 33.75 26.698 33.75 18 26.698 2.25 18 2.25zm0 28.828c-7.221 0-13.078-5.857-13.078-13.078 0-7.221 5.857-13.078 13.078-13.078 7.221 0 13.078 5.857 13.078 13.078 0 7.221-5.857 13.078-13.078 13.078z"
      fillOpacity={0.85}
    />
    <path
      d="M24.142 22.448l-5.013-3.625v-8.701a.282.282 0 00-.282-.282h-1.69a.282.282 0 00-.282.282v9.682a.28.28 0 00.116.228l5.815 4.24a.284.284 0 00.394-.06l1.005-1.37a.28.28 0 00-.063-.394z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgShareWait;
