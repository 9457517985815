import React from 'react';

const SvgBackgroundPatternsDesktop = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={720} height={232} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#background-patterns-desktop_svg__a)">
      <rect width={720} height={232} rx={14} fill="#3F2D51" />
    </g>
    <mask
      id="background-patterns-desktop_svg__a"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={720}
      height={232}
    >
      <rect width={720} height={232} rx={14} fill="#fff" />
    </mask>
    <g mask="url(#background-patterns-desktop_svg__a)" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M158.791 301.682c22.084 110.544 271.164 116.828 259.192-18.587-9.482-65.487-24.66-75.065-73.454-71.849-40.108 2.643-36.872 44.741-86.481 55.944-34.638 7.822-110.442-18.907-99.257 34.492"
        fill="#FE544A"
      />
      <path
        d="M9.657-4.226c-.48 3.153-34.05 88.488 18.96 183.748 0 0 26.997 38.54-3.603 55.604s-62.492-10.551-62.492-10.551-33.008-34.957-39.447-92.487c-6.44-57.53-2.442-72.577-2.442-72.577L9.657-4.226z"
        fill="#2A41EA"
      />
      <path
        d="M-29.472 20.64C-95.374 2.754-142.433-37.961-161.077-105.861c-14.834-54.028-1.934-103.172 40.16-141.593 43.77-39.95 96.38-50.731 152.366-33.886 56.394 16.968 97.33 53.563 118.447 109.631C190.613-63.596 109.053 38.655-6.163 24.202c-7.253-.91-14.458-2.2-23.31-3.562"
        fill="#4FE0BE"
      />
      <path
        d="M748.959 181.779s-39.872-10.879-65.997 10.413c-26.125 21.293-49.697 83.881-45.742 111.64 3.955 27.759 25.298 43.775 48.964 22.059 30.208-27.718 23.525-48.84 70.559-54.409 31.132-3.686 57.736-62.548-7.784-89.703"
        fill="#FF8C9C"
      />
      <path
        d="M708.724 68.178c.184.163.395.306.628.434 4.459 3.138 30.717 22.587 13.098 33.711-19.205 12.126-16.854 29.385-4.881 37.482 0 0 13.706 10.831 8.945 23.831-4.761 12.999-1.739 25.542 11.697 21.63 13.436-3.911 49.854-15.782 52.85-53.224 2.996-37.444-2.414-58.945 4.596-76.16 7.01-17.216 17.022-40.581 14.735-51.894 0 0 5.32-47.238-39.486-66.916C726.1-82.605 641.989-38.246 683.063 4.2c34.548 35.702 5.859 46.428 25.661 63.978"
        fill="#FFC543"
      />
      <path
        d="M362.923-6.532c-17.056-111.43 214.853-202.525 249.917-71.183 13.489 64.781 2.501 78.973-44.449 92.64-38.594 11.233-49.952-29.433-100.4-22.993-35.225 4.497-97.315 55.54-105.068 1.536"
        fill="#FE544A"
      />
      <path
        d="M28.863 412.341c-41.25-11.195-70.707-36.68-82.377-79.182-9.286-33.818-1.21-64.58 25.138-88.629 27.397-25.007 60.328-31.755 95.372-21.211 35.299 10.621 95.439-39.472 130.361 6.321 43.878 57.538-81.785 193.978-153.904 184.931-4.54-.569-9.05-1.376-14.59-2.23"
        fill="#4FE0BE"
      />
    </g>
    <defs>
      <filter
        id="background-patterns-desktop_svg__a"
        x={-16}
        y={-14}
        width={752}
        height={264}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={8} />
        <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.207843 0 0 0 0 0.196078 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_659_38657" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_659_38657" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default SvgBackgroundPatternsDesktop;
