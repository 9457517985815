import React from 'react';

const SvgRulesPetYes = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <circle fill="#EBEAED" cx={12} cy={12} r={12} />
      <path
        d="M12 10.08c1.68 0 3.15 1.6 4.56 4.65a2.07 2.07 0 01-2.29 2.9l-1.75-.35a2.7 2.7 0 00-1.04 0l-1.75.35a2.07 2.07 0 01-2.29-2.9c1.4-3.05 2.88-4.65 4.56-4.65zm0 1.5c-.9 0-2.01 1.21-3.2 3.77a.58.58 0 00.64.8l1.75-.34c.53-.1 1.09-.1 1.62 0l1.75.35a.57.57 0 00.63-.8c-1.18-2.57-2.29-3.78-3.19-3.78zm-4.83-1.8c.76 0 1.38.77 1.38 1.72 0 .96-.62 1.73-1.38 1.73-.76 0-1.38-.77-1.38-1.73 0-.95.62-1.72 1.38-1.72zm9.66 0c.76 0 1.38.77 1.38 1.72 0 .96-.62 1.73-1.38 1.73-.76 0-1.38-.77-1.38-1.73 0-.95.62-1.72 1.38-1.72zm-6.9-3.45c.76 0 1.38.77 1.38 1.72 0 .96-.62 1.73-1.38 1.73-.76 0-1.38-.77-1.38-1.73 0-.95.62-1.72 1.38-1.72zm4.14 0c.76 0 1.38.77 1.38 1.72 0 .96-.62 1.73-1.38 1.73-.76 0-1.38-.77-1.38-1.73 0-.95.62-1.72 1.38-1.72z"
        fill="#3F2D51"
      />
    </g>
  </svg>
);

export default SvgRulesPetYes;
