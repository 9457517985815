import React from 'react';

const SvgFacilityDeskChair = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round" fill="none" fillRule="evenodd">
      <path d="M16.322 11.397c-.315.923-.666 1.386-1.055 1.389l-9.42.07" />
      <path d="M17.816 3.478L16 13.09l.933 7.557m-9.418-7.638l-1.83 7.678" />
    </g>
  </svg>
);

export default SvgFacilityDeskChair;
