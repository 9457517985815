import React from 'react';

const SvgIconEmpty = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 88 88" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#CCC" cx={44} cy={44} r={44} />
      <path fill="#F5F7F7" opacity={0.6} d="M24 38h40l10 15v10H14V53z" />
      <path
        d="M16.1 52l17.63.04v.17a10 10 0 1020 0v-.13l18.3.04a2 2 0 012 2v22.04A43.85 43.85 0 0144 88a43.84 43.84 0 01-29.95-11.77L14.09 54a2 2 0 011.85-2h.15z"
        fill="#FFF"
      />
      <path
        d="M61.62 23.12a1.25 1.25 0 011.76 1.76l-6 6a1.25 1.25 0 01-1.76-1.76zm-37 0a1.25 1.25 0 011.76 0l6 6a1.25 1.25 0 01-1.76 1.76l-6-6a1.25 1.25 0 010-1.76zM44 17.75c.69 0 1.25.56 1.25 1.25v9a1.25 1.25 0 01-2.5 0v-9c0-.69.56-1.25 1.25-1.25z"
        fill="#FAFAFA"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgIconEmpty;
