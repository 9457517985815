import React from 'react';

const SvgRulesDisableNoRoom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.435 2.4c-2.42.027-4.427 1.696-4.77 3.894-.343 2.193 1.064 4.323 3.368 4.997l.226.066 2.211 9.79v.001c.135.576.69 1.015 1.364 1.017h11.078l3.313 9.04c.24.662 1.03 1.05 1.783.811l4.43-1.4c.5-.138.863-.517.966-.968a1.228 1.228 0 00-.45-1.233 1.494 1.494 0 00-1.427-.23l-.007.002-3.082.972-3.206-8.688c-.178-.5-.688-.858-1.303-.858H14.978l-1.119-4.896h5.782c.784 0 1.388-.579 1.4-1.267l-.021-.198v-.002c-.071-.585-.647-1.107-1.38-1.107h-6.363l-.153-.937.287-.123c2.08-.893 3.198-3 2.737-5.061-.461-2.066-2.398-3.586-4.694-3.622h-.019zm-1.93 3.474c.434-.644 1.213-.985 1.997-.896 1.026.115 1.834.94 1.834 1.96 0 1.022-.808 1.846-1.834 1.962-.784.088-1.563-.252-1.998-.896a1.892 1.892 0 010-2.13zm-.729 9.734c-.38-.617-1.244-.846-1.928-.476-4.31 2.337-6.372 7.094-5.052 11.562 1.322 4.475 5.713 7.572 10.708 7.497 4.993-.074 9.276-3.3 10.446-7.81a1.222 1.222 0 00-.391-1.221 1.49 1.49 0 00-1.38-.308c-.488.136-.846.501-.958.94v.001c-.883 3.395-4.089 5.775-7.772 5.829-3.682.053-6.964-2.234-7.957-5.6-.994-3.373.573-6.933 3.774-8.669.672-.363.88-1.144.51-1.745z"
    />
  </svg>
);

export default SvgRulesDisableNoRoom;
