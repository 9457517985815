import React from 'react';

const SvgPaymentSuccessful = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 84 232"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <filter
        x="-12.2%"
        y="-9.4%"
        width="124.3%"
        height="126.4%"
        filterUnits="objectBoundingBox"
        id="payment-successful_svg__a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.164705882 0 0 0 0 0.164705882 0 0 0 0 0.164705882 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        id="payment-successful_svg__b"
        d="M70.74.016l-1.497 1.498L67.73 0l-1.514 1.514L64.703 0l-1.514 1.514L61.676 0l-1.514 1.514-1.53-1.498-1.497 1.498L55.622 0l-1.514 1.514L52.595 0 51.08 1.514 49.568 0l-1.514 1.514-1.53-1.498-1.497 1.498L43.514 0 42 1.514 40.486 0l-1.513 1.514L37.459 0l-1.513 1.514-1.53-1.498-1.497 1.498L31.405 0l-1.513 1.514L28.378 0l-1.513 1.514L25.35 0l-1.513 1.514-1.53-1.498-1.497 1.498L19.297 0l-1.513 1.514L16.27 0l-1.513 1.514L13.243 0v52.973h57.514V.031L70.74.016"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M15.514 226.56v-73.769a4.738 4.738 0 014.737-4.737H63.75a4.738 4.738 0 014.737 4.737v73.769a4.737 4.737 0 01-4.737 4.737H20.25a4.737 4.737 0 01-4.737-4.737"
        fill="#475E9F"
      />
      <path d="M26.108 224.865a4.162 4.162 0 110-8.325 4.162 4.162 0 010 8.325" fill="#8DA0CB" />
      <path d="M26.108 219.946a4.162 4.162 0 110-8.325 4.162 4.162 0 010 8.325" fill="#768CBE" />
      <path
        fill="#8E9FCC"
        d="M35.19 225.243h3.783v-14.378h-3.784zm0-18.919h3.783v-14.378h-3.784z"
      />
      <path
        fill="#2890C3"
        d="M35.19 187.405h3.783v-14.378h-3.784zm0-18.919h3.783v-14.378h-3.784z"
      />
      <path
        d="M76.302 187.784H7.698A7.698 7.698 0 010 180.086V49.049a7.698 7.698 0 017.698-7.698h68.604A7.698 7.698 0 0184 49.05v131.037a7.699 7.699 0 01-7.698 7.698"
        fill="#4C4C4C"
      />
      <path
        d="M84 123.081H0V48.92a7.568 7.568 0 017.568-7.568h68.864A7.568 7.568 0 0184 48.92v74.162"
        fillOpacity={0.2}
        fill="#2A2A2A"
      />
      <path
        d="M72.27 54.973H11.73a1.892 1.892 0 110-3.784h60.54a1.892 1.892 0 010 3.784"
        fill="#2A2A2A"
      />
      <path
        d="M70.378 64.81H13.622a3.784 3.784 0 00-3.784 3.785v35.567a3.784 3.784 0 003.784 3.784h56.756a3.784 3.784 0 003.784-3.784V68.595a3.784 3.784 0 00-3.784-3.784"
        fill="#42A66B"
      />
      <path
        fill="#FFF"
        d="M38.736 94.919l-8.053-8.054 2.675-2.676 5.378 5.379 11.906-11.906 2.675 2.676-14.58 14.58"
      />
      <path
        d="M24.595 118.162H12.486a2.649 2.649 0 110-5.297h12.109a2.649 2.649 0 110 5.297m23.46 0h-12.11a2.649 2.649 0 110-5.297h12.11a2.649 2.649 0 110 5.297m23.459 0H59.405a2.649 2.649 0 110-5.297h12.109a2.649 2.649 0 110 5.297m-46.541 17.406H12.108a2.27 2.27 0 01-2.27-2.27v-3.028a2.27 2.27 0 012.27-2.27h12.865a2.27 2.27 0 012.27 2.27v3.027a2.27 2.27 0 01-2.27 2.27m23.46 0H35.567a2.27 2.27 0 01-2.27-2.27v-3.027a2.27 2.27 0 012.27-2.27h12.864a2.27 2.27 0 012.27 2.27v3.027a2.27 2.27 0 01-2.27 2.27m23.46 0H59.027a2.27 2.27 0 01-2.27-2.27v-3.027a2.27 2.27 0 012.27-2.27h12.865a2.27 2.27 0 012.27 2.27v3.027a2.27 2.27 0 01-2.27 2.27M24.973 149.19H12.108a2.27 2.27 0 01-2.27-2.27v-3.027a2.27 2.27 0 012.27-2.27h12.865a2.27 2.27 0 012.27 2.27v3.027a2.27 2.27 0 01-2.27 2.27m23.46 0H35.567a2.27 2.27 0 01-2.27-2.27v-3.027a2.27 2.27 0 012.27-2.27h12.864a2.27 2.27 0 012.27 2.27v3.027a2.27 2.27 0 01-2.27 2.27m23.46 0H59.027a2.27 2.27 0 01-2.27-2.27v-3.027a2.27 2.27 0 012.27-2.27h12.865a2.27 2.27 0 012.27 2.27v3.027a2.27 2.27 0 01-2.27 2.27m-46.919 13.622H12.108a2.27 2.27 0 01-2.27-2.27v-3.027a2.27 2.27 0 012.27-2.27h12.865a2.27 2.27 0 012.27 2.27v3.027a2.27 2.27 0 01-2.27 2.27m23.46 0H35.567a2.27 2.27 0 01-2.27-2.27v-3.027a2.27 2.27 0 012.27-2.27h12.864a2.27 2.27 0 012.27 2.27v3.027a2.27 2.27 0 01-2.27 2.27m23.46 0H59.027a2.27 2.27 0 01-2.27-2.27v-3.027a2.27 2.27 0 012.27-2.27h12.865a2.27 2.27 0 012.27 2.27v3.027a2.27 2.27 0 01-2.27 2.27"
        fill="#999"
      />
      <path
        d="M24.973 177.946H12.108a2.27 2.27 0 01-2.27-2.27v-4.54a2.27 2.27 0 012.27-2.271h12.865a2.27 2.27 0 012.27 2.27v4.54a2.27 2.27 0 01-2.27 2.27"
        fill="#D85145"
      />
      <path
        d="M48.432 177.946H35.568a2.27 2.27 0 01-2.27-2.27v-4.54a2.27 2.27 0 012.27-2.271h12.864a2.27 2.27 0 012.27 2.27v4.54a2.27 2.27 0 01-2.27 2.27"
        fill="#FECE00"
      />
      <path
        d="M71.892 177.946H59.027a2.27 2.27 0 01-2.27-2.27v-4.54a2.27 2.27 0 012.27-2.271h12.865a2.27 2.27 0 012.27 2.27v4.54a2.27 2.27 0 01-2.27 2.27"
        fill="#3EA769"
      />
      <g transform="translate(0 2)">
        <use
          fill="#000"
          filter="url(#payment-successful_svg__a)"
          xlinkHref="#payment-successful_svg__b"
        />
        <use fill="#FFFFFE" xlinkHref="#payment-successful_svg__b" />
      </g>
      <path
        fill="#EBEBEB"
        d="M18.162 13.73h23.46v-2.27h-23.46zm39.352 0h8.324v-2.27h-8.324zm-39.352 6.81H44.65v-2.27H18.162zm39.352 0h8.324v-2.27h-8.324zm-39.352 6.811h15.892v-2.27H18.162zm39.352 0h8.324v-2.27h-8.324zm-39.352 6.811h29.514v-2.27H18.162zm39.352 0h8.324v-2.27h-8.324zm-39.352 6.811h23.46v-2.27h-23.46zm39.352 0h8.324v-2.27h-8.324z"
      />
    </g>
  </svg>
);

export default SvgPaymentSuccessful;
