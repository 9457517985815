import React from 'react';

const SvgStar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.5 12.75l-4.996 2.627.954-5.564-4.042-3.94 5.586-.811L8.5 0l2.498 5.062 5.586.811-4.042 3.94.954 5.564z"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgStar;
