import React from 'react';

const SvgFacilityLights = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path d="M12 10a8 8 0 00-8 8h16a8 8 0 00-8-8z" stroke="#494A47" />
      <path
        d="M8.21 3s0 .829.553 1.263c.44.346 1.376.842 3.237.842 1.86 0 2.797-.496 3.237-.842.552-.434.552-1.263.552-1.263H8.211z"
        stroke="#494A47"
        strokeLinecap="round"
      />
      <path
        d="M9.768 12.686S8.265 13 7.104 14.843M12 5.105V10m-2.37 9.446c.307.959 1.057 1.45 2.252 1.474 1.196.024 2.054-.458 2.576-1.444"
        stroke="#4B4B4C"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default SvgFacilityLights;
