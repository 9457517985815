import React from 'react';

const SvgProfileGender = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09 2l-.366.014a7.214 7.214 0 00-1.431 14.15l.425.106.227.284v2.704l-.297.297-3.183-.001c-.505.074-.887.46-.962.932l-.013.168.018.175c.069.503.46.892.948.97l.181.014h3.011l.297.297v3.092l.017.18c.076.496.466.878.95.953l.18.014h.165c.493-.058.884-.454.955-.957l.01-.168V22.11l.298-.297 3.123.001c.494-.073.875-.47.943-.977l.011-.17.001-.165a1.13 1.13 0 00-.96-.955l-.166-.012H10.52l-.297-.297v-2.645l.236-.288.07-.014A7.214 7.214 0 009.45 2.004L9.09 2zM4.688 6.673a5.078 5.078 0 118.795 5.078 5.078 5.078 0 01-8.795-5.078zm27.832 7.15v.002l-6.487-.002a1.146 1.146 0 00-.944.98l-.011.168-.002.158c.073.498.463.882.947.959l.181.014h2.673l.241.535-3.457 3.92-.391.046-.063-.045a7.214 7.214 0 103.03 6.252l.01-.353-.02-.399a7.218 7.218 0 00-.818-2.988l-.256-.459.032-.338 3.435-3.878.574.217-.003 2.745a1.139 1.139 0 002.244 0l.016-.166.001-6.431a1.145 1.145 0 00-.932-.937zM15.954 26.425a5.078 5.078 0 1110.156 0 5.078 5.078 0 01-10.156 0z"
    />
  </svg>
);

export default SvgProfileGender;
