import React from 'react';

const SvgMobileSetup201 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M31 0c16.95 0 30.722 13.603 30.996 30.487L62 31h-6C56 17.331 45.03 6.224 31.413 6.003L31 6V0z"
      fill="#FFF02C"
    />
  </svg>
);

export default SvgMobileSetup201;
