import React from 'react';

const SvgArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M32.062 16.125h-3.254a.65.65 0 00-.486.221l-8.796 10.138V3.859a.322.322 0 00-.321-.321h-2.41a.322.322 0 00-.322.321v22.625L7.678 16.346a.638.638 0 00-.486-.22H3.937a.32.32 0 00-.241.53l13.335 15.368a1.282 1.282 0 001.94 0l13.332-15.368a.32.32 0 00-.24-.53z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgArrowDown;
