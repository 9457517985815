import React from 'react';

const SvgIconPayDisabled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M41.362 5.374H27.285L19.23 23.703h22.132a3.39 3.39 0 013.39-3.39V8.765a3.39 3.39 0 01-3.39-3.39z"
        fill="#d9d9d9"
      />
      <path
        d="M46.66.395a3.825 3.825 0 013.814 3.814v20.977A3.825 3.825 0 0146.66 29H16.902l1.304-2.966h27.312c1.094 0 1.99-.886 1.99-1.97V5.225c0-1.082-.896-1.968-1.99-1.968H28.216l1.257-2.86zm-31.3 22.15c.16 0 .29.13.29.289v1.738a.29.29 0 01-.29.29H4.71a.29.29 0 01-.289-.29v-1.738c0-.16.13-.29.29-.29H15.36zm.252-8.804c.16 0 .29.13.29.289v1.738a.29.29 0 01-.29.29H.48a.29.29 0 01-.29-.29V14.03c0-.16.131-.29.29-.29h15.134zm9.116-8.864a.29.29 0 01.289.29v1.738a.29.29 0 01-.29.289H8.286a.29.29 0 01-.29-.29V5.167a.29.29 0 01.29-.289H24.73z"
        fill="#d9d9d9"
      />
      <path
        d="M32.935 19.435v1.607h-.974v-1.56c-.897-.016-1.824-.295-2.365-.68l.371-1.036a4.12 4.12 0 002.226.664c1.097 0 1.839-.634 1.839-1.515 0-.85-.602-1.375-1.747-1.839-1.576-.618-2.55-1.33-2.55-2.674 0-1.282.912-2.256 2.334-2.488V8.353h.958v1.5c.928.03 1.561.277 2.01.54l-.387 1.02a3.735 3.735 0 00-1.947-.525c-1.19 0-1.639.71-1.639 1.329 0 .803.572 1.206 1.917 1.762 1.592.649 2.396 1.453 2.396 2.828 0 1.221-.85 2.365-2.442 2.628z"
        fill="#F4F6F7"
      />
    </g>
  </svg>
);

export default SvgIconPayDisabled;
