import React from 'react';

const SvgTips = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={16.5} cy={12} r={6} fill="#FFC543" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.773A3.931 3.931 0 008.073 8.7a.654.654 0 101.309 0A2.621 2.621 0 0112 6.082a.654.654 0 100-1.31zM8.73 20.284a.65.65 0 00.11.362l1.002 1.507a.655.655 0 00.545.292h3.226c.219 0 .424-.11.545-.292l1.002-1.507a.658.658 0 00.11-.362l.002-1.766H8.728l.002 1.766zM12 1.5c-4.184 0-7.2 3.394-7.2 7.2a7.16 7.16 0 001.782 4.737c.68.776 1.749 2.405 2.144 3.77l1.964.002c0-.2-.03-.394-.088-.58a12.9 12.9 0 00-2.543-4.487A5.22 5.22 0 016.766 8.7C6.758 5.687 9.206 3.464 12 3.464A5.242 5.242 0 0117.236 8.7a5.22 5.22 0 01-1.294 3.442 12.927 12.927 0 00-2.54 4.478c-.06.19-.091.386-.092.585l1.964.004c.396-1.367 1.464-2.996 2.145-3.772A7.164 7.164 0 0019.2 8.7 7.2 7.2 0 0012 1.5z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgTips;
