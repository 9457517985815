import React from 'react';

const SvgRulesCoEd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#EBEAED" cx={12} cy={12} r={12} />
      <path
        d="M15.682 10.132h2.658c.334 0 .616.246.66.563v2.816a.66.66 0 01-1.311.088v-1.153l-1.435 1.62c.273.458.449.986.475 1.549l.009.176a3.3 3.3 0 11-1.39-2.693l1.443-1.637h-1.11a.66.66 0 01-.65-.572V10.8c0-.335.246-.617.563-.66h2.755l-2.667-.01zm-2.245 3.608a2.042 2.042 0 100 4.084 2.042 2.042 0 000-4.084zM8.244 5a3.3 3.3 0 01.66 6.54v1.082h1.25c.335 0 .616.256.652.573v.088a.657.657 0 01-.564.66H8.904v1.32a.65.65 0 01-.572.651h-.088a.66.66 0 01-.651-.563l-.009-.088v-1.32H6.308a.66.66 0 01-.651-.572l-.01-.088c0-.335.247-.608.573-.652h1.364v-1.109A3.3 3.3 0 018.244 5zm0 1.259a2.042 2.042 0 100 4.084 2.042 2.042 0 000-4.084z"
        fill="#3F2D51"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgRulesCoEd;
