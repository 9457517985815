import React from 'react';

const SvgBookingSummaryFloor = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.647 8.215L44.45 19.92a1.598 1.598 0 010 2.752L24.647 34.376c-.5.295-1.12.297-1.62.004L3.043 22.672a1.598 1.598 0 010-2.756L23.025 8.212a1.598 1.598 0 011.62.003h.002z"
      fill="#FFC543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.647 19.4L44.45 31.106a1.598 1.598 0 010 2.752L24.647 45.562c-.5.295-1.12.296-1.62.003L3.043 33.859a1.598 1.598 0 010-2.757l19.982-11.704a1.593 1.593 0 011.62.003h.002z"
      fill="#FFC543"
    />
    <path
      d="M45.2 14.113a1.599 1.599 0 01-.57.57l-19.987 11.71a1.598 1.598 0 01-1.614 0L3.043 14.684a1.598 1.598 0 010-2.757L23.03.22a1.598 1.598 0 011.615 0L44.63 11.927a1.598 1.598 0 01.57 2.186zM23.836 3.45L7.014 13.306 23.84 23.16l16.82-9.854L23.836 3.45h-.001zm21.418 15.86v4.103c0 .569-.302 1.095-.794 1.38L24.176 36.598a1.598 1.598 0 01-1.622-.008L2.778 24.796A1.598 1.598 0 012 23.421V19.31h3.196v3.205L23.38 33.362l18.677-10.868V19.31h3.197zm0 11.185v4.104c0 .568-.302 1.094-.794 1.38L24.176 47.783a1.598 1.598 0 01-1.622-.008L2.778 35.98A1.598 1.598 0 012 34.606v-4.111h3.196V33.7L23.38 44.547 42.057 33.68v-3.185h3.197z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgBookingSummaryFloor;
