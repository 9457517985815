import React from 'react';

const SvgMore = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M32.946 7.715h-3.013c-.205 0-.398.1-.518.265L18 23.714 6.585 7.98a.645.645 0 00-.518-.265H3.053a.323.323 0 00-.26.51l14.166 19.53a1.282 1.282 0 002.077 0l14.167-19.53a.32.32 0 00-.257-.51z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgMore;
