import React from 'react';

const SvgFacilityCoverings = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round" fill="none" fillRule="evenodd">
      <path d="M18.515 11.919h1.761m-2.04 1.672h1.672m-1.961 1.672h1.687m-1.955 1.672h1.672M17.4 18.608h1.672M9.01 16.895l2.14-11.163c.114-.486.082-1.005-.147-1.448A2.364 2.364 0 008.899 3H8.76a3.066 3.066 0 00-3.007 2.469l-2.25 11.249" />
      <path d="M6.67 17.493a.975.975 0 100-1.951h-.14a1.672 1.672 0 000 3.344h.14a2.37 2.37 0 000-4.738h-.14a3.066 3.066 0 000 6.132h11.985m-6.41-13.378h8.798m-1.831 1.672h1.768m-2.051 1.684h1.751" />
    </g>
  </svg>
);

export default SvgFacilityCoverings;
