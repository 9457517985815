import React from 'react';

const SvgTime = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.614 12.464a15.665 15.665 0 00-8.21-8.21 5.846 5.846 0 018.21 8.21zm-.244 6.468c.003 3.684-1.665 7.417-4.2 10.09l2.164 2.014c.577.578.593 1.335.111 1.907-.526.625-1.372.562-1.948-.015l-2.094-2.244c-4.95 3.466-12.104 3.428-17.059-.03l-2.011 2.088c-.377.377-.923.61-1.437.473-.515-.138-.66-.301-.798-.816-.138-.515-.015-.986.362-1.363l2.142-2.064c-2.432-2.583-4.103-6.205-4.103-10.034 0-7.976 6.468-14.439 14.439-14.439 7.97 0 14.435 6.46 14.435 14.435l-.002-.002zM17.934 6.672c-6.77 0-12.26 5.492-12.26 12.262 0 6.77 5.49 12.262 12.26 12.262s12.261-5.489 12.261-12.262c0-6.773-5.487-12.262-12.261-12.262zm5.976 13.756h-5.976a1.494 1.494 0 01-1.494-1.494V10.97a1.494 1.494 0 012.988 0v6.472h4.482a1.494 1.494 0 110 2.988v-.002zM3.285 12.666a5.967 5.967 0 018.381-8.38 15.959 15.959 0 00-8.38 8.38z"
    />
  </svg>
);

export default SvgTime;
