import React from 'react';

const SvgFacilityLocks = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g
      transform="translate(4 3)"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M14.5 13.53V15a1 1 0 01-1 1h-3.074M0 15V1a1 1 0 011-1h12.5a1 1 0 011 1v2.514" />
      <rect x={12} y={8} width={5} height={3.5} rx={0.5} />
      <path d="M16.5 7.5a2 2 0 10-4 0M1.28.039l8.36 2.442a.5.5 0 01.36.48v14.54a.5.5 0 01-.64.48L.72 15.455a1 1 0 01-.72-.96V1A1 1 0 011.28.04zM8.02 8.96v3" />
    </g>
  </svg>
);

export default SvgFacilityLocks;
