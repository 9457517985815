import React from 'react';

const SvgShareEmail = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <g clipPath="url(#share-email_svg__a)">
      <path
        d="M34.714 3.85H1.286C.575 3.85 0 4.424 0 5.136V30.85c0 .711.575 1.285 1.286 1.285h33.428c.711 0 1.286-.574 1.286-1.285V5.136c0-.712-.575-1.286-1.286-1.286zm-1.607 4.452v20.94H2.893V8.303l-1.11-.864 1.58-2.03 1.72 1.339H30.92l1.72-1.338 1.58 2.029-1.114.864zm-2.186-1.56L18 16.788 5.079 6.743l-1.72-1.338-1.58 2.029 1.11.864 13.725 10.671a2.25 2.25 0 002.76 0L33.107 8.302l1.11-.864-1.58-2.03-1.716 1.335z"
        fillOpacity={0.85}
      />
    </g>
    <defs>
      <clipPath id="share-email_svg__a">
        <path d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgShareEmail;
