import React from 'react';

const SvgIconNoborderMaster = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <g fill="none">
      <path fill="#FF5F00" d="M23.664 18.067h12.308v22.119H23.664z" />
      <path
        d="M24.446 29.128a14.042 14.042 0 015.372-11.06c-5.767-4.533-14.049-3.873-19.025 1.516-4.976 5.388-4.976 13.697 0 19.085 4.976 5.389 13.258 6.05 19.025 1.517a14.042 14.042 0 01-5.372-11.058z"
        fill="#EB001B"
      />
      <path
        d="M52.578 29.128a14.066 14.066 0 01-22.76 11.058 14.066 14.066 0 000-22.119 14.066 14.066 0 0122.76 11.058v.003zm-1.345 9.302v-.941h.184v-.194h-.465v.194h.2v.94h.081zm.903 0v-1.135h-.14l-.165.81-.164-.81h-.125v1.134h.102v-.85l.152.737h.106l.152-.738v.86l.082-.009z"
        fill="#F79E1B"
      />
    </g>
  </svg>
);

export default SvgIconNoborderMaster;
