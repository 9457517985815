import React from 'react';

const SvgBookingSummaryRoom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx={14} cy={14} r={14} />
      <path
        fill="#FFC543"
        d="M15.171 8.823l5.908 5.616-.043.104-1.872.333-.34 6.259-.06.06-7.802-.04-.06-.06v-5.99H8.865L8.823 15l6.265-6.175z"
      />
      <path
        d="M13.645 7.332l-6.32 6.232-.056.062c-.294.37-.269.874.054 1.193a.893.893 0 00.627.256h1.184l.06.06v5.481c0 .488.4.883.89.883h8.376c.491 0 .89-.395.89-.883v-5.48l.06-.06h1.181l.078-.004c.471-.053.815-.427.815-.88a.88.88 0 00-.263-.626l-6.32-6.231-.085-.075a.894.894 0 00-1.17.072zm.668.614l6.266 6.178-.042.104-2.035-.001v6.363l-.061.06h-8.337l-.061-.06v-6.363H8.007l-.042-.104 6.265-6.176.083-.001zm-1.253 9.311h-.97a.424.424 0 00-.423.424v.97c0 .234.19.424.424.424h.97c.233 0 .423-.19.423-.424v-.97a.424.424 0 00-.424-.424zm3.394 0h-.97a.424.424 0 00-.424.424v.97c0 .234.19.424.424.424h.97c.234 0 .424-.19.424-.424v-.97a.424.424 0 00-.424-.424zm-3.394-3.394h-.97a.424.424 0 00-.423.424v.97c0 .234.19.424.424.424h.97c.233 0 .423-.19.423-.424v-.97a.424.424 0 00-.424-.424zm3.394 0h-.97a.424.424 0 00-.424.424v.97c0 .234.19.424.424.424h.97c.234 0 .424-.19.424-.424v-.97a.424.424 0 00-.424-.424z"
        fill="#4C4C4C"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgBookingSummaryRoom;
