import React from 'react';

const SvgFacilityDrawers = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M5 2.5h14v19H5zM5.5 9h13m-8-4h3m-3 6.6h3m-3 6.2h3m-8-2.3h13"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityDrawers;
