import React from 'react';

const SvgFacilityWaste = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round">
      <path d="M19 8l-1 13H7L6 8" />
      <path d="M12 12.686c.219-.42.328-.743.328-.968 0-.225-.11-.56-.328-1.002M11 5.97c-.192-.39-.288-.698-.288-.925 0-.227.096-.575.288-1.045m3.377 4.97c.164-.436.246-.764.246-.984 0-.22-.082-.548-.246-.986" />
    </g>
  </svg>
);

export default SvgFacilityWaste;
