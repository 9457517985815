import React from 'react';

const SvgIconColorVisa = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <path
      d="M30.893 27.368c-.03 2.34 2.085 3.645 3.677 4.42 1.637.797 2.186 1.308 2.18 2.02-.012 1.09-1.305 1.57-2.515 1.59-2.111.032-3.339-.57-4.315-1.026l-.76 3.558c.979.451 2.792.845 4.672.862 4.413 0 7.3-2.178 7.315-5.556.017-4.286-5.929-4.523-5.888-6.44.014-.58.568-1.2 1.783-1.358.601-.08 2.261-.14 4.143.726l.738-3.443c-1.011-.368-2.312-.721-3.931-.721-4.154 0-7.075 2.208-7.099 5.368m18.127-5.071c-.805 0-1.485.47-1.788 1.191L40.93 38.54h4.41l.877-2.425h5.388l.51 2.425H56l-3.392-16.242H49.02m.617 4.387l1.273 6.1h-3.486l2.213-6.1m-24.09-4.387L22.07 38.539h4.202l3.474-16.242h-4.2m-6.217 0l-4.373 11.055-1.77-9.4c-.207-1.05-1.027-1.655-1.937-1.655H4.1l-.1.471c1.468.319 3.135.833 4.146 1.382.618.336.794.63.997 1.427l3.351 12.962h4.441l6.808-16.242H19.33"
      fill="#1A1F71"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgIconColorVisa;
