import classNames from 'classnames';
import { ReactComponentLike } from 'prop-types';
import React from 'react';
import styles from './typography.module.scss';
export interface TypographyProps {
  component?: ReactComponentLike;
  variant?: any;
  color?: string;
  fontSize?: number;
  fontWeight?: string | number;
  textAlign?: string;
  fullWidth?: boolean;
  textDecoration?: string;
  cursor?: string;
  fontFamily?: string;
}

const Typography = React.forwardRef<any, TypographyProps & React.HTMLAttributes<any>>(
  (props, ref) => {
    const {
      component: Component = 'span',
      variant,
      color,
      fontSize,
      fontWeight,
      textAlign,
      className,
      fullWidth,
      textDecoration,
      cursor,
      fontFamily,
      style,
      ...otherProps
    } = props;

    return (
      <>
        <Component
          {...otherProps}
          className={classNames(
            `typography_${variant || 'body2'}`,
            {
              [styles.typographyUnderline]: textDecoration === 'underline',
            },
            className
          )}
          ref={ref}
          style={{
            fontFamily: fontFamily,
            textDecoration: textDecoration,
            cursor: cursor,
            fontWeight: fontWeight,
            textAlign: textAlign,
            width: fullWidth ? '100%' : '',
            fontSize: fontSize && `${fontSize}px`,
            color: color,
            ...style,
          }}
        />
      </>
    );
  }
);

export default Typography;
