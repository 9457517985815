import React from 'react';

const SvgFacilityDrainer = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M4 7h16v10.667H4zm9 .026V17.55M4 7.026l2.812 2.816m8.322.158h2.383m-2.383 4.236h2.383m-2.383-2.099h2.383"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityDrainer;
