import React from 'react';

const SvgFacilityFire = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M16.5 20.79a3.5 3.5 0 003.5-3.5c-.013-1.291-1.088-1.65-.856-2.954-.61.276-1.749.512-2.416 2.323-1.126-1.109-.896-2.897-.52-3.659C12.893 14.152 13 16.596 13 17.29a3.5 3.5 0 003.5 3.5zM6.458 2.563c.074.15 2.104 1.486 6.089 4.007-.157 3.115-.723 5.598-1.7 7.447-.758 1.429-.823 3.889-.193 7.38l-5.99-3.373c-.362-2.672-.07-5.082.877-7.23 1.42-3.221.805-8.458.917-8.231z"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityFire;
