import React from 'react';

const SvgAmendmentBathroom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.235 3.333c0-.85.73-1.583 1.577-1.583h2.649c.847 0 1.577.736 1.577 1.583v.195h2.399c.322 0 .61.109.818.318.208.208.316.497.316.82 0 .324-.108.612-.316.821a1.131 1.131 0 01-.818.318h-2.4v.39h5.58c1.288 0 2.36.993 2.459 2.275v.02c0 1.978-.94 3.758-2.373 4.817H29.8c1.476 0 2.726 1.368 2.635 2.924v15.281c0 1.464-1.166 2.738-2.724 2.738H6.473c-1.464 0-2.723-1.268-2.723-2.738V16.223c0-1.542 1.242-2.916 2.725-2.916h3.037a5.774 5.774 0 01-2.228-4.554V8.58c0-1.293 1.082-2.385 2.371-2.385h5.58v-2.86zm2.267.695v2.166h1.269V4.028H17.5zm-7.95 4.539v.1a3.721 3.721 0 003.726 3.749h9.631a3.816 3.816 0 003.816-3.838v-.001a.088.088 0 00-.009-.031.23.23 0 00-.053-.074H9.653c-.004 0-.032.005-.064.037a.14.14 0 00-.037.058zm20.636 23.195h-.017V16.04a.447.447 0 00-.454-.455H6.473a.46.46 0 00-.308.148.467.467 0 00-.148.312v15.467c0 .097.05.214.148.312a.46.46 0 00.308.148h23.33a.447.447 0 00.385-.21zM15.553 18.07c.208-.209.496-.318.819-.318h3.533c.323 0 .611.109.819.318.208.209.316.498.316.82 0 .323-.108.612-.316.82a1.13 1.13 0 01-.819.32H16.37c-.322 0-.61-.11-.817-.32a1.145 1.145 0 01-.315-.821c0-.323.108-.61.316-.82zm-7.07 4.444c.208-.209.495-.318.817-.318h17.674c.322 0 .61.11.817.318.208.21.316.498.316.821 0 .323-.108.612-.316.82a1.128 1.128 0 01-.817.319H9.3c-.322 0-.61-.11-.817-.318a1.142 1.142 0 01-.315-.82c0-.324.108-.613.315-.822z"
    />
  </svg>
);

export default SvgAmendmentBathroom;
