import React from 'react';

const SvgInspectionGood = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M33.023 18.87a5.17 5.17 0 001.05-3.121c0-1.804-1.01-3.512-2.633-4.464a2.72 2.72 0 00-1.378-.373h-9.635l.242-4.938a4.301 4.301 0 00-1.186-3.19 4.283 4.283 0 00-3.13-1.343 4.671 4.671 0 00-4.492 3.42L8.41 17.356H3.215c-.711 0-1.286.575-1.286 1.286v14.625c0 .711.575 1.286 1.286 1.286h24.16c.369 0 .73-.073 1.064-.217a5.166 5.166 0 002.929-6.244 5.17 5.17 0 00.832-4.609 5.17 5.17 0 001.048-3.122 5.489 5.489 0 00-.225-1.49zm-28.2 12.79V20.249h3.254v11.41H4.822zm25.569-14.183l-.88.763.558 1.02a2.294 2.294 0 01-.51 2.825l-.88.764.559 1.02a2.295 2.295 0 01-.51 2.825l-.88.763.558 1.02a2.284 2.284 0 01-1.073 3.178H10.648V20.12l3.998-14.484a1.772 1.772 0 011.695-1.298c.306 0 .607.089.848.27.398.297.61.747.587 1.225l-.386 7.971h12.632c.715.438 1.157 1.178 1.157 1.945a2.3 2.3 0 01-.787 1.728z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgInspectionGood;
