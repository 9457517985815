import React from 'react';

const SvgShareAdd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M19.205 3.538h-2.41c-.215 0-.322.107-.322.321v12.616H4.5c-.215 0-.322.107-.322.322v2.41c0 .215.107.322.322.322h11.972v12.616c0 .214.107.321.321.321h2.411c.214 0 .322-.107.322-.321V19.529H31.5c.214 0 .321-.107.321-.322v-2.41c0-.215-.107-.322-.321-.322H19.526V3.86c0-.214-.107-.321-.321-.321z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgShareAdd;
