import React from 'react';

const SvgIconKitchen = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#EBEAED" cx={16} cy={16} r={16} />
      <path d="M10.985 14.653h12.333v7.333H9.985v-6.333a1 1 0 011-1z" fill="#FFC543" />
      <path
        d="M10.716 10.33a.87.87 0 01.866-.882.87.87 0 01.882.882c0 .204.165.39.39.39a.407.407 0 00.391-.39 1.65 1.65 0 00-1.663-1.663 1.65 1.65 0 00-1.646 1.662v2.242h-1.58a.363.363 0 00-.356.373v9.37c0 .203.153.372.357.372h15.207a.363.363 0 00.357-.373v-9.369a.363.363 0 00-.357-.373H10.716v-2.242zm5.635 3.02h6.806v1.563H16.35V13.35zm0 2.344h6.806v6.211H16.35v-6.211zm-.78-2.343v8.554H8.764v-8.554h6.807zm1.918 7.587a.363.363 0 01-.357-.373v-2.377a.363.363 0 01.357-.373h4.515c.203 0 .373.17.373.373v2.377c0 .203-.17.373-.373.373H17.49zm.407-2.342v1.56h3.7v-1.56h-3.7z"
        fill="#3F2D51"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgIconKitchen;
