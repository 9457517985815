import React from 'react';

const SvgAutomaticPaymentsSetup = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M7.642 13.42l28.152-1.281H38.9l-2.497-5.332c-.86-2.15-3.254-3.279-5.547-2.62L4.959 12.14l2.683 1.281z"
      fill="#3F2D51"
    />
    <path
      d="M37.623 11.851c3.582 0 6.487 2.848 6.487 6.363v4.664h-9.133c-3.011 0-5.476 2.35-5.568 5.274l-.003.19c0 .743.151 1.451.425 2.097a13.352 13.352 0 00-4.232 9.768c0 1.664.304 3.257.858 4.726H6.487C2.903 44.933 0 42.086 0 38.572v-20.36c0-3.512 2.904-6.36 6.487-6.36h31.137z"
      fill="#FFC543"
    />
    <path
      d="M34.658 25.242c1.202 0 2.248.674 2.779 1.664a13.317 13.317 0 00-5.86 2.149 3.15 3.15 0 013.08-3.813zm11.206 14.359c.138-.537.182-1.82.138-2.399-.308-4.184-5.065-6.239-8.438-5.382 2.54.881 5.124 3.282 5.143 6.125.002.35-.026.693-.077 1.029l-2.728-.287 1.768 2.05 1.767 2.05 2.282-1.457L48 39.875l-2.136-.274zm-12.406-3.086c-.153.532-.226 1.814-.2 2.393.202 4.193 4.906 6.366 8.3 5.595-2.518-.947-5.04-3.41-4.986-6.253.007-.35.042-.692.103-1.025l2.72.352-1.715-2.091-1.714-2.096-2.317 1.397-2.319 1.399 2.128.328v.001z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgAutomaticPaymentsSetup;
