import React from 'react';

const SvgVouchersIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 80" {...props}>
    <g opacity={0.3} fillRule="evenodd" clipRule="evenodd">
      <path d="M13.27 56.845l5.975 2.774 2.774-5.975-5.975-2.775-2.774 5.976z" fill="#8C8197" />
      <path d="M8.92 9.297L7.662 0 0 6.391l8.92 2.906z" fill="#FFE8B4" />
      <path d="M65.789 33.176l-3.86 5.387 7.034.43-3.174-5.817z" fill="#F69991" />
      <path d="M29.295 27.646l-3.86 5.386 7.034.431-3.174-5.817z" fill="#9DE7D6" />
      <path d="M3.318 51.027l5.529.916-2.301-5.498-3.228 4.582z" fill="#FFE8B4" />
      <path d="M81 61.499l-3.033-6.206-3.874 6.242L81 61.498z" fill="#F69991" />
      <path
        d="M9.704 24l-.857-.43 1.773-3.54 3.018 1.513 1.376-2.744 3.447 1.726-.429.859-2.59-1.298-1.375 2.744-3.02-1.513-1.343 2.682zm83.153 47.2L92 70.77l1.773-3.539 3.019 1.513L98.167 66l3.447 1.727-.428.858-2.591-1.298-1.375 2.744-3.02-1.513-1.343 2.682z"
        fill="#9DE7D6"
      />
      <path
        d="M68.316 50.956l-.858-.43.872-1.737c.24-.482.654-.84 1.166-1.009.51-.17 1.057-.13 1.537.11l.274.137c.522.264 1.156.05 1.417-.47a2.016 2.016 0 012.703-.898l1.646.823-.428.859-1.648-.825a1.054 1.054 0 00-1.416.47 2.018 2.018 0 01-2.704.9l-.274-.139a1.052 1.052 0 00-1.416.47l-.87 1.74zM45.093 33.263l-.858-.43.871-1.737a2 2 0 011.166-1.01 2.01 2.01 0 011.538.11l.274.138c.522.264 1.156.05 1.416-.47a2.017 2.017 0 012.704-.899l1.646.824-.428.858-1.648-.825a1.054 1.054 0 00-1.416.471 2.018 2.018 0 01-2.704.899l-.274-.138a1.052 1.052 0 00-1.416.47l-.871 1.739z"
        fill="#FFE8B4"
      />
      <path
        d="M22.31 43.308c-7.03 0-11.328-4.527-11.529-4.745l.701-.653c.052.057 5.34 5.603 13.533 4.208l.162.943a16.89 16.89 0 01-2.866.247zM19.659 72.57l51.306-.215-.072-18.925-51.306.216.072 18.925z"
        fill="#F69991"
      />
      <path
        opacity={0.2}
        d="M19.652 69.112l51.307-.217-.056-13.047-51.305.217.054 13.047z"
        fill="#FE544A"
      />
      <path d="M19.642 66.425l51.306-.217-.056-13.048-51.305.218.055 13.047z" fill="#F69991" />
      <path d="M42.266 73.191l6.1-.025-.095-24.281-6.1.026.095 24.28z" fill="#FFE8B4" />
      <path d="M42.178 50.541l6.1-.025-.057-13.461-6.1.026.057 13.46z" fill="#FFE8B4" />
      <path d="M41.143 51.944l4.077-4.111-9.073-8.997-4.076 4.11 9.072 8.998z" fill="#FFE8B4" />
      <path
        d="M44.131 53.882l-.023-5.484-13.878.058.023 5.485 13.878-.059zm17.272-.076l-.024-5.484-13.878.058.024 5.485 13.878-.059z"
        fill="#FFE8B4"
      />
      <path d="M49.324 51.912l-4.111-4.076 8.997-9.072 4.11 4.077-8.996 9.071z" fill="#FFE8B4" />
    </g>
  </svg>
);

export default SvgVouchersIcon;
