import React from 'react';

const SvgAmendmentBed = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.042 4.701A3.25 3.25 0 004.71 7.948v8.267A8.497 8.497 0 00.7 23.412v3.277a1.942 1.942 0 001.93 1.929h3.8v1.323a1.083 1.083 0 001.115 1.136 1.073 1.073 0 001.116-1.138v-1.321h20.674v1.323a1.084 1.084 0 001.115 1.136 1.083 1.083 0 001.127-1.136v-1.323h3.78a1.943 1.943 0 001.942-1.93v-3.275a8.499 8.499 0 00-4.09-7.245V7.944a3.293 3.293 0 00-3.328-3.243H8.042zM29.883 6.63v.3H8.031a1.072 1.072 0 00-1.09 1.025v7.282c.1-.028.2-.054.302-.078v-2.334a2.714 2.714 0 012.762-2.76h5.716a2.714 2.714 0 012.762 2.757v2.112h1.032v-2.109a2.718 2.718 0 01.556-1.74l.005-.006.191-.225.006-.006a2.715 2.715 0 011.956-.83h5.846l.046.046a2.715 2.715 0 012.632 2.758v2.338l.228.057V7.949a1.029 1.029 0 00-1.082-1.02l-.016-.299zm-13.906 5.664h-5.99a.484.484 0 00-.509.509v2.131h6.774v-2.175a.484.484 0 00-.275-.465zm11.877-.045H22.23a.483.483 0 00-.475.537l.002.017v2.131h6.764v-2.128a.483.483 0 00-.496-.51l-.096.003-.075-.05zm-9.37 4.916v.045H8.436a6.27 6.27 0 00-5.503 6.203v2.974H35.07v-2.973a6.27 6.27 0 00-5.503-6.204H19.515v-.045h-1.032z"
    />
  </svg>
);

export default SvgAmendmentBed;
