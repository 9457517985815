import React from 'react';

const SvgPasswordSuccess = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 78" {...props}>
    <g fill="none">
      <path
        d="M42.47 9.47c-18.24 0-33 14.76-33 33 0 18.24 14.76 33 33 33 18.24 0 33-14.76 33-33 0-18.24-14.76-33-33-33z"
        fill="#FFC543"
      />
      <path
        d="M59.078 32.29L37.533 53.836c-.431.43-1.006.718-1.652.718-.79.143-1.58-.072-2.154-.647L20.153 40.262a2.384 2.384 0 010-3.375l1.149-1.15a2.384 2.384 0 013.375 0l10.917 10.917 18.888-18.888a2.384 2.384 0 013.375 0l1.221 1.149a2.384 2.384 0 010 3.375z"
        fill="#3F2D51"
      />
      <path
        d="M40.28 77.819C18.797 78.569.774 61.762.024 40.279a38.75 38.75 0 014.825-20.182 2.457 2.457 0 014.299 2.38 33.835 33.835 0 00-4.214 17.632c.655 18.77 16.403 33.455 35.174 32.8 18.771-.656 33.456-16.404 32.8-35.175-.655-18.77-16.403-33.455-35.174-32.8a33.834 33.834 0 00-17.292 5.431 2.457 2.457 0 01-2.673-4.122A38.748 38.748 0 0137.562.023c21.483-.75 39.506 16.058 40.257 37.54.75 21.482-16.057 39.506-37.54 40.256z"
        fill="#3F2D51"
      />
    </g>
  </svg>
);

export default SvgPasswordSuccess;
