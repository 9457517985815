import React from 'react';

const SvgIconServices = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 1.8l.09 3.76c0 1.66-.88 3.05-2.18 3.52l.15 5.1c.02.44-.32.8-.76.8h-.66a.76.76 0 01-.76-.8l.16-5.1C2.74 8.61 2 7.21 2 5.56l.07-3.77a.71.71 0 011.4 0l.05 3.77h.76c0-.82.06-3.51.06-3.77a.7.7 0 011.4 0c0 .39.06 2.93.06 3.77h.76l.08-3.77C6.88.97 7.74 1 8 1.8zm3.86 7.28l-.08 5.1c-.04.45.31.83.76.83h.7c.42 0 .76-.34.76-.76V1.76a.76.76 0 00-.76-.76c-2.61 0-4.19 4.77-1.38 8.08z"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgIconServices;
