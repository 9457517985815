import React from 'react';

const SvgMenu = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M33.75 3.85H2.25a.322.322 0 00-.322.321v2.572c0 .176.145.321.322.321h31.5a.322.322 0 00.321-.321V4.17a.322.322 0 00-.321-.321zm0 25.071H2.25a.322.322 0 00-.322.322v2.571c0 .177.145.322.322.322h31.5a.322.322 0 00.321-.322v-2.571a.322.322 0 00-.321-.322zm0-12.535H2.25a.322.322 0 00-.322.321v2.571c0 .177.145.322.322.322h31.5a.322.322 0 00.321-.322v-2.571a.322.322 0 00-.321-.321z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgMenu;
