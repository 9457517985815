import React from 'react';

const SvgFacilityPlugs = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round" fill="none" fillRule="evenodd">
      <path d="M4 4h16v16H4z" />
      <path d="M15.056 8.072H8.944v1.91H6.5v6.208h11V9.983h-2.444zM8.362 16.19v-1.862m1.862 1.862v-1.862m3.724 1.862v-1.862m1.862 1.862v-1.862m-3.724 0v1.862" />
    </g>
  </svg>
);

export default SvgFacilityPlugs;
