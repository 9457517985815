import React from 'react';

const SvgNeedHelp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 24.093c0-8.969-9.633-16.11-21.592-16.11-11.958 0-21.592 7.308-21.592 16.11 0 7.807 7.474 14.284 17.274 15.779.332 0 .498.166.664.498l2.491 3.322c.499.664 1.33.664 1.828 0l2.49-3.322c.167-.166.5-.332.831-.498C40.526 38.543 48 31.9 48 24.093z"
      fill="#FFC543"
    />
    <path
      d="M22.754 3c5.98 0 11.627 1.827 15.945 4.983 4.318 3.322 6.81 7.64 6.81 12.29 0 8.305-7.807 15.447-18.602 16.942l-2.492 3.321c-.498.499-1.162.83-1.827.83-.664 0-1.329-.331-1.827-.83l-2.491-3.322c-4.983-.83-9.633-2.823-12.955-5.813C1.827 28.246 0 24.426 0 20.273c0-4.65 2.491-9.135 6.81-12.29C11.128 4.827 16.775 3 22.754 3zm0 2.16c-11.294 0-20.429 6.809-20.429 14.947 0 7.142 6.81 13.288 16.277 14.616.664.166 1.163.332 1.495.83l2.491 3.322 2.492-3.321c.332-.499.83-.83 1.494-.83 9.634-1.33 16.61-7.641 16.61-14.783 0-7.972-9.136-14.782-20.43-14.782zm-8.802 11.958c1.494 0 2.657 1.328 2.657 2.823 0 1.495-1.329 2.824-2.824 2.824-1.494 0-2.657-1.329-2.657-2.824 0-1.495 1.329-2.823 2.824-2.823zm9.135 0c1.66 0 2.823 1.328 2.823 2.823 0 1.495-1.329 2.824-2.823 2.824-1.495 0-2.824-1.329-2.824-2.824 0-1.495 1.329-2.823 2.823-2.823zm9.3 0c1.495 0 2.824 1.328 2.824 2.823 0 1.495-1.329 2.824-2.823 2.824-1.495 0-2.824-1.329-2.824-2.824 0-1.495 1.329-2.823 2.824-2.823z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgNeedHelp;
