import React from 'react';

const SvgFacilitySink = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M5 13.155l6.8-.004c1.38 0 2.5 1.416 2.5 2.796h4.5c0-4.43-1.8-7.292-5.8-7.292h-.5v-2.55h1.8a.2.2 0 00.2-.2V4.491a.2.2 0 00-.2-.2h-1.55l-.155-.912A.5.5 0 0012.11 3H9.89a.5.5 0 00-.485.379l-.155.912H7.7a.2.2 0 00-.2.2v1.414c0 .11.09.2.2.2h1.8v2.55H5m12 13.136a1.5 1.5 0 001.5-1.5c0-.552-.5-1.552-1.5-3-1 1.448-1.5 2.448-1.5 3a1.5 1.5 0 001.5 1.5z"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilitySink;
