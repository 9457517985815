import React from 'react';

const SvgFacilityPlug = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M20.963 13c.025.164.037.331.037.5 0 3.038-4.03 5.5-9 5.5s-9-2.462-9-5.5c0-.169.012-.336.037-.5zM12 12.803v-4.89a1.828 1.828 0 013.47-.8h0m-5.758 4.1h4.436M12.8 17.335c1.144.053 2.99-.287 4.535-1.46"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityPlug;
