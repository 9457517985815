import React from 'react';

const SvgIconEyeShow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M35.285 16.966C31.476 8.942 25.72 4.904 18 4.904 10.278 4.904 4.524 8.942.715 16.97a2.423 2.423 0 000 2.07C4.525 27.062 10.282 31.1 18 31.1c7.723 0 13.476-4.037 17.285-12.065a2.42 2.42 0 000-2.07zM18 28.208c-6.48 0-11.226-3.287-14.573-10.205C6.774 11.083 11.52 7.797 18 7.797s11.226 3.287 14.573 10.206C29.23 24.92 24.485 28.208 18 28.208zm-.16-17.277a7.072 7.072 0 100 14.143 7.072 7.072 0 000-14.143zm0 11.572a4.498 4.498 0 01-4.5-4.5c0-2.488 2.012-4.5 4.5-4.5 2.487 0 4.5 2.012 4.5 4.5 0 2.486-2.013 4.5-4.5 4.5z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgIconEyeShow;
