import React from 'react';

const SvgFacilityBucket = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#4C4C4C"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.5 11h9l-1 9.5h-7z"
      />
      <path
        d="M12.841 12.51A3.5 3.5 0 0016 14.5h0a3.5 3.5 0 003.163-2"
        stroke="#4C4C4C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle fill="#4C4C4C" cx={20} cy={8.5} r={1} />
      <circle
        stroke="#4C4C4C"
        strokeLinecap="round"
        strokeLinejoin="round"
        cx={18}
        cy={5.5}
        r={1}
      />
      <path
        stroke="#4C4C4C"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.152 3.766L6.975 20.23M3 20.5h7.753"
      />
    </g>
  </svg>
);

export default SvgFacilityBucket;
