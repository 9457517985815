import React from 'react';

const SvgRefund = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#3EA769" cx={8} cy={8} r={8} />
      <path
        d="M12.271 8.567H5.166l2.766 2.766a.671.671 0 11-.949.95L3.186 8.486a.659.659 0 01-.174-.59.66.66 0 01.174-.59l3.798-3.798a.671.671 0 11.948.95L5.166 7.225h7.105a.671.671 0 110 1.342z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgRefund;
