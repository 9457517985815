import React from 'react';

const SvgIconEarly = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
      fill="#FDF3F5"
    />
    <path
      d="M15 9.6a1.8 1.8 0 011.8 1.8v25.2a1.8 1.8 0 01-3.6 0V11.4A1.8 1.8 0 0115 9.6zm13.2 7.2c.96 0 1.8.84 1.8 1.8 0 .48-.24.96-.6 1.32l-2.52 2.28H37.8c.96 0 1.8.84 1.8 1.8 0 .96-.84 1.8-1.8 1.8H26.88l2.52 2.28c.365.345.58.819.6 1.32 0 .96-.84 1.8-1.8 1.8-.48 0-.84-.12-1.2-.48l-6-5.4a1.92 1.92 0 01-.6-1.32c0-.48.24-.96.6-1.32l6-5.4c.36-.24.72-.48 1.2-.48z"
      fill="#FE544A"
    />
  </svg>
);

export default SvgIconEarly;
