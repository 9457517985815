import React from 'react';

const SvgIconRoom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#EBEAED" cx={16} cy={16} r={16} />
      <path
        fill="#FFC543"
        d="M17.266 9.997l6.751 6.418-.049.119-2.139.38-.389 7.153-.069.07-8.916-.046-.069-.07v-6.846h-2.327l-.049-.118 7.16-7.058z"
      />
      <path
        d="M15.522 8.293l-7.223 7.122-.065.07c-.336.425-.306 1 .063 1.364.19.188.448.293.716.293h1.353l.07.07v6.263c0 .557.455 1.009 1.016 1.009h9.572c.562 0 1.017-.451 1.017-1.01v-6.262l.07-.07h1.35l.088-.004c.539-.06.931-.487.931-1.005 0-.268-.108-.526-.3-.715l-7.222-7.122-.099-.086a1.022 1.022 0 00-1.337.083zm.763.701l7.161 7.061-.048.119-2.326-.001v7.272l-.07.07h-9.528l-.07-.07v-7.272H9.08l-.05-.12 7.16-7.057.096-.002zm-1.432 10.642h-1.108a.485.485 0 00-.485.484v1.109c0 .267.217.484.485.484h1.108a.485.485 0 00.485-.484V20.12a.485.485 0 00-.485-.484zm3.879 0h-1.109a.485.485 0 00-.484.484v1.109c0 .267.217.484.484.484h1.109a.485.485 0 00.484-.484V20.12a.485.485 0 00-.484-.484zm-3.879-3.879h-1.108a.485.485 0 00-.485.485v1.108c0 .268.217.485.485.485h1.108a.485.485 0 00.485-.485v-1.108a.485.485 0 00-.485-.485zm3.879 0h-1.109a.485.485 0 00-.484.485v1.108c0 .268.217.485.484.485h1.109a.485.485 0 00.484-.485v-1.108a.485.485 0 00-.484-.485z"
        fill="#3F2D51"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgIconRoom;
