import React from 'react';

const SvgIconPerson = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.095 46.355c.88 0 1.612-.668 1.53-1.46-.866-8.553-7.184-15.203-14.872-15.203-7.69 0-14.015 6.65-14.873 15.204-.083.783.65 1.459 1.53 1.459h26.685zm-23.36-32.216a8.241 8.241 0 004.16 7.147 8.37 8.37 0 008.314 0 8.241 8.241 0 004.158-7.147 8.24 8.24 0 00-4.158-7.146 8.37 8.37 0 00-8.315 0 8.24 8.24 0 00-4.159 7.146z"
      fill="#FFC543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.86 25.385c9.65 0 17.755 8.375 18.849 19.483.078.792-.2 1.556-.773 2.151a3.28 3.28 0 01-2.353.981H8.14a3.282 3.282 0 01-2.353-.98 2.669 2.669 0 01-.773-2.152C6.108 33.76 14.212 25.385 23.86 25.385zm0 2.517c-8.267 0-15.235 7.395-16.2 17.196a.264.264 0 00.093.224.54.54 0 00.392.155h31.438a.58.58 0 00.393-.155.27.27 0 00.094-.224c-.974-9.801-7.94-17.196-16.209-17.196zM23.86 0c6.41 0 11.625 5.179 11.625 11.538 0 6.358-5.214 11.537-11.624 11.537s-11.624-5.177-11.624-11.537C12.237 5.178 17.452 0 23.861 0zm0 2.654c-4.942 0-8.962 3.984-8.962 8.884s4.02 8.884 8.962 8.884c4.944 0 8.964-3.985 8.964-8.884 0-4.9-4.02-8.884-8.963-8.884z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgIconPerson;
