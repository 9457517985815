import React from 'react';

const SvgFaq = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0z"
        fill="#EBEAED"
      />
      <path
        d="M9.926 13.417c.298 0 .55.094.748.292a.988.988 0 01.293.72.956.956 0 01-.312.739c-.207.19-.45.288-.73.288-.28 0-.52-.098-.733-.293a.986.986 0 01-.292-.734c0-.298.094-.539.292-.72.2-.2.44-.292.734-.292zm.217-8.817c.973 0 1.745.264 2.336.809.572.526.861 1.251.861 2.158 0 .68-.196 1.28-.587 1.795l-.055.063-.155.162-.34.328-.46.42-.18.16c-.297.257-.506.503-.647.757a1.81 1.81 0 00-.246.796l-.007.18v.333l-.158.158H9.347l-.158-.158v-.333c0-.5.092-.943.275-1.326.215-.46.706-1.024 1.493-1.724l.38-.389.1-.12c.27-.336.4-.678.4-1.044 0-.53-.153-.942-.448-1.237-.294-.307-.724-.458-1.304-.458-.68 0-1.165.213-1.482.649-.262.334-.409.78-.438 1.348l-.006.219-.158.158H6.858L6.7 8.146c0-1.062.294-1.908.895-2.539.61-.672 1.464-1.007 2.548-1.007z"
        stroke="#3F2D51"
        strokeWidth={0.1}
        fill="#3F2D51"
      />
    </g>
  </svg>
);

export default SvgFaq;
