import { UserActionTypes, UPDATE_USER_INFO, REMOVE_USER_INFO, UPDATE_LOADED } from './types';
import { SP_QueryStudentViewer_studentViewer } from '~graphql/__generated__/SP_QueryStudentViewer';

export function updateViewerInfo(viewer: SP_QueryStudentViewer_studentViewer): UserActionTypes {
  return {
    type: UPDATE_USER_INFO,
    viewer,
  };
}

export function removeViewerInfo() {
  return {
    type: REMOVE_USER_INFO,
  };
}

export function updateLoaded() {
  return {
    type: UPDATE_LOADED,
  };
}
