import React from 'react';

const SvgIconInfo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.98.015a7.98 7.98 0 100 15.959 7.98 7.98 0 000-15.96zm0 12.252a.929.929 0 110-1.856.93.93 0 110 1.856zm.93-4.022a.93.93 0 01-1.86 0V3.913c0-.513.416-.928.93-.928a.93.93 0 01.93.928v4.332z"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgIconInfo;
