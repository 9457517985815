import React from 'react';

const SvgRefundValidateSmall = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={32} height={32} rx={16} fill="#FFC543" />
    <path
      d="M22.907 15.25h-.532v-1.593a.53.53 0 00-.531-.532H9.094A1.596 1.596 0 007.5 14.719v9.563a1.596 1.596 0 001.594 1.593h13.813a.532.532 0 00.53-.53v-9.563a.533.533 0 00-.53-.532z"
      fill="#3F2D51"
    />
    <path d="M9.094 14.188h12.219v1.062H9.092a.531.531 0 010-1.063z" fill="#FAFAFA" />
    <path
      d="M23.969 21.625H20.78a1.595 1.595 0 010-3.188h3.188a.532.532 0 01.531.532v2.125a.532.532 0 01-.531.531z"
      fill="#AAB3F7"
    />
    <path
      d="M21.11 15.25l-1.979-3.956a.533.533 0 00-.713-.238L10.03 15.25h11.078z"
      fill="#FE544A"
    />
    <path
      d="M8.563 15.25h10.422l-1.979-3.956a.532.532 0 00-.713-.237l-7.73 3.865v.328z"
      fill="#FF8C9C"
    />
    <circle cx={20.782} cy={20.032} r={0.797} fill="#2A41EA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.808 9.174A7.299 7.299 0 0115.998 7c2.902 0 5.423 1.712 6.644 4.219a.5.5 0 10.9-.438C22.162 7.952 19.306 6 15.998 6c-2.333 0-4.442.971-5.965 2.535l-.585-.483a.5.5 0 00-.811.302l-.4 2.37a.5.5 0 00.667.552l2.253-.84a.5.5 0 00.144-.853l-.494-.409z"
      fill="#fff"
    />
  </svg>
);

export default SvgRefundValidateSmall;
