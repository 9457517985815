import React from 'react';

const SvgIconUnderage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <g clipPath="url(#icon-underage_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.922 25.135c1.717 0 3.341.397 4.786 1.104-6.478.659-11.533 6.129-11.533 12.78a12.79 12.79 0 002.728 7.915h-10.51a2.857 2.857 0 01-2.857-2.857v-8.043c0-6.02 4.88-10.9 10.9-10.9h6.486v.001z"
        fill="#FFC543"
      />
      <path d="M23.457 21.384a8.693 8.693 0 100-17.386 8.693 8.693 0 000 17.386z" fill="#FFC543" />
      <path
        d="M21.44 0a10.475 10.475 0 00-5.96 19.137l.182.12 1.118.727-1.294.32A15.109 15.109 0 004.002 34.718L4 34.947v7.56a5.012 5.012 0 004.851 4.999l.15.002h12.126a1.19 1.19 0 00.088-2.379l-.088-.003H9a2.619 2.619 0 01-2.615-2.48l-.004-.139v-7.561C6.395 28 11.97 22.363 18.888 22.244l.21-.001h4.055a12.62 12.62 0 0110.16 5.066 1.195 1.195 0 001.909-1.437 15.687 15.687 0 00-1.4-1.604 15.051 15.051 0 00-6.42-3.803l-.292-.082-1.275-.344 1.117-.703A10.475 10.475 0 0021.655.004L21.44 0zm-.065 2.403a8.091 8.091 0 018.08 8.1 8.092 8.092 0 01-16.185-.01 8.104 8.104 0 017.924-8.089l.181-.001z"
        fill="#3F2D51"
      />
      <path
        d="M22.882 40.646l.676-1.634H27.1l.675 1.634h1.853l-3.412-7.629h-1.744l-3.4 7.63h1.81zm3.662-2.975h-2.42l1.21-2.921 1.21 2.92zm6.518 5.177c2.114 0 3.291-1.003 3.291-3.183v-4.882H34.74v.752c-.447-.567-1.122-.84-1.94-.84-1.623 0-2.931 1.123-2.931 2.835 0 1.71 1.308 2.833 2.931 2.833.764 0 1.406-.24 1.853-.73v.25c0 1.057-.523 1.603-1.722 1.603-.752 0-1.57-.262-2.07-.665l-.676 1.22c.686.534 1.765.807 2.877.807zm.076-3.88c-.893 0-1.548-.578-1.548-1.439 0-.861.655-1.439 1.548-1.439.894 0 1.537.578 1.537 1.44 0 .86-.643 1.438-1.537 1.438zm7.706 1.766c1.057 0 1.874-.328 2.42-.949l-.905-.98c-.404.38-.85.566-1.471.566-.894 0-1.515-.447-1.68-1.177h4.437c.011-.142.032-.327.032-.458 0-1.885-1.329-3.04-3.051-3.04-1.788 0-3.128 1.264-3.128 3.018 0 1.744 1.319 3.02 3.346 3.02zm1.231-3.532h-2.888c.12-.752.676-1.22 1.45-1.22.763 0 1.319.48 1.438 1.22z"
        fill="#3F2D51"
      />
    </g>
    <defs>
      <clipPath id="icon-underage_svg__a">
        <path fill="#fff" transform="translate(4)" d="M0 0h40v48H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgIconUnderage;
