import React from 'react';

const SvgFacilityHandle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g stroke="#4C4C4C" strokeLinejoin="round" fill="none" fillRule="evenodd">
      <path strokeLinecap="round" d="M3.5 3h17v17.716h-17z" />
      <path d="M18.623 4.8v14h-5.8v-14z" />
      <path
        strokeLinecap="round"
        d="M11.334 13.166V18.8h-6v-14h6v4.872m4.818 2.11l-2.215 2.26m-2.644-2.826l-2.546 1.696m7.626 1.287l-1.93 1.979"
      />
    </g>
  </svg>
);

export default SvgFacilityHandle;
