import React from 'react';

const SvgPaymentModelSuccessful = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 64 64"
    {...props}
  >
    <defs>
      <path
        id="payment-model-successful_svg__a"
        d="M.003 38.876l1.3-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.3-1.595 1.281 1.595 1.3-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.301-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.301-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.3-1.595 1.281 1.595 1.3-1.595 1.28 1.595 1.3-1.595 1.277 1.59V0H0v38.876h.003"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFCE00" cx={32} cy={32} r={32} />
      <path
        fillOpacity={0.05}
        fill="#2A2A2A"
        d="M46.78 56.036L45.5 54.44l-1.301 1.595-1.28-1.595-1.3 1.595-1.28-1.595-1.3 1.595-1.28-1.595-1.301 1.595-1.28-1.595-1.3 1.595-1.28-1.595-1.301 1.595-1.28-1.595-1.3 1.595-1.28-1.595-1.3 1.595-1.281-1.595-1.3 1.595-1.28-1.595-1.3 1.595-1.281-1.595-1.3 1.595h-.003V52.6l.945-1.159 1.28 1.595 1.3-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.301-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.301-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.301-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.301-1.595 1.277 1.59V17.804h2.938V56.03l-1.276-1.59-1.3 1.595"
      />
      <path
        fill="#F7F7F7"
        d="M15.456 53.036l1.3-1.595 1.28 1.595 1.3-1.595 1.281 1.595 1.3-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.301-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.301-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.301-1.595 1.28 1.595 1.3-1.595 1.28 1.595 1.301-1.595 1.277 1.59V14.16H15.453v38.876h.003"
      />
      <path
        d="M29.415 27.455H19.123a.551.551 0 110-1.102h10.292a.551.551 0 110 1.102m13.119 0H38.67a.551.551 0 110-1.102h3.865a.551.551 0 110 1.102m-.001 2.437H38.67a.551.551 0 010-1.102h3.865a.551.551 0 110 1.102m-.001 2.438H38.67a.551.551 0 010-1.103h3.865a.551.551 0 010 1.102m-.001 4.874H38.67a.551.551 0 010-1.102h3.865a.551.551 0 010 1.102m-.001 2.437H38.67a.551.551 0 110-1.103h3.865a.552.552 0 010 1.103m-17.108-9.748h-6.304a.551.551 0 110-1.102h6.304a.551.551 0 010 1.102m1.518 2.438h-7.822a.551.551 0 010-1.103h7.822a.551.551 0 010 1.102m.781 4.874h-8.603a.551.551 0 010-1.102h8.603a.551.551 0 010 1.102m1.012 2.437h-9.615a.551.551 0 110-1.103h9.615a.552.552 0 010 1.103m13.796-19.56H38.67a.551.551 0 010-1.103h3.865a.551.551 0 110 1.103m-.001 2.437H38.67a.551.551 0 110-1.103h3.865a.551.551 0 110 1.103M27.726 20.08h-8.603a.551.551 0 110-1.103h8.603a.551.551 0 010 1.103m1.012 2.437h-9.615a.551.551 0 110-1.103h9.615a.551.551 0 110 1.103m1.954 20.259H20.84a2.269 2.269 0 000 4.538h9.85a2.268 2.268 0 000-4.538"
        fill="#CCC"
      />
      <path
        d="M40.83 45.17v.81c.217 0 .325-.13.325-.39 0-.28-.108-.42-.324-.42zm-.97-1.36c0 .259.107.388.323.388v-.81c-.216 0-.324.14-.324.422zm.97 3.141v.648h-.647v-.648h-1.295v-.971h1.295v-.81c-.864 0-1.295-.454-1.295-1.36 0-.93.431-1.393 1.295-1.393v-.648h.648v.648h1.296v.971H40.83v.81c.864 0 1.296.464 1.296 1.393 0 .907-.432 1.36-1.296 1.36z"
        fill="#D85145"
      />
      <g transform="translate(15.453 14.16)">
        <mask id="payment-model-successful_svg__b" fill="#fff">
          <use xlinkHref="#payment-model-successful_svg__a" />
        </mask>
        <path
          d="M38.547 5.34a9.5 9.5 0 11-19 0 9.5 9.5 0 0119 0"
          fillOpacity={0.05}
          fill="#2A2A2A"
          mask="url(#payment-model-successful_svg__b)"
        />
      </g>
      <path
        d="M42.534 27.455H38.67a.551.551 0 110-1.102h3.865a.551.551 0 110 1.102m-.001 2.437H38.67a.551.551 0 010-1.102h3.865a.551.551 0 110 1.102m-.001 2.438h-2.87a11.705 11.705 0 01-1.504-.765.551.551 0 01.509-.338h3.865a.551.551 0 010 1.102m0-12.249H38.67a.551.551 0 010-1.103h3.865a.551.551 0 110 1.103m-.001 2.437H38.67a.551.551 0 110-1.103h3.865a.551.551 0 110 1.103"
        fill="#CCC"
      />
      <path d="M53 19.5a9.5 9.5 0 11-19 0 9.5 9.5 0 0119 0" fill="#3EA769" />
      <path
        d="M39.982 18.686a.951.951 0 00-1.346.002l-.357.358a.955.955 0 00-.002 1.346l3.346 3.346a.944.944 0 00.672.277.969.969 0 00.676-.282l6.748-6.748a.955.955 0 00.003-1.35l-.358-.358a.958.958 0 00-1.35.003l-5.72 5.719-2.312-2.313z"
        fill="#F7F7F7"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgPaymentModelSuccessful;
