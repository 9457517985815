import React from 'react';

const SvgIconPay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.996 12.9H32.315l-9.546 21.72h26.227c0-2.22 1.8-4.018 4.018-4.018V16.918a4.018 4.018 0 01-4.018-4.018z"
      fill="#FFC543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.01 29.562v1.905h-1.154v-1.85c-1.063-.018-2.161-.348-2.802-.806l.44-1.227c.659.44 1.611.788 2.637.788 1.3 0 2.179-.751 2.179-1.796 0-1.007-.714-1.63-2.07-2.179-1.868-.732-3.021-1.575-3.021-3.169 0-1.52 1.08-2.673 2.765-2.948v-1.85h1.136v1.777c1.098.036 1.85.33 2.381.64l-.458 1.21a4.426 4.426 0 00-2.307-.623c-1.411 0-1.942.842-1.942 1.575 0 .952.677 1.429 2.27 2.088 1.887.769 2.84 1.721 2.84 3.351 0 1.447-1.008 2.803-2.894 3.114z"
      fill="#3F2D51"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.274 7H34.908l-1.49 3.39h20.503c1.296 0 2.357 1.05 2.357 2.333v22.325c0 1.284-1.06 2.334-2.357 2.334H21.556l-1.546 3.515h35.264a4.533 4.533 0 004.52-4.52V11.52A4.533 4.533 0 0055.273 7zm-25.989 8.056H9.8a.344.344 0 01-.343-.342v-2.06c0-.188.154-.343.343-.343h19.485c.189 0 .343.155.343.343v2.06a.344.344 0 01-.343.342zM18.484 25.56H.55a.344.344 0 01-.343-.343v-2.06c0-.188.154-.342.343-.342h17.934c.188 0 .342.154.342.342v2.06a.344.344 0 01-.342.343zm-.299 10.432H5.563a.344.344 0 01-.343-.342v-2.06c0-.189.154-.343.343-.343h12.622c.189 0 .343.154.343.343v2.06a.344.344 0 01-.343.342z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgIconPay;
