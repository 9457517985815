import React from 'react';

const SvgPicEmpty = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#E2E6E8" cx={34} cy={34} r={34} />
      <path fill="#F4F6F7" d="M18.545 29.364h30.91l7.727 11.59v7.728H10.818v-7.727z" />
      <path
        d="M12.89 40.186l13.176.027v.129a7.727 7.727 0 1015.454 0l-.003-.096 13.688.028a2 2 0 011.996 2l.002 16.579A33.88 33.88 0 0134 68a33.88 33.88 0 01-23.145-9.094l.031-16.724a2 2 0 011.854-1.991l.15-.005z"
        fill="#FFF"
      />
      <path
        d="M47.612 17.862a.966.966 0 111.366 1.366l-4.636 4.637a.966.966 0 01-1.366-1.366zm-28.59 0a.966.966 0 011.366 0l4.636 4.637a.966.966 0 01-1.366 1.366l-4.636-4.637a.966.966 0 010-1.366zM34 13.716c.533 0 .966.432.966.966v6.954a.966.966 0 11-1.932 0v-6.954c0-.534.433-.966.966-.966z"
        fill="#F4F6F7"
      />
    </g>
  </svg>
);

export default SvgPicEmpty;
