import React from 'react';

const SvgFacilityWardrobe = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M5 2.5h14v19H5zM5.5 17h13M12 3v14m-1.5 2h3m.5-9.5v1m-4-1v1"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityWardrobe;
