import React from 'react';

const SvgIconVisa = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 40 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <rect id="icon-visa_svg__a" rx={2} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" xlinkHref="#icon-visa_svg__a" />
      <rect stroke="#E7E7E7" x={0.5} y={0.5} width={39} height={23} rx={2} />
      <path
        d="M20.41 10.48c-.01 1.08.96 1.68 1.7 2.04.75.37 1 .6 1 .93 0 .5-.6.72-1.16.73-.97.02-1.54-.26-1.99-.47l-.35 1.64c.45.21 1.3.4 2.16.4 2.04 0 3.37-1 3.37-2.56.01-1.98-2.73-2.1-2.71-2.98 0-.26.26-.55.82-.62a3.65 3.65 0 011.91.33l.34-1.59A5.2 5.2 0 0023.7 8c-1.92 0-3.27 1.02-3.28 2.48m8.37-2.34a.88.88 0 00-.83.55l-2.9 6.94h2.03l.4-1.12h2.5l.23 1.12H32l-1.57-7.5h-1.65m.28 2.03l.6 2.82h-1.62l1.02-2.82M17.94 8.14l-1.6 7.5h1.94l1.6-7.5h-1.94m-2.86 0l-2.02 5.1-.82-4.34a.9.9 0 00-.9-.76h-3.3L8 8.35c.68.15 1.45.39 1.91.64.29.16.37.3.46.66l1.55 5.98h2.05l3.14-7.5h-2.03"
        fill="#1A1F71"
      />
    </g>
  </svg>
);

export default SvgIconVisa;
