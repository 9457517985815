import React from 'react';

const SvgInfo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M18 2.25C9.302 2.25 2.25 9.302 2.25 18S9.302 33.75 18 33.75 33.75 26.698 33.75 18 26.698 2.25 18 2.25zm0 28.828c-7.221 0-13.078-5.857-13.078-13.078 0-7.221 5.857-13.078 13.078-13.078 7.221 0 13.078 5.857 13.078 13.078 0 7.221-5.857 13.078-13.078 13.078z"
      fillOpacity={0.85}
    />
    <path
      d="M16.312 11.813a1.687 1.687 0 103.374 0 1.687 1.687 0 00-3.374 0zm2.531 3.937h-1.687a.282.282 0 00-.281.281v9.563c0 .154.126.281.28.281h1.688a.282.282 0 00.282-.281V16.03a.282.282 0 00-.282-.281z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgInfo;
