import React from 'react';

const SvgFacilityCabinet = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M4 4h10v16H4zm10 0h6v16h-6zm.048 11H20m-5.945-6H20m-9.202-.5c-.144.177-1.057 1.09-2.738 2.738M10.738 13c-.145.177-1.057 1.09-2.738 2.738"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityCabinet;
