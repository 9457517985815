import React from 'react';

const SvgIconCreditDiscover = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <defs>
      <linearGradient
        x1="75.555%"
        y1="90.012%"
        x2="40.992%"
        y2="35.896%"
        id="Icon-credit-discover_svg__a"
      >
        <stop stopColor="#F89F20" offset="0%" />
        <stop stopColor="#F79A20" offset="25.02%" />
        <stop stopColor="#F68D20" offset="53.31%" />
        <stop stopColor="#F58720" offset="61.96%" />
        <stop stopColor="#F48120" offset="72.32%" />
        <stop stopColor="#F37521" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="70.098%"
        y1="89.284%"
        x2="19.584%"
        y2="-9.451%"
        id="Icon-credit-discover_svg__b"
      >
        <stop stopColor="#F58720" offset="0%" />
        <stop stopColor="#E16F27" offset="35.87%" />
        <stop stopColor="#D4602C" offset="70.3%" />
        <stop stopColor="#D05B2E" offset="98.16%" />
      </linearGradient>
    </defs>
    <g transform="translate(8 15)" fill="none">
      <path fill="#FFF" d="M.157.23h45.674v29.077H.169z" />
      <path d="M45.673 16.403s-12.48 8.8-35.335 12.746h35.335V16.403z" fill="#F48120" />
      <path
        d="M45.83.06H0v29.416h46V.048h-.17v.013zm-.157.327v28.75H.34V.387h45.334z"
        fill="#231F20"
      />
      <path
        d="M4.019 9.055h-1.9v6.658h1.9c1.005 0 1.731-.242 2.373-.763a3.33 3.33 0 001.21-2.554C7.59 10.423 6.125 9.055 4.02 9.055zm1.525 5.011c-.411.363-.932.533-1.78.533h-.35v-4.406h.35c.836 0 1.344.145 1.78.532.448.4.714 1.017.714 1.659 0 .641-.266 1.283-.714 1.682zm2.651-5.011H9.49v6.658H8.195zm4.467 2.554c-.775-.29-1.005-.484-1.005-.835 0-.424.412-.739.969-.739.387 0 .714.158 1.04.545l.679-.884a2.927 2.927 0 00-1.961-.738c-1.175 0-2.082.823-2.082 1.913 0 .92.423 1.392 1.634 1.827.508.182.762.303.896.376.254.17.387.411.387.69 0 .544-.424.944-1.005.944-.617 0-1.113-.315-1.416-.884l-.835.811c.593.884 1.32 1.271 2.3 1.271 1.343 0 2.3-.895 2.3-2.19.024-1.078-.424-1.562-1.9-2.107zm2.324.787c0 1.96 1.538 3.474 3.51 3.474.558 0 1.042-.109 1.623-.387v-1.526c-.52.52-.98.727-1.562.727-1.307 0-2.24-.945-2.24-2.3 0-1.283.957-2.288 2.18-2.288.617 0 1.09.218 1.622.75V9.321c-.569-.29-1.041-.412-1.598-.412-1.949 0-3.535 1.55-3.535 3.487zm15.447 1.138l-1.78-4.48h-1.416l2.82 6.828h.702l2.87-6.827h-1.405zm3.789 2.179h3.68v-1.126h-2.385v-1.792h2.3V11.67h-2.3v-1.476h2.385V9.055h-3.68zm8.824-4.685c0-1.247-.86-1.961-2.36-1.961H38.76v6.658h1.295v-2.676h.17l1.791 2.676h1.598l-2.094-2.809c.98-.205 1.525-.871 1.525-1.888zm-2.614 1.101h-.376v-2.021h.4c.81 0 1.247.339 1.247.993 0 .665-.436 1.028-1.271 1.028z"
        fill="#231F20"
      />
      <circle fill="url(#Icon-credit-discover_svg__a)" cx={24.102} cy={12.408} r={3.547} />
      <circle
        fill="url(#Icon-credit-discover_svg__b)"
        opacity={0.65}
        cx={24.102}
        cy={12.408}
        r={3.547}
      />
      <g fill="#231F20">
        <path d="M44.607 9.577c0-.266-.224-.4-.575-.4h-.511v1.278h.383V9.95l.447.505h.447l-.51-.532c.191-.027.319-.16.319-.346zm-.64.186h-.063v-.346h.064c.192 0 .287.053.287.16 0 .133-.095.186-.287.186z" />
        <path d="M43.819 8.922a.643.643 0 00-.64.639c0 .35.29.639.64.639.35 0 .639-.29.639-.64a.643.643 0 00-.64-.638zm0 1.171a.526.526 0 01-.517-.532c0-.29.228-.532.517-.532.274 0 .502.243.502.532 0 .289-.228.532-.502.532z" />
      </g>
    </g>
  </svg>
);

export default SvgIconCreditDiscover;
