import React from 'react';

const SvgDownload = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M17.747 23.98a.323.323 0 00.506 0l4.5-5.694a.32.32 0 00-.253-.518h-2.977V4.17a.322.322 0 00-.322-.321h-2.41a.322.322 0 00-.322.321v13.593H13.5a.32.32 0 00-.252.518l4.5 5.697zm14.958-1.407h-2.41a.322.322 0 00-.322.322v6.187H6.027v-6.188a.322.322 0 00-.322-.32h-2.41a.322.322 0 00-.322.32v7.956c0 .711.575 1.286 1.286 1.286H31.74c.711 0 1.286-.575 1.286-1.286v-7.956a.322.322 0 00-.322-.32z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgDownload;
