import React from 'react';

const SvgFacilityMicrowave = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M3 4.529h18v14.824H3zm13.765 0v14.824M19 7v2.324M5.5 17.559h8.529M10.322 8L7 11.129M12.413 11L9 14.425"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityMicrowave;
