import React from 'react';

const SvgGenderMismatch = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.947 34.416c0 .564.034 1.12.101 1.666H6.592a1.048 1.048 0 01-1.109-1.109v-3.28a5.438 5.438 0 015.484-5.499h17.751a13.517 13.517 0 00-2.771 8.222zm15.749-23.579a1.048 1.048 0 011.093 1.109v9.289a13.613 13.613 0 00-3.266-.396c-1.794 0-3.507.348-5.075.98H8.78v-8.824a2.203 2.203 0 012.187-2.158h30.729z"
      fill="#FFC543"
    />
    <path
      d="M9.439 7.001h29.29a4.016 4.016 0 014.06 3.956v10.277a13.547 13.547 0 00-2.187-.353v-9.924a1.783 1.783 0 00-1.873-1.767H9.44a1.843 1.843 0 00-1.873 1.768v10.314c.395-.13.8-.238 1.213-.322v-3.46a3.236 3.236 0 013.142-3.296h7.825a3.236 3.236 0 013.296 3.14v3.393h2.188V17.49a3.24 3.24 0 01.8-2.252l.105-.115a3.236 3.236 0 012.176-.986l.155-.003h7.671l.06.06a3.236 3.236 0 013.295 3.14l.001.156v3.35l-.195.002a13.508 13.508 0 00-7.083 2.13l-6.984.001-.001-.06h-2.188v.06H9.968a8.81 8.81 0 00-7.777 8.53l-.003.19v4.39h23.86c.091.74.243 1.472.454 2.187H9.873v2.187a1.034 1.034 0 01-1.093 1.11 1.05 1.05 0 01-1.094-1.11V38.27H2.187a2.202 2.202 0 01-2.184-2.075L0 36.082v-4.39a10.992 10.992 0 015.378-9.425v-11.31a3.956 3.956 0 014.06-3.956zm26.698 9.32h-7.67a1.048 1.048 0 00-1.041 1.07l.006.098v3.236h9.874v-3.236a1.048 1.048 0 00-.981-1.107l-.098-.002-.09-.06zm-16.291-.002l-.1.002v.06h-7.67a1.048 1.048 0 00-1.11 1.009l.001.1v3.235h9.888v-3.296a1.05 1.05 0 00-.91-1.102l-.099-.008z"
      fill="#3F2D51"
    />
    <path
      d="M47.174 30.71c.399 0 .735.283.81.668l.012.078.004.08v3.061a.826.826 0 01-1.636.159l-.011-.079-.004-.08v-.989l-1.421 1.606.057.098c.229.413.378.865.439 1.338l.02.178.01.18.002.134a3.79 3.79 0 11-1.739-3.187l.075.05 1.455-1.644H44.18a.825.825 0 01-.791-.591l-.018-.076-.012-.078-.004-.08c0-.398.283-.734.668-.81l.078-.012.08-.004h2.993zm-5.508 4.225a2.208 2.208 0 100 4.415 2.208 2.208 0 000-4.415zm-6.393-9.98a3.79 3.79 0 01.892 7.473l-.066.015-.001 1.07h1.317c.372 0 .69.247.791.593l.019.075.011.079.005.08a.826.826 0 01-.668.81l-.078.01-.08.005h-1.317v1.393c0 .372-.246.69-.591.792l-.076.018-.078.012-.08.004a.826.826 0 01-.81-.667l-.011-.079-.005-.08v-1.394H33.1a.826.826 0 01-.792-.591l-.019-.076-.01-.077-.005-.08c0-.398.283-.735.667-.81l.078-.012.08-.004h1.348v-1.072l-.067-.014a3.79 3.79 0 01-2.886-3.396l-.008-.145-.003-.143a3.79 3.79 0 013.79-3.79zm0 1.582a2.207 2.207 0 100 4.415 2.207 2.207 0 000-4.415z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgGenderMismatch;
