import React from 'react';

const SvgIconBookingconfirmed = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 115" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M95.741 102.387c-13.478-.578-25.83-1.02-39.518 12.454-4.598-10.694-12.727-13.917-19.676-15.611-12.336-3.01-23.572-10.14-30.475-20.853C-6.32 59.15 1.518 34.05 18.183 18.555 35.77 2.206 64.107-1.288 87.09.375c16.121 1.166 32.418 6.54 44.876 16.652 5.108 4.491 9.468 9.797 12.629 16.067 7.567 15.01 6.628 32.56-2.416 46.702-10.489 16.401-27.472 23.402-46.437 22.591"
        fill="#EBEAED"
      />
      <path
        d="M115.294 21.706v34.07h-82.71V21.703a2.445 2.445 0 012.444-2.445h77.818a2.447 2.447 0 012.448 2.448"
        fill="#3F2D51"
      />
      <path
        d="M43.52 79.642l-5.126 7.314a.79.79 0 01-.644.336h-3.33a.788.788 0 01-.776-.906l1.042-6.744h8.834"
        fill="#32243F"
      />
      <path
        d="M104.357 79.642l5.127 7.314a.79.79 0 00.644.336h3.329a.788.788 0 00.777-.906l-1.042-6.744h-8.835"
        fill="#4C4C4C"
      />
      <path
        d="M71.24 40.657a107.893 107.893 0 00-22.993 0 1.166 1.166 0 01-1.245-1.491c1.169-3.872 1.169-7.743 0-11.615a1.167 1.167 0 011.245-1.491c7.664.819 15.328.819 22.993 0a1.167 1.167 0 011.245 1.491c-1.17 3.872-1.17 7.743 0 11.615.24.797-.417 1.58-1.245 1.491m28.39 0a107.884 107.884 0 00-22.992 0 1.166 1.166 0 01-1.245-1.491c1.169-3.872 1.169-7.743 0-11.615a1.167 1.167 0 011.245-1.491c7.664.819 15.328.819 22.993 0a1.167 1.167 0 011.245 1.491c-1.17 3.872-1.17 7.743 0 11.615.24.797-.417 1.58-1.245 1.491"
        fill="#FFFFFE"
      />
      <path
        d="M108.357 41.619c3.27 1.359 6.452 14.157 6.452 14.157h-82.1S35.88 42.955 39.16 41.62c15.436-6.29 53.232-6.636 69.197 0"
        fill="#FFC543"
      />
      <path
        d="M119.61 55.687v22.127a2.447 2.447 0 01-2.448 2.444h-86.45a2.444 2.444 0 01-2.444-2.444V55.687a2.444 2.444 0 012.444-2.444h86.45a2.447 2.447 0 012.448 2.444"
        fill="#3F2D51"
      />
      <path
        d="M116.727 53.234v.009H30.79c0-2.3.863-4.414 2.3-6.014a8.964 8.964 0 015.73-2.932c20.93-2.231 49.043-2.247 69.887-.009 4.565.49 8.02 4.352 8.02 8.946"
        fill="#FFFFFE"
      />
      <g fillRule="nonzero">
        <path
          d="M110.004 57C98.961 57 90 65.95 90 77s8.96 20 20.004 20S130 88.041 130 76.991 121.048 57 110.004 57z"
          fill="#FFC543"
        />
        <path
          d="M106.663 81.148l11.614-11.614a1.825 1.825 0 112.58 2.581L107.953 85.02a1.825 1.825 0 01-2.643-.066 1.856 1.856 0 01-.065-.062l-6.71-6.71a1.825 1.825 0 012.58-2.58l5.548 5.546z"
          fill="#3F2D51"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconBookingconfirmed;
