import React from 'react';

const SvgTiktok = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.294 18.048a19.638 19.638 0 0011.455 3.664v-8.216c-.81 0-1.615-.084-2.406-.252v6.468a19.642 19.642 0 01-11.456-3.664v16.766c0 8.387-6.802 15.185-15.193 15.185a15.126 15.126 0 01-8.458-2.568A15.151 15.151 0 0018.1 50c8.39 0 15.194-6.798 15.194-15.186V18.048zM36.26 9.76a11.448 11.448 0 01-2.967-6.704V1.998h-2.28A11.502 11.502 0 0036.26 9.76zM12.544 38.995a6.949 6.949 0 015.533-11.152c.714 0 1.425.11 2.107.325v-8.4a15.46 15.46 0 00-2.405-.138v6.538a6.97 6.97 0 00-2.109-.325 6.948 6.948 0 00-3.127 13.151v.001z"
      fill="#9EAAB2"
      fillOpacity={0.6}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.887 16.048a19.642 19.642 0 0011.456 3.664v-6.467a11.498 11.498 0 01-6.082-3.485 11.502 11.502 0 01-5.247-7.762h-5.988v32.815a6.95 6.95 0 01-6.951 6.923 6.943 6.943 0 01-5.532-2.741 6.946 6.946 0 013.127-13.151 6.956 6.956 0 012.109.325V19.63c-8.243.17-14.874 6.903-14.874 15.183 0 4.133 1.651 7.88 4.331 10.617A15.126 15.126 0 0015.694 48c8.391 0 15.193-6.799 15.193-15.186V16.048z"
      fill="#8C8197"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.343 13.246v-1.749a11.457 11.457 0 01-6.082-1.737 11.49 11.49 0 006.082 3.486zM31.013 2a11.993 11.993 0 01-.126-.944V0H22.62v32.815a6.948 6.948 0 01-6.949 6.922 6.921 6.921 0 01-3.127-.74 6.941 6.941 0 005.533 2.74 6.95 6.95 0 006.95-6.922V2h5.988zM17.78 19.633V17.77a15.326 15.326 0 00-2.085-.142C7.303 17.63.5 24.43.5 32.815c0 5.258 2.673 9.892 6.736 12.618a15.13 15.13 0 01-4.33-10.618c0-8.28 6.629-15.013 14.873-15.182z"
      fill="#8C8197"
      fillOpacity={0.3}
    />
  </svg>
);

export default SvgTiktok;
