import React from 'react';

const SvgIconPersonDisabled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 41" {...props}>
    <g fill="#d9d9d9" fillRule="evenodd">
      <path d="M29.71 39c.74 0 1.354-.561 1.285-1.226C30.268 30.587 24.96 25 18.5 25c-6.46 0-11.775 5.587-12.495 12.774C5.935 38.432 6.55 39 7.29 39h22.42zM10.084 11.933a6.924 6.924 0 003.494 6.005 7.033 7.033 0 006.986 0 6.924 6.924 0 003.493-6.005 6.924 6.924 0 00-3.493-6.004 7.033 7.033 0 00-6.986 0 6.924 6.924 0 00-3.494 6.004z" />
      <path d="M16.07 21.382c8.108 0 14.917 7.036 15.836 16.369.066.665-.168 1.307-.649 1.807a2.757 2.757 0 01-1.977.824H2.862a2.757 2.757 0 01-1.977-.824 2.242 2.242 0 01-.65-1.807c.92-9.333 7.729-16.369 15.836-16.369zm0 2.114c-6.946 0-12.8 6.213-13.61 14.448a.222.222 0 00.077.188c.078.085.205.13.33.13H29.28a.487.487 0 00.33-.13.228.228 0 00.079-.188c-.818-8.235-6.671-14.448-13.618-14.448zm0-23.442c5.386 0 9.767 4.351 9.767 9.694 0 5.342-4.381 9.693-9.766 9.693s-9.766-4.35-9.766-9.693S10.686.054 16.071.054zm0 2.23c-4.152 0-7.53 3.347-7.53 7.464 0 4.116 3.378 7.464 7.53 7.464 4.154 0 7.531-3.348 7.531-7.464 0-4.117-3.377-7.464-7.53-7.464z" />
    </g>
  </svg>
);

export default SvgIconPersonDisabled;
