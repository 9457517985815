import React from 'react';

const SvgIllustrationsOfferedAccommodationDesktop = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 258 160" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h258v160H0z" />
      <path
        d="M216.135 79.958s.453 6.126.453 6.353c0 .227-3.176 15.884-3.176 15.884s-4.539 8.623 0 8.396c4.538-.227 3.176-8.169 3.176-8.169l4.766-13.16-2.043-9.304h-3.176z"
        fill="#FFF"
      />
      <circle fill="#FFC543" cx={183} cy={16} r={14} />
      <circle fill="#FFF1BD" cx={169} cy={23} r={8} />
      <circle fill="#FFF1BD" cx={125} cy={41} r={8} />
      <circle fill="#FFF1BD" cx={110} cy={47} r={5} />
      <circle fill="#FFF1BD" cx={34} cy={67} r={5} />
      <path
        d="M229.976 100.607l10.211 2.723s.681 2.269.454 2.95c-.227.68.227 1.134 0 1.588-.227.454-.454 2.723-.454 2.723v11.119c0 .454.681 1.134.454 1.134-.227 0 .227 0 0 .681-.227.68 0 1.135 0 2.042 0 .448-.276 5.48-.583 10.736l-.075 1.28c-.294 4.98-.597 9.844-.703 10.902-.079.784 0 2.383.227 3.631l1.134 4.085s4.652 3.744-.567 3.29c-4.4-.383-5.01-.2-5.093-.134l-.009.008a.028.028 0 00-.002.004l-.002.005v.003s-1.588-.454-1.815-.907c-.216-.432 1.008-6.42 1.126-6.99-.2-1.7-.055-4.455-.006-5.262l.009-.144.006-.085-.908-19.968s.227-2.269-.908-3.177c-1.134-.907-2.95-10.21-2.95-10.21l-2.042 13.84v.064c-.005.32-.05 1.777-.454 1.98-.453.226 0 .907 0 .907s.227 1.361 0 2.042c-.227.68.454 15.657.454 15.657l.681 4.311s.68 6.58.454 7.035c-.227.453-1.816.907-1.816.907l.003-.001.008-.004.01-.006.01-.008a.06.06 0 00.005-.005l.006-.01c.03-.073-.33-.212-4.466.148-5.22.454-.568-3.29-.568-3.29l1.135-4.085-.019-.155c-.188-1.502-.662-4.383-.662-4.383l-2.814-16.854-.063-1.066s-.103-1.06-.103-1.631c0-.467-.006-.409-.025-.62l-.014-.19c-.03-.452-.06-.737-.06-1.418 0-.68-1.232-11.124-1.913-13.393-.68-2.269 0-9.076 0-9.076l1.135-1.589 11.572-1.134z"
        fill="#859094"
      />
      <path fill="#4C4C4C" d="M64.93 79.262h2.02v79.794h-2.02z" />
      <path
        d="M85.593 89.43c.128 27.903-19.502 50.613-19.502 50.613s-19.84-22.528-19.968-50.43C45.994 61.71 65.625 39 65.625 39s19.839 22.528 19.968 50.43z"
        fill="#4FE0BE"
      />
      <path fill="#F5F6F7" d="M51.169 126.021h156.343v33.13H51.169z" />
      <path fill="#57666A" d="M46.177 127.652l17.018-35.626h135.921l12.707 35.626z" />
      <path fill="#F5F6F7" d="M92.61 94.296h63.081v20.876H92.61z" />
      <path fill="#57666A" d="M86.71 98.153h74.881l-5.9-20.876H96.694z" />
      <path
        fill="#CCC"
        d="M94.169 132.602h49.807v26.548H94.17zM57.296 133.51h26.548v25.64H57.296z"
      />
      <path fill="#57666A" d="M152.599 135.098h13.728v24.052H152.6z" />
      <path fill="#F5F6F7" d="M0 159.037h258v1H0z" />
      <path fill="#859094" d="M40.823 106.406h1.333v52.656h-1.333z" />
      <path
        d="M54.458 113.117c.085 18.412-12.869 33.399-12.869 33.399s-13.092-14.867-13.176-33.28c-.085-18.412 12.869-33.398 12.869-33.398s13.092 14.866 13.176 33.279z"
        fill="#9DE7D6"
      />
      <path fill="#859094" d="M23.493 106.406h1.333v52.656h-1.333z" />
      <path
        d="M37.129 113.117c.085 18.412-12.87 33.399-12.87 33.399s-13.091-14.867-13.176-33.28c-.085-18.412 12.87-33.398 12.87-33.398s13.09 14.866 13.176 33.279z"
        fill="#9DE7D6"
      />
      <path
        fill="#57666A"
        d="M181.19 136.46h6.694v6.24h-6.694zM190.153 136.46h6.694v6.24h-6.694zM181.19 144.968h6.694v6.467h-6.694zM190.153 144.968h6.694v6.467h-6.694zM98.396 100.008h5.239v4.883h-5.24zM105.41 100.008h5.24v4.883h-5.24zM98.396 106.667h5.239v5.061h-5.24zM105.41 106.667h5.24v5.061h-5.24zM137.652 100.008h5.238v4.883h-5.238zM144.666 100.008h5.239v4.883h-5.239zM137.652 106.667h5.238v5.061h-5.238zM144.666 106.667h5.239v5.061h-5.239zM118.137 100.008h5.239v4.883h-5.239zM125.152 100.008h5.239v4.883h-5.24zM118.137 106.667h5.239v5.061h-5.239zM125.152 106.667h5.239v5.061h-5.24z"
      />
      <path
        fill="#F5F6F7"
        d="M155.662 137.534h3.299v3.075h-3.299zM160.079 137.534h3.298v3.075h-3.298zM155.662 141.727h3.299v3.187h-3.299zM160.079 141.727h3.298v3.187h-3.298z"
      />
      <circle fill="#F5F6F7" cx={164.512} cy={148.826} r={1} />
      <path
        fill="#F5F6F7"
        d="M94.169 135.438h49.92v1H94.17zM94.169 137.48h49.92v1H94.17zM94.169 139.522h49.92v1H94.17zM94.169 141.565h49.92v1H94.17zM94.169 143.607h49.92v1H94.17zM94.169 145.65h49.92v1H94.17zM94.169 147.691h49.92v1H94.17zM94.169 149.734h49.92v1H94.17zM94.169 151.776h49.92v1H94.17zM94.169 153.818h49.92v1H94.17zM94.169 155.86h49.92v1H94.17zM57.296 135.438h26.548v1H57.296zM57.296 137.48h26.548v1H57.296zM57.296 139.522h26.548v1H57.296zM57.296 141.565h26.548v1H57.296zM57.296 143.607h26.548v1H57.296zM57.296 145.65h26.548v1H57.296zM57.296 147.691h26.548v1H57.296zM57.296 149.734h26.548v1H57.296zM57.296 151.776h26.548v1H57.296zM57.296 153.818h26.548v1H57.296zM57.296 155.86h26.548v1H57.296z"
      />
      <path
        d="M198.717 72c9.942 0 18 8.059 18 18 0 7.294-4.338 13.575-10.575 16.402l-1.796 3.111-5.628 9.748-5.629-9.748-1.796-3.11c-6.237-2.828-10.576-9.109-10.576-16.403 0-9.941 8.06-18 18-18z"
        fill="#FE544A"
      />
      <circle fill="#FFF3F1" cx={198.863} cy={89.888} r={6.458} />
      <circle fill="#FFF" cx={221.354} cy={56.132} r={5.9} />
      <path
        d="M219.311 60.443s.908 4.539.908 5.22c0 .68 3.858 2.722 3.858 2.722l4.31-5.446s-2.722-3.857-2.722-4.765l-6.354 2.27z"
        fill="#FFF"
      />
      <path
        d="M227.475 61.592l7.266.893 2.27.908s2.496.454 3.176 4.992c.681 4.538 1.362 5.673.681 5.673-.302 0-2.084-.045-3.97-.114l-.299-.012 1.092 20.094s1.589 2.496 1.135 3.858c-.454 1.361 1.361 5.446 1.361 5.446s-7.034 3.177-12.934.907c-5.572-2.143-9.524-1.047-9.946-.919l-.034.01c-.004.002.319-2.064.689-4.198l.093-.534c.298-1.687.61-3.314.803-3.889.453-1.361 0-.68-.454-1.588s.907-6.808.907-7.715c0-.908.454-.681 0-.908-.417-.209-.45-2.914-.453-3.346v-.058l-.182-.323-3.222.323s-.422-2.11-1.47-3.685l-.119-.172c-1.134-1.589 0-4.992 0-4.992l.009-.08c.205-1.057 4.133-5.083 5.662-6.618l.404-.404.198-.196c.6.872 2.577 1.398 2.577 1.398 2.95-.227 4.76-4.75 4.76-4.75z"
        fill="#2A41EA"
      />
      <path
        d="M232.926 72.697s-1.314 3.378-1.04 6.722c.05.598.252 1.174.586 1.673l-16.098 7.643s-7.274.753-5.458 3.93c1.815 3.177 8.395-1.588 8.395-1.588s17.246-2.497 18.607-4.993c1.362-2.496 2.27-12.934 2.27-12.934l-7.262-.453z"
        fill="#FFF"
      />
      <path
        d="M216.85 49.897l-1.024-.41s2.14-2.356 5.117-2.15l-.838-.923s2.047-.819 3.908 1.332c.978 1.13 2.11 2.46 2.815 3.957h1.096l-.457 1.007 1.6 1.007-1.643-.18c.156.869.103 1.763-.155 2.608l.044.796s-1.905-2.947-1.905-3.357v1.024s-1.023-.921-1.023-1.536l-.558.717-.28-1.127-3.442 1.127.559-.922-2.14.308.837-1.127s-2.419 1.331-2.512 2.458c-.093 1.127-1.302 2.56-1.302 2.56l-.559-1.024s-.837-4.609 1.861-6.145z"
        fill="#859094"
      />
    </g>
  </svg>
);

export default SvgIllustrationsOfferedAccommodationDesktop;
