import React from 'react';

const SvgIconExtend = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.746 12.717L.776 17.19c-.238.24-.374.537-.376.802.014.31.147.6.371.814l5.004 4.504.008.007a.92.92 0 00.323.222c.104.04.227.061.394.061.576 0 1.095-.516 1.1-1.092a1.2 1.2 0 00-.372-.814L4.362 19.1H14.5c.579 0 1.1-.52 1.1-1.1 0-.58-.521-1.1-1.1-1.1H4.362l2.862-2.59c.24-.242.376-.543.376-.81 0-.58-.521-1.1-1.1-1.1-.255 0-.455.118-.754.317zm22.654.775c.015.31.148.6.372.815l2.866 2.593H21.5c-.58 0-1.1.52-1.1 1.1 0 .58.52 1.1 1.1 1.1h10.138l-2.866 2.594a1.2 1.2 0 00-.372.814c.005.576.523 1.092 1.1 1.092.166 0 .29-.02.394-.06a.92.92 0 00.323-.223l.008-.007 5.003-4.504a1.2 1.2 0 00.372-.814c-.003-.265-.139-.562-.376-.802l-4.97-4.473c-.3-.2-.5-.317-.754-.317-.576 0-1.095.516-1.1 1.092z"
    />
  </svg>
);

export default SvgIconExtend;
