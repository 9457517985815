import React from 'react';

const SvgFacilityCurtains = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g strokeLinecap="round" strokeLinejoin="round" fill="none" fillRule="evenodd">
      <path
        d="M4.174 4.196v16.043h3.13c.136-1.92-.282-4.955-1.252-5.87 2.426-1.565 5.165-5.086 5.165-10.173H4.174zm15.652 0v16.043h-3.13c-.136-1.92.282-4.955 1.252-5.87-2.426-1.565-5.165-5.086-5.165-10.173h7.043z"
        stroke="#494946"
      />
      <path stroke="#4C4C4C" d="M3 4.196h18M3.783 14.37h3.13m10.174 0h3.13" />
    </g>
  </svg>
);

export default SvgFacilityCurtains;
