import React from 'react';

const SvgVisaDisabled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 17" {...props}>
    <path
      d="M26.893 5.368c-.03 2.34 2.085 3.645 3.677 4.42 1.637.797 2.186 1.308 2.18 2.02-.012 1.09-1.305 1.57-2.515 1.59-2.111.032-3.339-.57-4.315-1.026l-.76 3.558c.979.451 2.792.845 4.672.862 4.413 0 7.3-2.178 7.315-5.556.017-4.286-5.929-4.523-5.888-6.44.014-.58.568-1.2 1.783-1.358.601-.08 2.261-.14 4.143.726l.738-3.443C36.912.353 35.611 0 33.992 0c-4.154 0-7.075 2.208-7.099 5.368M45.02.297c-.805 0-1.485.47-1.788 1.191L36.93 16.54h4.41l.877-2.425h5.388l.51 2.425H52L48.608.297H45.02m.617 4.387l1.273 6.1h-3.486l2.213-6.1M21.547.297L18.07 16.539h4.202L25.747.297h-4.2m-6.217 0l-4.373 11.055-1.77-9.4C8.98.902 8.16.297 7.25.297H.1L0 .768c1.468.319 3.135.833 4.146 1.382.618.336.794.63.997 1.427L8.494 16.54h4.441L19.743.297H15.33"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgVisaDisabled;
