import React from 'react';

const SvgMobileSetup202 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M31 0c17.12 0 31 13.88 31 31 0 16.95-13.603 30.722-30.487 30.996L31 62v-6c13.807 0 25-11.193 25-25C56 17.331 45.03 6.224 31.413 6.003L31 6V0z"
      fill="#FFF02C"
    />
  </svg>
);

export default SvgMobileSetup202;
