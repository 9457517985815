import React from 'react';

const SvgIconRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M32.344 17.027L17.164 3.852a.645.645 0 00-.422-.156h-3.555a.32.32 0 00-.21.562l14.071 12.215H3.536a.322.322 0 00-.322.321v2.41c0 .177.145.322.322.322h23.508L12.974 31.74a.32.32 0 00.209.563h3.676a.309.309 0 00.209-.08l15.276-13.251a1.29 1.29 0 000-1.945z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgIconRight;
