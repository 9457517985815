import React from 'react';

const SvgProfileName = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M31.92 28.104a15.025 15.025 0 00-3.238-4.801 15.092 15.092 0 00-4.801-3.239c-.016-.008-.032-.012-.048-.02a9.943 9.943 0 004.13-8.076A9.963 9.963 0 0018 2.004a9.963 9.963 0 00-9.964 9.964 9.956 9.956 0 004.13 8.08c-.016.008-.032.012-.048.02-1.8.76-3.415 1.848-4.802 3.239a15.093 15.093 0 00-3.238 4.801 14.934 14.934 0 00-1.185 5.549.321.321 0 00.321.33h2.41a.32.32 0 00.322-.314 11.979 11.979 0 013.528-8.209A11.974 11.974 0 0118 21.933c3.222 0 6.248 1.253 8.526 3.531a11.978 11.978 0 013.527 8.209.318.318 0 00.322.313h2.41a.323.323 0 00.322-.33 14.974 14.974 0 00-1.185-5.552zM18 18.879a6.87 6.87 0 01-4.886-2.025 6.866 6.866 0 01-2.025-4.886c0-1.844.72-3.58 2.025-4.885A6.866 6.866 0 0118 5.058c1.844 0 3.58.719 4.886 2.025a6.866 6.866 0 012.025 4.885c0 1.845-.72 3.58-2.025 4.886A6.866 6.866 0 0118 18.879z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgProfileName;
