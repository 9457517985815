import React from 'react';

const SvgIconMaster = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 40 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <rect id="icon-master_svg__a" width={40} height={24} rx={2} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" xlinkHref="#icon-master_svg__a" />
      <rect stroke="#E7E7E7" x={0.5} y={0.5} width={39} height={23} rx={2} />
      <g fillRule="nonzero">
        <path fill="#FF5F00" d="M17.29 7.34h5.38v9.68h-5.38z" />
        <path
          d="M17.63 12.18c0-1.89.87-3.67 2.35-4.84a6.15 6.15 0 100 9.68 6.14 6.14 0 01-2.35-4.84z"
          fill="#EB001B"
        />
        <path
          d="M29.94 12.18a6.15 6.15 0 01-9.96 4.84 6.15 6.15 0 000-9.68 6.15 6.15 0 019.96 4.84zm-.59 4.07v-.41h.08v-.09h-.2v.09h.09v.41h.03zm.4 0v-.5h-.06l-.08.36-.07-.36h-.05v.5h.04v-.37l.07.32h.04l.07-.32v.37h.04z"
          fill="#F79E1B"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconMaster;
