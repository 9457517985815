import React from 'react';

const SvgIconPayment = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.51 5.379a1.674 1.674 0 00-1.179-.49h-7.52l-.55 1.252h7.57c.48 0 .871.387.871.86v8.243a.869.869 0 01-.87.862H7.882l-.57 1.298h13.02A1.674 1.674 0 0022 15.735V6.557a1.674 1.674 0 00-.49-1.178zM6.764 14.706a.127.127 0 00-.127-.126H1.978a.127.127 0 00-.127.126v.76a.127.127 0 00.127.127h4.66a.127.127 0 00.126-.127v-.76zm.11-3.852a.127.127 0 00-.127-.126h.001H.127a.127.127 0 00-.127.126v.76a.127.127 0 00.127.127h6.62a.127.127 0 00.127-.126v-.76zm3.988-3.878a.127.127 0 00-.126-.126H3.543a.127.127 0 00-.127.126v.76a.127.127 0 00.127.127h7.193c.07 0 .127-.057.127-.126v-.76zm7.151.091h-6.159l-3.524 8.02h9.683a1.483 1.483 0 011.484-1.484V8.551a1.483 1.483 0 01-1.484-1.483zm-3.687 6.152v.703H13.9v-.683c-.392-.007-.798-.129-1.034-.297l.162-.453a1.8 1.8 0 00.974.29c.48 0 .804-.277.804-.663 0-.372-.263-.601-.764-.804-.69-.27-1.116-.582-1.116-1.17 0-.561.4-.987 1.022-1.089V8.37h.419v.657a1.8 1.8 0 01.88.236l-.17.446a1.634 1.634 0 00-.852-.23c-.52 0-.717.311-.717.582 0 .351.25.527.839.77.696.285 1.048.636 1.048 1.238 0 .534-.372 1.035-1.069 1.15z"
    />
  </svg>
);

export default SvgIconPayment;
