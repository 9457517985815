import React from 'react';

const SvgBookingSummaryKitchen = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx={14} cy={14} r={14} />
      <path d="M9.75 12.833h10.667v6.417H8.75v-5.417a1 1 0 011-1z" fill="#FFC543" />
      <path
        d="M9.39 9.05a.76.76 0 01.757-.771c.435 0 .773.336.773.772 0 .178.144.341.34.341a.356.356 0 00.343-.34c0-.814-.643-1.457-1.456-1.457-.791 0-1.44.643-1.44 1.455v1.961H7.326a.317.317 0 00-.312.327v8.198c0 .178.134.327.312.327h13.306a.317.317 0 00.312-.327v-8.198a.318.318 0 00-.312-.327H9.39v-1.96h0zm4.93 2.644h5.955v1.367H14.32v-1.367zm0 2.05h5.955v5.435H14.32v-5.435zm-.682-2.05v7.485H7.682v-7.485h5.956zm1.678 6.639a.317.317 0 01-.312-.327v-2.08a.317.317 0 01.312-.326h3.951a.33.33 0 01.327.327v2.079a.33.33 0 01-.327.327h-3.95 0zm.356-2.05v1.366h3.238v-1.366h-3.237 0z"
        stroke="#4C4C4C"
        strokeWidth={0.2}
        fill="#4C4C4C"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgBookingSummaryKitchen;
