import React from 'react';

const SvgBeyooLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={120} height={36} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#beyoo-logo_svg__clip0_317_14626)">
      <path
        d="M101.974 9.72c-1.032 0-2.01.217-2.932.597-.923.38-1.738.923-2.39 1.629a7.415 7.415 0 00-1.629 2.443c-.38.923-.597 1.9-.597 2.986 0 1.087.218 2.01.597 2.933.38.923.924 1.737 1.63 2.389.705.706 1.466 1.249 2.389 1.629.923.38 1.9.597 2.932.597a7.648 7.648 0 002.932-.597 7.415 7.415 0 002.443-1.63c.706-.705 1.249-1.465 1.629-2.388.38-.923.598-1.9.598-2.933 0-1.031-.218-2.008-.598-2.986a7.412 7.412 0 00-1.629-2.443 7.415 7.415 0 00-2.443-1.63 7.248 7.248 0 00-2.932-.597zm0 17.755c-1.358 0-2.66-.271-3.91-.76-1.249-.543-2.335-1.249-3.312-2.226-.923-.978-1.683-2.064-2.226-3.313-.543-1.248-.76-2.552-.76-3.855 0-1.357.271-2.66.76-3.91.489-1.248 1.249-2.334 2.226-3.311.977-.924 2.063-1.684 3.312-2.227 1.25-.543 2.552-.76 3.91-.76 1.357 0 2.66.272 3.909.76 1.249.543 2.335 1.25 3.312 2.227.924.977 1.684 2.063 2.227 3.312.543 1.249.76 2.552.76 3.91 0 1.357-.272 2.66-.76 3.854a10.172 10.172 0 01-2.227 3.313c-.977.923-2.063 1.683-3.312 2.226-1.194.543-2.497.76-3.909.76z"
        fill="#AAD7D1"
      />
      <path
        d="M17.974 9.72c-1.032 0-2.01.217-2.932.597-.923.38-1.738.923-2.39 1.629-.705.706-1.248 1.466-1.628 2.389-.38.923-.598 1.9-.598 2.932s.217 2.009.598 2.932c.38.923.923 1.738 1.629 2.443.705.706 1.466 1.25 2.389 1.63.923.38 1.9.597 2.932.597a7.647 7.647 0 002.932-.598c.923-.38 1.738-.923 2.389-1.629.706-.705 1.249-1.465 1.629-2.443.38-.923.597-1.9.597-2.932s-.217-2.01-.597-2.932c-.38-.923-.923-1.738-1.629-2.39-.706-.705-1.466-1.248-2.39-1.628-.922-.435-1.9-.598-2.931-.598zm-7.222 14.769c-.977-.978-1.683-2.064-2.226-3.313a10.66 10.66 0 01-.76-3.909V.543h2.606v9.937c.054-.109.217-.218.38-.38a10.522 10.522 0 013.258-2.227c1.249-.543 2.552-.76 3.91-.76 1.357 0 2.66.272 3.909.76 1.249.489 2.335 1.25 3.258 2.227.977.977 1.683 2.063 2.226 3.312.489 1.249.76 2.552.76 3.855 0 1.357-.271 2.66-.76 3.91-.489 1.248-1.249 2.334-2.226 3.312a10.524 10.524 0 01-3.258 2.226c-1.249.543-2.552.76-3.91.76-1.357 0-2.66-.271-3.909-.76-1.195-.543-2.28-1.303-3.258-2.226zm28.995-14.77c-.814 0-1.574.109-2.334.38a9.168 9.168 0 00-2.064 1.032c-.597.435-1.14.978-1.629 1.575-.488.597-.814 1.303-1.14 2.063h14.28a7.88 7.88 0 00-2.714-3.638c-1.303-.977-2.77-1.412-4.398-1.412zM32.2 17.375c0 1.032.217 2.01.597 2.933s.978 1.683 1.63 2.389c.65.706 1.465 1.194 2.388 1.575.924.38 1.9.597 2.933.597 1.411 0 2.715-.326 3.855-1.032 1.14-.706 2.063-1.629 2.715-2.823h2.932c-.543 1.303-1.303 2.497-2.28 3.475-.978.923-2.064 1.683-3.313 2.226-1.249.543-2.498.76-3.91.76-1.411 0-2.66-.271-3.909-.76-1.249-.489-2.335-1.249-3.258-2.226-.977-.978-1.683-2.064-2.226-3.313-.489-1.248-.76-2.497-.76-3.909 0-1.357.271-2.66.76-3.91.489-1.248 1.249-2.334 2.226-3.257.923-.978 2.01-1.738 3.258-2.227a10.66 10.66 0 013.91-.76c1.357 0 2.66.272 3.909.76 1.249.543 2.335 1.25 3.312 2.227.978.923 1.684 2.063 2.227 3.312.488 1.249.76 2.606.76 3.963H32.2z"
        fill="#9C9C9A"
      />
      <path
        d="M65.484 25.195c-.814.705-1.683 1.303-2.66 1.683a8.484 8.484 0 01-3.095.597c-1.14 0-2.172-.217-3.204-.597s-1.9-1.032-2.715-1.792c-.814-.814-1.412-1.683-1.846-2.715a8.048 8.048 0 01-.651-3.204V7.982h2.606v11.131c0 .815.163 1.52.489 2.226.325.706.705 1.303 1.248 1.847.543.543 1.14.923 1.846 1.248.706.326 1.467.435 2.227.435.76 0 1.52-.163 2.172-.435.706-.271 1.303-.651 1.792-1.194.488-.543.923-1.086 1.249-1.738.325-.651.488-1.357.542-2.117V7.982h2.607V27.04c0 1.249-.163 2.389-.543 3.366-.326 1.032-.815 1.9-1.466 2.607a5.805 5.805 0 01-2.39 1.683c-.922.38-2.008.597-3.149.597v-2.606c.869 0 1.63-.163 2.227-.489.597-.326 1.14-.706 1.52-1.249.38-.543.706-1.14.869-1.792.163-.706.271-1.411.271-2.117l.054-1.846zm14.77-15.476c-1.032 0-2.01.218-2.933.598-.922.38-1.737.923-2.389 1.629-.706.706-1.249 1.466-1.629 2.443-.38.923-.597 1.9-.597 2.932s.217 2.01.597 2.932c.38.923.924 1.738 1.63 2.39.705.705 1.465 1.248 2.388 1.628.924.38 1.901.598 2.933.598a7.646 7.646 0 002.932-.598 7.414 7.414 0 002.443-1.629c.706-.705 1.25-1.466 1.63-2.389.38-.923.597-1.9.597-2.932a7.647 7.647 0 00-.598-2.932 7.415 7.415 0 00-1.629-2.443 7.414 7.414 0 00-2.443-1.63c-.923-.434-1.9-.597-2.932-.597zm0 17.756c-1.358 0-2.661-.271-3.91-.76-1.249-.543-2.335-1.249-3.312-2.226-.923-.978-1.683-2.064-2.226-3.313-.543-1.248-.76-2.552-.76-3.855 0-1.357.271-2.66.76-3.91.488-1.248 1.249-2.334 2.226-3.311.977-.924 2.063-1.684 3.312-2.227 1.249-.543 2.498-.76 3.91-.76 1.357 0 2.66.272 3.91.76 1.248.543 2.334 1.25 3.311 2.227.924.977 1.684 2.063 2.227 3.312.543 1.249.76 2.552.76 3.91 0 1.357-.272 2.606-.76 3.854-.489 1.25-1.25 2.335-2.227 3.313-.977.923-2.063 1.683-3.312 2.226a10.66 10.66 0 01-3.91.76z"
        fill="#AAD7D1"
      />
    </g>
    <defs>
      <clipPath id="beyoo-logo_svg__clip0_317_14626">
        <path fill="#fff" d="M0 0h120v35.837H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgBeyooLogo;
