import React from 'react';

const SvgFacilityFreezer = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M7 3h11v18H7zm0 6.305h11m-9.2-4.42v2.39m0 3.89v2.298"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityFreezer;
