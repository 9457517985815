import React from 'react';

const SvgInspectionDamage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M32.648 7.045a.514.514 0 00-.808-.1l-6.923 6.922-2.728-2.728 6.927-6.927a.512.512 0 00-.1-.804 10.3 10.3 0 00-12.508 1.596 10.303 10.303 0 00-2.672 9.94L2.064 26.716a.312.312 0 00.012.442l6.818 6.819c.124.124.325.132.442.012L21.104 22.22c3.436.916 7.252.029 9.948-2.668a10.3 10.3 0 001.596-12.507zm-3.64 10.458a7.404 7.404 0 01-8.623 1.35l-.354.354-.004-.004L9.018 30.216l-3.182-3.182 9.245-9.245s0 .004.005.004l2.12-2.122a7.404 7.404 0 014.009-10.329 7.4 7.4 0 013.128-.443l-5.34 5.336a1.287 1.287 0 000 1.82l5.002 5.002a1.288 1.288 0 001.82 0l5.336-5.335a7.41 7.41 0 01-2.154 5.781z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgInspectionDamage;
