import React from 'react';

const SvgInspectionTodo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.4 5.856c1.778 0 3.224 1.429 3.224 3.182v16.448a13.061 13.061 0 00-5.145-1.048c-7.236 0-13.102 5.866-13.102 13.102 0 2.11.499 4.105 1.386 5.87H9.197c-1.778 0-3.223-1.427-3.223-3.181V9.039c0-1.756 1.445-3.183 3.223-3.183H37.4z"
      fill="#FFC543"
    />
    <path
      d="M36.255 0l.16.002.246.013c2.529.204 4.509 2.303 4.509 4.85v20.869a13.073 13.073 0 00-2.675-.947V4.866l-.004-.123-.018-.182a2.233 2.233 0 00-2.216-1.912H4.974l-.123.003-.185.018a2.226 2.226 0 00-1.93 2.196v34.7l.004.122.017.183a2.234 2.234 0 002.217 1.911h18.105c.32.936.743 1.824 1.256 2.65H4.973l-.158-.003-.246-.013C2.04 44.212.06 42.114.06 39.566v-34.7l.002-.157.015-.244C.282 1.96 2.4 0 4.974 0h31.28z"
      fill="#3F2D51"
    />
    <path
      d="M22.582 28.027H9.354a1.454 1.454 0 00-1.461 1.448 1.453 1.453 0 001.356 1.442l.105.005h13.228a1.455 1.455 0 001.46-1.447 1.454 1.454 0 00-1.356-1.442l-.104-.005zm5.541-9.56H9.353a1.453 1.453 0 00-1.46 1.448 1.453 1.453 0 001.356 1.442l.105.004h18.77a1.453 1.453 0 001.46-1.446 1.453 1.453 0 00-1.356-1.443l-.105-.004zm-11.888-9.56H9.354a1.453 1.453 0 00-1.461 1.447 1.453 1.453 0 001.356 1.443l.105.004h6.881a1.455 1.455 0 001.461-1.447 1.453 1.453 0 00-1.356-1.443l-.105-.004z"
      fill="#3F2D51"
    />
    <path
      d="M35.48 48c5.776 0 10.46-4.683 10.46-10.46 0-5.777-4.684-10.46-10.46-10.46-5.777 0-10.46 4.683-10.46 10.46 0 5.777 4.683 10.46 10.46 10.46z"
      fill="#FE544A"
    />
    <path
      d="M35.151 40.117a1.324 1.324 0 11.077 2.646 1.324 1.324 0 01-.077-2.646zm-.038-8.633c.63 0 1.14.51 1.14 1.14v5.318a1.14 1.14 0 11-2.28 0v-5.318c0-.629.511-1.14 1.14-1.14z"
      fill="#fff"
    />
  </svg>
);

export default SvgInspectionTodo;
