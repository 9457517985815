import React from 'react';

const SvgAllSet = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 44 47" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M34.568 5.828c1.63 0 2.955 1.32 2.955 2.943l.001 13.825a12.927 12.927 0 00-5.37-1.16c-7.166 0-12.975 5.809-12.975 12.974 0 2.224.56 4.316 1.545 6.146l-12.012-.002a2.952 2.952 0 01-2.955-2.943V8.771a2.952 2.952 0 012.955-2.943h25.856z"
        fill="#88D3A6"
        fillRule="nonzero"
      />
      <circle fill="#88D3A6" cx={32.718} cy={34.974} r={11.282} />
      <path
        d="M33.518.412l.146.002.226.013a4.502 4.502 0 014.129 4.271l.005.213-.001 17.925a12.886 12.886 0 00-2.452-.945V4.91l-.003-.112-.017-.17a2.054 2.054 0 00-2.031-1.767H4.84l-.114.003-.169.017A2.054 2.054 0 002.794 4.75l-.006.161V37l.003.113.017.17c.132.949.912 1.685 1.87 1.76l.161.007h15.194c.332.865.753 1.685 1.252 2.45H4.84l-.144-.003-.226-.012A4.502 4.502 0 01.34 37.212L.335 37V4.911l.003-.144.012-.226A4.502 4.502 0 014.626.417L4.84.412h28.679zm3.676 30.273a1.156 1.156 0 011.63 0 1.15 1.15 0 010 1.63l-6.982 6.948a1.154 1.154 0 01-1.63 0l-3.617-3.598a1.15 1.15 0 010-1.63 1.156 1.156 0 011.63 0l2.802 2.785zM20.982 26.33l.096.003c.267.02.513.116.714.27a12.916 12.916 0 00-1.436 2.402H8.855l-.096-.003a1.338 1.338 0 01-.033-2.666l.129-.006h12.127zm5.08-8.84l.096.003a1.338 1.338 0 01.034 2.666l-.13.006H8.856l-.096-.003a1.338 1.338 0 01-.033-2.667l.129-.006h17.208zM15.165 8.648l.096.003a1.338 1.338 0 01.033 2.666l-.129.006h-6.31l-.095-.003a1.338 1.338 0 01-.033-2.666l.129-.006h6.31z"
        fill="#666"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgAllSet;
