import React from 'react';

const SvgRulesDisableYes = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <circle fill="#EBEAED" cx={12} cy={12} r={12} />
      <path
        d="M7.12 10.43a.75.75 0 11.75 1.3 3.22 3.22 0 104.74 3.55.75.75 0 111.46.35 4.72 4.72 0 11-6.95-5.2zm2-5.68a2.2 2.2 0 01.88 4.2l.02.13h2.51c.38 0 .7.29.74.65l.01.1c0 .42-.34.75-.75.75h-2.2l.39 1.82h4.43c.32 0 .6.2.7.5l1.28 3.7 1.13-.38a.75.75 0 11.48 1.42l-1.84.62a.75.75 0 01-.95-.47l-1.34-3.9h-4.5a.75.75 0 01-.73-.59l-.9-4.25a2.2 2.2 0 01.63-4.3h.01zm0 1.5a.7.7 0 10-.167 1.39.7.7 0 00.167-1.39z"
        fill="#3F2D51"
      />
    </g>
  </svg>
);

export default SvgRulesDisableYes;
