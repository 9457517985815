import React from 'react';

const SvgFacilitySurfaces = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g stroke="#4C4C4C" fill="none" fillRule="evenodd">
      <path strokeLinejoin="round" d="M7.332 14.307h9.336V9.693H7.332z" />
      <path d="M7.332 9.693L4.264 4.275l3.068 5.418z" strokeLinejoin="round" />
      <path d="M9.666 9.693L8 4l1.666 5.693zm2.334 0V4v5.693zm2.334 0L16 4l-1.666 5.693z" />
      <path d="M16.668 9.693l3.187-5.44-3.187 5.44z" strokeLinejoin="bevel" />
      <path d="M5.111 5.898h13.778H5.111zm1.111 1.897h11.556H6.222zm1.11 1.898h9.336-9.336z" />
      <path
        d="M5 20h14a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v14a1 1 0 001 1z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.332 14.307L5.46 17.713l1.873-3.406zm9.336 0l1.75 3.347-1.75-3.347z"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgFacilitySurfaces;
