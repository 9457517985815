import React from 'react';

const SvgVerificationUnableImage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path d="M24.586 10.82a2.469 2.469 0 104.938 0 2.469 2.469 0 00-4.938 0z" />
    <path d="M34.73 3.574a1.097 1.097 0 00-.752-.374L18.34 2.107a1.081 1.081 0 00-.932.407l-3.16 3.938a1.096 1.096 0 00.023 1.4l5.01 5.827-5.056 6.674 1.251-5.944a1.096 1.096 0 00-.381-1.077l-6.566-5.34 2.729-4.309a1.097 1.097 0 00-1.002-1.68l-8.236.575a1.096 1.096 0 00-1.018 1.17l1.913 27.358a1.099 1.099 0 001.176 1.02l6.052-.454a1.097 1.097 0 10-.165-2.187l-4.952.37-.221-3.171 4.296-.431a1.1 1.1 0 00.984-1.202 1.1 1.1 0 00-1.203-.984l-4.229.427L3.27 4.69l4.976-.349-2.09 3.3a1.096 1.096 0 00.234 1.437l6.802 5.531-2.014 9.576a1.06 1.06 0 00.025.505c-.007.129.01.257.048.38l1.767 5.297c.137.414.506.707.941.747l17.933 1.63a1.098 1.098 0 001.195-1.016L34.998 4.37a1.1 1.1 0 00-.267-.796zM31.34 25.2l-14.143-1.36a1.081 1.081 0 00-1.198.987 1.097 1.097 0 00.988 1.198l14.2 1.365-.214 3.068-16.103-1.464-1.357-4.07 8.05-10.628a1.095 1.095 0 00-.043-1.378l-4.992-5.805 2.23-2.78 13.973.977-1.39 19.89z" />
  </svg>
);

export default SvgVerificationUnableImage;
