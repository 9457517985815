import React from 'react';

const SvgVerificationRefresh = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M33.955 5.838L31.689 7.61c-3.09-3.95-7.895-6.485-13.291-6.485-9.318 0-16.86 7.533-16.871 16.855-.012 9.33 7.545 16.895 16.87 16.895 7.285 0 13.493-4.62 15.855-11.093a.322.322 0 00-.197-.414l-2.278-.784a.321.321 0 00-.405.193 13.748 13.748 0 01-3.198 4.994 13.731 13.731 0 01-9.767 4.05c-1.87 0-3.678-.365-5.377-1.084a13.72 13.72 0 01-4.392-2.966 13.747 13.747 0 01-2.96-4.395 13.75 13.75 0 01-1.085-5.38c0-1.868.365-3.676 1.084-5.38a13.748 13.748 0 012.961-4.395 13.73 13.73 0 019.767-4.05 13.72 13.72 0 019.768 4.05c.398.397.772.82 1.117 1.261l-2.418 1.889a.322.322 0 00.12.566l7.055 1.728a.323.323 0 00.398-.31l.032-7.268a.324.324 0 00-.522-.249z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgVerificationRefresh;
