import React from 'react';

const SvgCheckIn = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 38 44" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.86 4.24h32.162c1.135 0 2.023-.788 2.023-1.726V.837H.837v1.677c0 .938.937 1.727 2.023 1.727m0 34.974h32.162c1.135 0 2.023.74 2.023 1.677v1.727H.837v-1.727c0-.937.937-1.677 2.023-1.677"
        fill="#D85145"
      />
      <path
        d="M29.793 4.24c0 8.09-4.538 14.948-10.852 17.463-6.314-2.515-10.803-9.372-10.803-17.462h21.655m0 34.974c0-8.09-4.538-14.947-10.852-17.512-6.314 2.565-10.803 9.422-10.803 17.512h21.655"
        fill="#FFF"
      />
      <path
        d="M19 31.014c-3.274 1.306-5.963 4.097-7.6 7.6h15.2c-1.578-3.503-4.268-6.294-7.6-7.6m-.059-11.441c-2.121-1.154-3.848-3.597-4.883-6.65h9.816c-1.036 3.053-2.812 5.496-4.933 6.65"
        fill="#D85145"
      />
      <path
        d="M18.94 22.541a.82.82 0 01-.311-.06c-6.775-2.698-11.328-10.03-11.328-18.24 0-.463.374-.837.838-.837.463 0 .837.374.837.838 0 7.402 3.99 14.006 9.964 16.554 6.004-2.558 10.015-9.162 10.015-16.554 0-.464.374-.838.838-.838.464 0 .837.374.837.838 0 8.2-4.573 15.53-11.379 18.24a.82.82 0 01-.31.059"
        fill="#2F2F2F"
      />
      <path
        d="M29.793 40.052a.836.836 0 01-.838-.838c0-7.372-4.01-13.995-10.015-16.602-5.973 2.597-9.964 9.22-9.964 16.602a.836.836 0 01-.837.838.836.836 0 01-.838-.838c0-8.188 4.55-15.536 11.323-18.287a.846.846 0 01.632 0C26.06 23.69 30.63 31.038 30.63 39.214a.836.836 0 01-.837.838"
        fill="#2F2F2F"
      />
      <path
        d="M18.94 30.928a.836.836 0 01-.838-.838v-1.283c0-.464.374-.838.838-.838.464 0 .838.374.838.838v1.283a.836.836 0 01-.838.838m0-3.85a.836.836 0 01-.838-.837v-1.282c0-.464.374-.837.838-.837.464 0 .838.373.838.837v1.282a.836.836 0 01-.838.838"
        fill="#D85145"
      />
      <path
        d="M1.676 1.676v.839c0 .464.564.889 1.184.889h32.162c.642 0 1.184-.408 1.184-.889v-.84H1.676zM35.022 5.08H2.86C1.31 5.08 0 3.905 0 2.515V.838C0 .374.374 0 .838 0h36.206c.464 0 .838.374.838.838v1.677c0 1.414-1.283 2.565-2.86 2.565zM1.676 41.78h34.53v-.889c0-.462-.532-.839-1.184-.839H2.86c-.63 0-1.184.392-1.184.84v.888zm35.368 1.676H.838A.836.836 0 010 42.618v-1.727c0-1.386 1.283-2.515 2.86-2.515h32.162c1.604 0 2.86 1.105 2.86 2.515v1.727a.836.836 0 01-.838.838z"
        fill="#2F2F2F"
      />
      <path
        d="M2.86 40.052a.836.836 0 01-.838-.838V4.242c0-.464.374-.838.838-.838.464 0 .838.374.838.838v34.972a.836.836 0 01-.838.838m32.162 0a.836.836 0 01-.838-.838V4.242c0-.464.374-.838.838-.838.464 0 .838.374.838.838v34.972a.836.836 0 01-.838.838"
        fill="#2F2F2F"
      />
    </g>
  </svg>
);

export default SvgCheckIn;
