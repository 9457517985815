import React from 'react';

const SvgStep3 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5 53.624A27.874 27.874 0 0031 59c6.14 0 11.817-1.976 16.432-5.326C54.442 48.585 59 40.324 59 31c0-3-.472-5.89-1.346-8.6C54.025 11.143 43.464 3 31 3"
      stroke="#FFC543"
      strokeWidth={6}
      fill="none"
    />
  </svg>
);

export default SvgStep3;
