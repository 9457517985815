import React from 'react';

const SvgImageError = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 380 210" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M145.626 204.05c0 2.805-15.402 5.08-34.4 5.08-19 0-34.401-2.275-34.401-5.08 0-2.804 15.402-5.078 34.4-5.078 19 0 34.401 2.274 34.401 5.079"
        fill="#EBEBEB"
      />
      <path
        d="M181.255 204.05c0 2.805-15.402 5.08-34.4 5.08-19 0-34.4-2.275-34.4-5.08 0-2.804 15.4-5.078 34.4-5.078 18.998 0 34.4 2.274 34.4 5.079m101.236 0c0 2.804-15.402 5.078-34.4 5.078-19 0-34.402-2.274-34.402-5.078 0-2.805 15.402-5.079 34.402-5.079 18.998 0 34.4 2.274 34.4 5.079m88.212 1.149c0 2.17-11.918 3.93-26.619 3.93-14.702 0-26.62-1.76-26.62-3.93s11.918-3.93 26.62-3.93c14.7 0 26.619 1.76 26.619 3.93"
        fill="#EBEBEB"
      />
      <path
        d="M271.751 146.674c-1.172-1.41-2.6-1.703-4.062-2.726-2.362-1.649-3.476-3.779-5.03-6.176-2.227-3.427-4.082-6.742-5.077-10.775-1.976-8.01-.82-17.632 2.515-25.13 4.29-9.647 11.512-14.449 19.814-19.73 1.027 5.882.873 12.499.462 18.425-.28 4.045-1.626 16.32 3.696 17.869 7.567 2.2 1.185-19.612.914-21.95-.633-5.465.284-10.8-.229-16.075 6.878-1.284 13.458-7.031 20.724-6.957-2.632 4.663-12.028 27.866-2.334 30.217 6.686 1.62 4.945-12.89 4.91-16.735-.044-4.745-.408-10.076 1.174-14.62 2.224.77 5.283-.14 8.323-1.05 2.359-.706 4.708-1.412 6.648-1.332-1.418 3.878-7.308 25.119.015 25.317 6.13.166 4.21-18.302 6.923-22.26 1.676-2.445 4.125-2.878 7.057-3.046 7.956-.454 15.714.143 23.626.249-2.221 5.185-2.425 11.589-2.509 17.147-.062 4.166.057 10.719-3.491 13.848-2.955 2.61-6.968 2.161-11.036 1.716-4.08-.45-8.216-.898-11.404 1.734 1.665 3.507 9.078 3.648 12.233 4.519 3.604.993 6.544 2.213 10.246 1.366-1.33 5.176-2.64 11.362-4.889 16.15-4.833-3.174-11.404-3.79-16.966-4.809-3.81-.697-12.695-2.852-15.345 1.658-3.357 5.717 9.586 6.86 12.734 6.806 6.024-.104 11.427.142 17.276 1.716-1.88 5.537-4.135 12.104-7.162 17.151-5.493-5.545-15.18-7.798-22.844-9.053-3.028-.498-13.287-3.022-15.223.845-1.274 2.547 2.38 5.75 4.002 7.003 5.52 4.264 12.451 4.795 19.096 5.353 4.068.341 8.707.78 12.324 2.76-11.537 15.967-38.41 24.11-55.94 12.77-4.39-2.84-13.351-9.914-12.995-15.845.152-2.521 2.111-4.787 1.824-6.35"
        fill="#E2E6E8"
      />
      <path
        d="M302.553 157.297a.136.136 0 01-.12-.073 12.542 12.542 0 00-1.125-1.551c.454.448.908.883 1.361 1.35.12.122 0 .274-.116.274m-1.245-1.624a85.942 85.942 0 00-3.092-2.92c1.14.898 2.205 1.858 3.092 2.92m-3.092-2.92c-2.395-1.884-5.14-3.485-7.386-5.121-3.321-2.418-5.89-5.229-8.476-8.327-1.44.987-2.845 2.011-4.073 3.213a.724.724 0 01-.515.222c-.59 0-1.12-.75-.58-1.278 1.176-1.151 2.475-2.163 3.79-3.157l-.03-.045c-4.277-8.308-8.964-16.377-13.047-24.793-.142-.294.108-.575.348-.575.104 0 .206.052.275.18 4.353 8.151 8.868 16.37 14.023 24.047.92-.7 1.83-1.412 2.692-2.18 2.932-2.618 5.78-5.333 8.62-8.048-.382-.113-.653-.544-.29-.904 1.759-1.749 1.17-4.695.897-6.91-.294-2.393-.377-4.781-.442-7.193-.173-6.433-.45-12.67-2.538-18.823-.03-.087.054-.164.131-.164.033 0 .067.017.088.056 3.148 6.186 2.93 13.697 3.3 20.458.17 3.106 1.955 8.645.456 11.935 5.461-5.285 10.713-10.648 16.816-15.24.379-.283.755-.568 1.132-.853.017-.194.136-.365.375-.365.015 0 .03 0 .046.002a.55.55 0 01.056.004c5.74-4.318 11.48-8.647 17.155-13.044 3.688-2.855 7.246-5.824 10.749-8.898 2.873-2.522 5.859-5.387 9.357-7.006a.482.482 0 01.2-.046c.35 0 .564.442.22.71-6.233 4.884-11.985 10.263-18.114 15.283-5.385 4.408-10.847 8.723-16.16 13.218 9.553.47 19.708.083 27.408 6.561.23.194.027.502-.213.502a.29.29 0 01-.187-.071c-7.89-6.49-18.338-5.705-28.004-6.142-.758.647-1.524 1.284-2.273 1.942-5.947 5.208-11.673 10.617-17.485 15.969 8.773 1.965 17.318 4.814 25.955 7.287.194.056.15.344-.027.344a.19.19 0 01-.048-.006c-9.012-2.35-17.884-5.162-27.118-6.518a167.238 167.238 0 01-8.177 6.97c-1.27 1.01-2.647 1.92-4.013 2.842 2.853 3.55 5.747 6.6 9.462 9.35a77.756 77.756 0 015.645 4.613m16.145-45.088c-.279 0-.548-.198-.46-.543 2.45-9.645 1.568-19.603 2.355-29.43.01-.134.129-.2.245-.2.121 0 .244.071.242.214-.102 9.858.537 19.917-1.874 29.572a.504.504 0 01-.508.387"
        fill="#99AAB5"
      />
      <path
        d="M120 160.658c.94-1.87 2.452-2.568 3.814-4.08 2.199-2.444 2.89-5.099 4-8.164 1.588-4.387 2.795-8.549 2.86-13.29.13-9.416-3.64-19.814-9.281-27.29-7.261-9.617-16.53-13.09-27.121-16.82.376 6.805 2.254 14.12 4.241 20.604 1.357 4.423 6.02 17.722.503 20.818-7.845 4.401-6.38-21.495-6.682-24.168-.707-6.236-3.103-11.931-3.894-17.929-3.44.15-7.032-.636-10.651-1.422-4.776-1.038-9.6-2.076-14.186-.961 4.13 4.504 20.566 27.874 10.395 32.99-7.015 3.527-8.825-13.055-9.778-17.338-1.177-5.285-2.148-11.307-5.08-15.95-2.281 1.435-5.93 1.207-9.554.98-2.796-.176-5.577-.352-7.704.236 2.577 3.944 14.608 26.04 6.52 28.15-6.773 1.765-9.407-19.26-13.445-22.959-2.495-2.286-5.33-2.136-8.63-1.566-8.964 1.55-17.435 4.215-26.205 6.374 3.81 5.19 5.69 12.26 7.216 18.417 1.145 4.616 2.706 11.93 7.459 14.494 3.973 2.145 8.337.591 12.763-.96 4.403-1.545 8.868-3.09 13.074-.997-.948 4.329-9.151 6.4-12.435 8.18-3.75 2.035-6.704 4.15-11.038 4.165 2.814 5.412 5.867 11.95 9.605 16.693 4.552-4.777 11.699-7.159 17.62-9.726 4.056-1.757 13.378-6.448 17.488-2.118 5.209 5.491-8.887 10.103-12.4 10.855-6.724 1.439-12.669 3.109-18.763 6.365 3.518 5.672 7.72 12.391 12.39 17.222 4.674-7.583 14.862-12.589 23.059-15.964 3.237-1.333 13.99-6.788 17.143-2.988 2.074 2.5-1.164 7.004-2.644 8.816-5.034 6.167-12.604 8.545-19.848 10.881-4.433 1.429-9.478 3.117-12.988 6.253 16.948 14.77 48.927 16.887 65.488-.243 4.146-4.29 12.284-14.472 10.357-20.972-.82-2.765-3.584-4.775-3.667-6.588"
        fill="#E2E6E8"
      />
      <path
        d="M88.463 180.424c-.123 0-.256-.13-.164-.281.384-.635.776-1.237 1.164-1.851a14.57 14.57 0 00-.849 2.015c-.027.082-.088.117-.151.117m1-2.132c.715-1.41 1.647-2.753 2.684-4.044a98.41 98.41 0 00-2.684 4.044m2.684-4.044a89.093 89.093 0 015.088-6.586c3.419-4.016 5.85-8.154 8.105-12.839-1.757-.672-3.522-1.328-5.196-2.123a190 190 0 01-10.89-5.637c-9.915 3.892-19.054 9.308-28.466 14.244a.197.197 0 01-.09.025c-.174 0-.268-.272-.08-.379 8.962-4.98 17.728-10.352 26.974-14.802-7.844-4.45-15.606-8.985-23.56-13.24-1.004-.536-2.02-1.048-3.03-1.57-10.632 2.98-22.452 4.803-29.546 14.056a.326.326 0 01-.265.136c-.244 0-.48-.263-.292-.513 6.889-9.188 18.278-11.38 28.777-14.369-7.068-3.625-14.253-7.013-21.378-10.523-8.11-4-15.892-8.495-24.084-12.314-.492-.231-.3-.86.175-.86.039 0 .079.004.12.012 4.309.898 8.367 3.311 12.213 5.374 4.687 2.514 9.409 4.894 14.245 7.117 7.447 3.424 14.945 6.753 22.44 10.074.021-.006.043-.012.063-.02a.592.592 0 01.161-.028c.204 0 .34.144.4.325.492.217.985.437 1.478.656 7.971 3.527 15.194 8.137 22.63 12.601-2.516-3.27-1.962-9.89-2.574-13.386-1.333-7.613-3.516-15.906-1.612-23.598.016-.062.063-.087.114-.087.079 0 .165.06.156.152-.734 7.38.568 14.386 2.038 21.584.55 2.696 1.075 5.372 1.366 8.108.269 2.534.374 5.961 2.782 7.45.496.308.306.858-.09 1.08 3.858 2.287 7.725 4.57 11.66 6.724 1.155.631 2.35 1.19 3.554 1.728 3.752-9.865 6.648-20.168 9.385-30.354.054-.198.194-.28.344-.28.25 0 .524.234.45.557-2.366 10.411-5.495 20.59-8.106 30.933-.005.021-.015.038-.021.058 1.72.765 3.424 1.554 5.028 2.53.802.49.266 1.593-.492 1.593a.867.867 0 01-.453-.135c-1.674-1.018-3.501-1.795-5.357-2.522-2.076 4.113-4.206 7.902-7.273 11.446-2.076 2.399-4.714 4.89-6.89 7.602M62.41 128.307a.563.563 0 01-.516-.317c-5.173-10.11-7.057-21.46-9.716-32.394-.046-.188.127-.31.291-.31.106 0 .209.051.246.168 3.412 10.723 5.002 22.023 10.217 32.111.215.419-.15.742-.522.742"
        fill="#99AAB5"
      />
      <path
        d="M350.668 3.49c14.5 7.135-6.867 19.352-24.045 16.176 0 0 9.539-23.3 24.045-16.176m-41.007 26.114c12.652-8.517 33.676-4.902 28.272 5.291-5.407 10.205-28.272-5.291-28.272-5.291m-20.313 14.179c10.144-8.052 34.522-11.033 29.123 1.75-5.407 12.77-29.123-1.75-29.123-1.75m26.487-42.385c14.786-4.18 10.107 16.61-6.174 28.206 0 0-8.603-24.023 6.174-28.206"
        fill="#E2E6E8"
      />
      <path
        d="M298.37 59.707c-1.878 18.02-27.914-.357-27.914-.357 13.08-9.702 29.024-10.385 27.914.357m-4.038-45.383c10.961-3.383 9.335 16.867-4.984 29.459 0 0-11.021-24.53 4.984-29.459M267.5 32.662c12.287-7.661 13.155 9.272 2.957 26.688 0 0-15.258-19.03-2.957-26.688"
        fill="#6C8B8E"
      />
      <path
        d="M244.472 83.76a262.342 262.342 0 0137.652-37.43c13.905-11.022 28.874-20.899 45.272-27.836 1.299-.517 1.932.917.72 1.55-15.35 8.447-29.465 18.963-43.126 29.865-13.566 11.062-26.594 22.785-39.208 35.052-1.072 1.016-2.211-.129-1.31-1.2M24.61 13.457C9.087 19.36 29.766 33.917 47.646 32.324c0 0-7.513-24.755-23.037-18.866m39.43 30.666c-12.122-9.931-33.977-8.26-29.432 2.688 4.548 10.96 29.432-2.688 29.432-2.688M83.45 60.586c-9.6-9.214-34.25-14.613-29.96-1.016 4.3 13.584 29.96 1.016 29.96 1.016m-23.008-45.91c-14.723-5.7-11.942 16.017 3.597 29.448 0 0 11.115-23.744-3.597-29.448"
        fill="#E2E6E8"
      />
      <path
        d="M72.687 76.006c.184 18.613 28.588 2.326 28.588 2.326-12.445-11.185-28.687-13.42-28.588-2.326"
        fill="#6C8B8E"
      />
      <path
        d="M81.192 29.972c-10.887-4.518-11.176 16.354 2.258 30.614 0 0 13.64-24.03-2.258-30.614"
        fill="#E2E6E8"
      />
      <path
        d="M106.873 51.317c-11.832-9.022-14.352 8.216-5.598 27.015 0 0 17.442-17.997 5.598-27.015"
        fill="#6C8B8E"
      />
      <path
        d="M125.501 105.807a269.406 269.406 0 00-34.908-41.918c-13.16-12.616-27.52-24.162-43.625-32.839-1.28-.654-2.066.752-.886 1.516 14.886 10.12 28.312 22.239 41.235 34.707 12.812 12.624 25.008 25.872 36.73 39.635 1 1.143 2.275.081 1.454-1.101"
        fill="#E2E6E8"
      />
      <path
        d="M302.016 20.463l20.199 140.11c.668 4.624-2.55 8.926-7.175 9.592L89.377 202.699c-4.625.667-8.9-2.555-9.566-7.18L59.61 55.41c-.67-4.651 2.525-8.922 7.15-9.59l225.662-32.532a8.46 8.46 0 019.593 7.176"
        fill="#666"
      />
      <path
        d="M321.195 153.485l1.02 7.087c.668 4.625-2.55 8.927-7.175 9.593L89.377 202.699c-4.625.667-8.9-2.555-9.566-7.18l-1.022-7.087 242.406-34.947"
        fill="#4C4C4C"
      />
      <path fill="#FFF" d="M310.877 145.31l-224.5 32.366L68.7 55.056l224.5-32.365 17.677 122.619" />
      <path d="M259.982 34.857a2.67 2.67 0 11-5.283.763 2.67 2.67 0 015.283-.763" fill="#3EA769" />
      <path d="M271.788 33.155a2.67 2.67 0 11-5.283.762 2.67 2.67 0 015.283-.762" fill="#FFF02C" />
      <path d="M283.597 31.453a2.67 2.67 0 11-5.284.762 2.67 2.67 0 015.284-.762" fill="#D85145" />
      <path
        d="M351.162 88.09s8.29-.625 10.638 2.269c2.346 2.894 2.19 8.056.235 12.984-1.955 4.927-3.598 11.34-1.643 18.068 1.955 6.725 4.119 14.365 3.806 16.137-.312 1.774-13.14.73-13.14.73"
        fill="#FFD5AA"
      />
      <path
        d="M358.864 96.914c-.365-1.048.014-2.21.81-2.982 3.573-3.477 15.741-14.93 18.502-12.485 3.244 2.875-8.156 12.395-11.808 14.734-3.042 1.949-6.624 3.269-7.504.733"
        fill="#D2B089"
      />
      <path
        d="M342.893 86.75l17.622-2.712-1.882-16.343s-8.208-1.582-8.435-1.883c-.225-.301-7.909 5.799-7.909 5.799l.604 15.138"
        fill="#2A2A2A"
      />
      <path
        d="M343.612 89.916c-.848-.587-1.978-.536-2.868-.018-3.993 2.326-17.253 10.345-15.756 13.418 1.762 3.615 13.102-4.2 16.1-6.88 2.497-2.231 4.566-5.1 2.524-6.52"
        fill="#D2B089"
      />
      <path fill="#FFD5AA" d="M348.217 90.046h6.126v-6.414h-6.126z" />
      <path
        d="M359.534 76.496c0 5.102-3.712 9.237-8.291 9.237-4.581 0-8.294-4.135-8.294-9.237 0-5.101 3.713-9.237 8.294-9.237 4.579 0 8.291 4.136 8.291 9.237m-8.268 61.782s-12.827 1.044-13.139-.73c-.312-1.772 1.851-9.412 3.806-16.137 1.956-6.728.313-13.141-1.642-18.068-1.956-4.928-2.112-10.09.235-12.984 2.346-2.894 10.636-2.268 10.636-2.268m-22.313 15.665c.812-.742 1.085-1.908.868-2.986-1.01-5.045-4.747-22.995-6.923-23.46-2.53-.542 1.686 25.725 2.944 26.281.941.419 1.999 1.177 3.11.165"
        fill="#FFD5AA"
      />
      <path
        d="M323.645 78.245c-.372 2.23-4.15 1.151-5.424.735-2.076-.678-3.99-3.04-3.735-3.868.254-.828 4.887.995 5.103.855.217-.14-1.95-1.244-1.632-1.997.319-.753 3.377.338 4.42 1.246 1.046.908 1.502 1.63 1.268 3.029m53.725 6.461c-.891.246-1.843-.084-2.51-.72-3.13-2.976-14.206-13.671-13.562-15.424.75-2.04 17.515 12.72 17.349 13.862-.125.856-.06 1.947-1.277 2.282"
        fill="#FFD5AA"
      />
      <path
        d="M362.945 69.42c1.393-1.289-1.104-3.442-1.982-4.145-1.433-1.145-3.973-1.4-4.438-.84-.464.56 2.95 2.972 2.953 3.189 0 .216-1.774-.798-2.155-.227-.381.571 1.793 2.215 2.91 2.53 1.121.314 1.837.303 2.712-.506"
        fill="#FFD5AA"
      />
      <path
        d="M359.268 87.823s4.932-4.692 3.39-13.104c-1.281-6.968-3.163-8.51-4.746-8.435 0 0-2.786-4.97-10.62-2.712-7.832 2.26-7.53 10.92-7.228 15.214.3 4.293 2.86 10.92 2.86 10.92l1.281-4.594s-.602-9.414.452-10.77c1.053-1.355 10.167-2.862 12.05-4.97l2.56 18.451"
        fill="#4C4C4C"
      />
      <path
        d="M338.908 137.758s-2.659 14.86-3.284 22.056c-.626 7.194 0 10.48-.522 12.462-.52 1.98-1.079 13.662-1.1 28.339l2.355.477s2.097-13.757 3.036-20.093c.94-6.334 3.113-12.425 4.131-19.073 1.016-6.649 5.006-20.805 7.038-20.962 2.034-.156 1.643 20.883 1.643 25.368 0 4.485.254 19.443.672 34.254l2.43.079s3.326-13.018 3.56-18.727c.236-5.712-.014-7.654-.014-9.533 0-1.876 6.805-22.341 4.094-34.023-2.711-11.679-24.04-.624-24.04-.624"
        fill="#FCC083"
      />
      <path
        d="M363.34 128.958c-.441-1.54-.562-2.388-.562-2.388l-22.723.081c-.329.758-.63 1.58-.922 2.451-3.473 10.372-4.548 27.235-4.548 27.235l-.957 41.821 3.53-.05 5.754-30.67a154.204 154.204 0 016.19-21.747l.956-2.617.916 13.403 1.647 42.764 3.288.077c1.5-5.685 3.935-20.245 4.252-23.614.314-3.367 2.846-19.267 2.846-19.267s1.15-5.053 1.464-13.363c.214-5.52-.258-11.098-1.13-14.116"
        fill="#4C4C4C"
      />
      <path
        d="M347.068 87.918s-3.785-.307-8.7 2.712c-4.911 3.019-8.339 5.374-8.85 5.374-.513 0-2.687-8.444-2.687-8.444l-4.247 1.062s.587 15.007 3.248 15.979c2.66.972 12.076-4.606 12.435-4.248.358.358 3.327 7.317 3.019 14.482-.306 7.163-3.582 14.532-3.07 15.658.51 1.127 24.05 1.127 25.38 0 1.331-1.126-2.148-12.077-2.148-16.476 0-4.402 1.996-12.487 2.507-13.818.512-1.33 15.914-13.304 15.608-17.193-.308-3.89-9.876-9.467-9.876-9.467l-3.121 2.712s5.73 5.783 5.526 6.192c-.204.41-8.956 7.164-10.184 6.703-1.229-.46-4.86-1.126-6.19-1.33 0 0-1.331 3.634-4.504 3.634-3.174 0-4.146-3.532-4.146-3.532"
        fill="#E23F88"
      />
      <path
        d="M352.742 200.49s.117 1.593-.643 2.896c-1.066 1.833-1.07 1.462-.82 2.13.247.669 4.59.103 4.59.103l-.389-5.17s-2.798-.103-2.738.041m-18.904-.487s-.835 1.135-2.75 2.621c-1.916 1.485-2.424 1.173-2.464 1.72-.04.548 8.016 0 8.016 0v-4.497s-2.802.04-2.802.156"
        fill="#4C4C4C"
      />
      <path fill="#34555B" d="M267 181.598h4.418v-3.444h-4.419z" />
      <path
        fill="#34555B"
        d="M267.934 204.525h2.364v-49.908h-2.364zm-43.332-22.927h4.418v-3.444h-4.418z"
      />
      <path fill="#34555B" d="M225.537 204.525h2.363v-49.908h-2.363z" />
      <path
        d="M279.788 167.856v10.12c0 .764-.62 1.384-1.386 1.384h-60.786c-.764 0-1.385-.62-1.385-1.385v-10.12c0-.763.62-1.384 1.385-1.384h60.786c.766 0 1.386.62 1.386 1.385"
        fill="#FFF02C"
      />
      <path
        d="M230.168 166.471l-9.364 12.889h-3.188c-.764 0-1.385-.62-1.385-1.385v-.851l7.738-10.653h6.199m12.657 0l-9.363 12.889h-6.2l9.365-12.889h6.198m12.654 0l-9.364 12.889h-6.194l9.363-12.889h6.195m12.657 0l-9.363 12.889h-6.195l9.364-12.889h6.194m11.652 1.385l-8.358 11.504h-6.194l9.363-12.889h3.803c.766 0 1.386.62 1.386 1.385"
        fill="#4C4C4C"
      />
      <path
        d="M279.788 148.977v10.12c0 .765-.62 1.386-1.386 1.386h-60.786c-.764 0-1.385-.62-1.385-1.387v-10.12c0-.764.62-1.384 1.385-1.384h60.786c.766 0 1.386.62 1.386 1.385"
        fill="#FFF02C"
      />
      <path
        d="M230.168 147.592l-9.364 12.89h-3.188c-.764 0-1.385-.62-1.385-1.386v-.852l7.738-10.652h6.199m12.657 0l-9.363 12.89h-6.2l9.365-12.89h6.198m12.654 0l-9.364 12.89h-6.194l9.363-12.89h6.195m12.657 0l-9.363 12.89h-6.195l9.364-12.89h6.194m11.652 1.385l-8.358 11.506h-6.194l9.363-12.891h3.803c.766 0 1.386.62 1.386 1.385"
        fill="#4C4C4C"
      />
      <path
        d="M154.349 201.523h-27.536l2.562-7.021 9.887-27.069c.402-1.103 2.235-1.103 2.637 0l2.997 8.21 9.453 25.88"
        fill="#FFF02C"
      />
      <path fill="#FFF02C" d="M159.855 204.69h-38.55l-.021-3.442h38.594l-.023 3.442" />
      <path
        fill="#4C4C4C"
        d="M147.26 182.113H133.9l2.366-6.47h8.63l2.364 6.47m4.526 12.389h-22.41l2.364-6.47h17.681l2.365 6.47"
      />
      <text
        transform="rotate(-8 191.35 99.99)"
        fontFamily="Montserrat-Bold, Montserrat"
        fontSize={26}
        fontWeight="bold"
        fill="#4C4C4C"
      >
        <tspan x={109.749} y={85.99}>
          {'SOMETHING '}
        </tspan>
        <tspan x={93.551} y={125.99}>
          {'WENT WRONG'}
        </tspan>
      </text>
    </g>
  </svg>
);

export default SvgImageError;
