import React from 'react';

const SvgMobileSetup203 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M31 0c17.12 0 31 13.88 31 31 0 17.12-13.88 31-31 31C14.05 62 .278 48.397.004 31.513L0 31h6c0 13.807 11.193 25 25 25s25-11.193 25-25C56 17.331 45.03 6.224 31.413 6.003L31 6V0z"
      fill="#FFF02C"
    />
  </svg>
);

export default SvgMobileSetup203;
