import React from 'react';

const SvgIconDocumentsDisabled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32" {...props}>
    <g fill="#d9d9d9" fillRule="evenodd">
      <path d="M33.49 30.681H7.59c-1.57 0-2.847-1.256-2.847-2.795V8.607c0-1.544 1.277-2.795 2.847-2.795h11.29c1.415 0 1.837.88 2.542 2.085l2.013 3.588h14.45c1.57 0 .278 1.256.278 2.795L38 27.886c-.01 1.539-2.939 2.795-4.51 2.795z" />
      <path d="M35.245 31.114h-31.5a3.469 3.469 0 01-3.464-3.465V3.794A3.469 3.469 0 013.746.33h13.729c1.72 0 3.315.926 4.175 2.419l2.636 4.602H35.25a3.469 3.469 0 013.465 3.465v16.838a3.476 3.476 0 01-3.47 3.46zM3.745 2.399c-.771 0-1.394.628-1.394 1.395v23.855c0 .772.628 1.395 1.395 1.395h31.5c.771 0 1.394-.628 1.394-1.395V10.811c0-.773-.628-1.396-1.395-1.396H23.086L19.853 3.78a2.755 2.755 0 00-2.378-1.38H3.745z" />
    </g>
  </svg>
);

export default SvgIconDocumentsDisabled;
