import React from 'react';

const SvgFacilityShower = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round">
      <path d="M5.595 19.307a1.186 1.186 0 012.373 0M5.595 7.049v12.258a1.186 1.186 0 01-2.372 0V9.422m4.745-5.14a1.186 1.186 0 012.372 0v15.025a1.186 1.186 0 01-2.372 0V9.422m-2.373-5.14c0-.655-.736-1.176-1.186-1.187-.45-.01-1.196.214-1.208.823m2.394.364a1.186 1.186 0 002.373 0m-2.373-.36h2.373m2.372 0h1.38M12.625 12.086H20a1 1 0 110 2h-7.353m7.353-2V5a1 1 0 00-1.707-.707l-.89.89h0m-.196.196a2 2 0 00-2.828 0h0l2.828 2.828a2 2 0 000-2.828zM20 14.086v.5c0 2.761-2.388 5-5.333 5h-2.04m4.873 1v-1.5" />
    </g>
  </svg>
);

export default SvgFacilityShower;
