import React from 'react';

const SvgProfilePassport = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.634.52a2.56 2.56 0 00-2.02-.198h-.002L5.977 5.89l-.119.045-.044.025-.15.035A1.972 1.972 0 004.2 7.88v25.95l.018.236A1.99 1.99 0 006.231 35.8H29.15l.234-.01a1.989 1.989 0 001.797-1.95V7.89l-.018-.238a2 2 0 00-.586-1.173l-.176-.151-.19-.129a1.998 1.998 0 00-1.063-.282h-2.277V2.6l-.013-.265A2.327 2.327 0 0026.07.824l-.207-.161-.229-.143zm-1.242 1.725l.093.015-.03.197.09-.177.076.039.065.055.009.01c.052.056.08.131.078.208v.004l-.2-.006h.2v3.327H14.005l-.06-.391L24.28 2.264l.112-.019zM6.296 7.965h22.588s.186 0 .196.035c.01.035 0 .165 0 .165v25.588l-22.784-.002V7.965zm11.165 4.13c-3.748 0-6.795 2.901-6.795 6.46 0 3.558 3.046 6.459 6.795 6.459 3.725 0 6.767-2.904 6.767-6.459 0-3.554-3.042-6.46-6.767-6.46zm-.304 1.776a.595.595 0 01.304-.098c.108 0 .21.043.297.098.09.057.18.137.27.234.178.195.364.475.539.83l.003.008.195.486c.238.659.392 1.345.459 2.043v.246h-3.583l.095-.77a8.306 8.306 0 01.597-2.022l.003-.007a3.27 3.27 0 01.541-.814c.092-.097.187-.177.28-.234zm-2.498.598l-.261.785a10.008 10.008 0 00-.426 2.177l-.016.287H12.46l.09-.36a4.77 4.77 0 011.38-2.25l.004-.002.725-.637zm6.13.482l.19.162a4.794 4.794 0 011.365 2.247l.002.008.083.35h-1.491l-.016-.287a10.02 10.02 0 00-.415-2.153l-.037-.112.19-.063.13-.152zm-8.327 4.439h1.305v.2l.2-.011.005.1c.06.732.199 1.454.414 2.155l.273.788-.729-.62a4.769 4.769 0 01-1.38-2.25l-.002-.006-.086-.356zm3.205 0h3.586l-.097.772a8.228 8.228 0 01-.595 2.023l-.003.007c-.17.348-.355.623-.532.815a1.36 1.36 0 01-.268.232.568.568 0 01-.297.097.598.598 0 01-.305-.097 1.395 1.395 0 01-.281-.235 3.36 3.36 0 01-.549-.83l-.003-.006-.194-.486a8.426 8.426 0 01-.46-2.045l-.002-.01v-.237zm5.27 0h1.49l-.083.358a4.796 4.796 0 01-1.365 2.25l-.006.005-.744.637.278-.808c.215-.7.354-1.422.415-2.152l.015-.29zm-8.266 7.973c-.201.04-.546.165-.67.328a.836.836 0 00.662 1.342h9.244l.163-.013a.836.836 0 00-.156-1.657h-9.243zm8.604 2.934a.838.838 0 00-.612-.192l-.017-.178v.18h-6.89a.838.838 0 00.157 1.668h6.91a.839.839 0 00.452-1.478z"
    />
  </svg>
);

export default SvgProfilePassport;
