import React from 'react';

const SvgAutomaticPaymentsOnetime = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.803 18.013l-.601-1.328h-3.255l-1.856-.238-2.527 1.29-.981 2.414c2.91 4.414 4.456 7.925 4.637 10.534.727 0 1.44 1.224 1.681 1.224.162 0 1.483-.196 3.965-.586v-2.8c0-.351-.658-7.583-.658-7.824 0-.16-.135-1.056-.405-2.686zm-37.45-.185l.602-1.328h3.254l1.857-.239 2.908.239.526 1.995c-2.55 4.671-3.918 8.488-4.102 11.452-.727 0-1.9 1.777-2.143 1.777-.161 0-1.483-.196-3.964-.587v-2.799c0-.352.657-7.584.657-7.824 0-.16.135-1.057.406-2.686z"
      fill="#3F2D51"
    />
    <path
      d="M19.186 14.302s-20.368 26.73 6.148 27.282c0 0 26.016 0 .648-28.548-4.721-.425-6.796 1.266-6.796 1.266z"
      fill="#FFC543"
    />
    <path
      d="M25.742 27.276c2.842 1.004 3.992 2.319 3.992 4.473 0 2.127-1.507 3.943-4.256 4.422v2.437h-2.344v-2.27c-1.602-.07-3.155-.501-4.065-1.027l.718-2.797c1.005.55 2.415 1.051 3.97 1.051 1.363 0 2.295-.526 2.295-1.482 0-.908-.765-1.483-2.534-2.08-2.559-.86-4.304-2.056-4.304-4.375 0-2.105 1.483-3.753 4.04-4.256V19.1h2.342v2.105c1.603.072 2.68.405 3.468.79l-.694 2.702c-.62-.263-1.72-.814-3.443-.814-1.554 0-2.056.67-2.056 1.34 0 .788.837 1.29 2.87 2.055z"
      fill="#3F2D51"
    />
    <path
      d="M25.565 11.765s1.767-5.457-.308-5.687c-1.747-.194-4.42-.155-7.148 1.723a48.695 48.695 0 001.806 2.158l-2.318-1.782c-.343.265-.684.559-1.024.887l2.69 3.904s3.121-1.664 6.302-1.202zM10.398 29.947H5.016V28.31c1.072-.444 2.265-1.598 2.265-2.972 0-.27-.02-.54-.06-.807H5.239v-2.083h1.618a8.692 8.692 0 01-.163-1.637c0-2.549 1.86-4.225 4.569-4.225 1.113 0 1.94.24 2.385.484l-.506 2.325c-.383-.222-.95-.364-1.696-.364-1.436 0-1.88.93-1.88 1.901 0 .526.06 1.01.18 1.516h2.771v.066c.922-2.121 1.998-4.073 2.957-5.65-1.16-1.628-2.589-3.41-4.327-5.366-3.688-.332-5.31.989-5.31.989S-10.01 33.287 10.52 33.8c-.238-1.235-.26-2.533-.122-3.853z"
      fill="#FFC543"
    />
    <path
      d="M9.827 25.965a3.477 3.477 0 01-.99 1.436v.04h1.997c.238-.986.531-1.958.879-2.91H10.01c.02.505-.02.969-.183 1.434zm.995-15.461s1.38-4.264-.24-4.444c-1.366-.152-3.455-.12-5.586 1.348a38.79 38.79 0 001.412 1.685L4.596 7.702a9.741 9.741 0 00-.8.692l2.102 3.05s2.438-1.3 4.924-.94zm28.498.993c-3.688-.332-5.31.989-5.31.989s-1.374 1.805-2.79 4.364a62.607 62.607 0 012.363 3.301c.208-.371.447-.715.715-1.025 1.153-1.293 2.769-2.062 4.67-2.062 1.232 0 2.304.283 3.031.607l-.566 2.304c-.524-.221-1.355-.485-2.243-.485-.97 0-1.86.323-2.486 1.09-.284.325-.507.79-.648 1.315h5.034v1.434h-5.607c.104.159.455.764.55.951h5.057v1.434h-4.368c.137.311.27.619.391.92.155.387.291.768.419 1.145.523.264 1.129.383 1.758.383.93 0 1.88-.303 2.305-.526l.486 2.243c-.689.385-1.86.769-3.154.769-.23 0-.461-.012-.69-.035.17 1.125.186 2.183.055 3.174.172.007.346.012.522.016 0 0 20.327 0 .506-22.306zm-.326-.993s1.38-4.264-.24-4.444c-1.365-.152-3.453-.12-5.585 1.348.666.85 1.412 1.685 1.412 1.685l-1.812-1.391a9.742 9.742 0 00-.8.692l2.101 3.05s2.439-1.3 4.924-.94z"
      fill="#FFC543"
    />
  </svg>
);

export default SvgAutomaticPaymentsOnetime;
