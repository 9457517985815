import React from 'react';

const SvgIconTransferr = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M8 0a8 8 0 110 16A8 8 0 018 0zm2.78 8.506H5.222l-.103.007c-.6.083-.848.88-.36 1.302l1.44 1.243.087.064c.3.188.696.129.928-.156a.756.756 0 00-.02-.978h3.586l.097-.007a.734.734 0 00.623-.734.73.73 0 00-.72-.741zM9.388 4.902h-.115a.706.706 0 00-.487.263.757.757 0 00.021.978H5.221l-.097.007a.735.735 0 00-.623.734c0 .408.322.74.72.74h5.557l.104-.006c.599-.083.847-.88.36-1.302L9.8 5.073l-.092-.068a.706.706 0 00-.435-.102z"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgIconTransferr;
