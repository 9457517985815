import React from 'react';

const SvgIconBuilding = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.89 3.456l-.17.013-.168.04-.166.064a1.158 1.158 0 00-.671 1.05v25.025l-4.87.002-.169.013-.177.04a1.168 1.168 0 00.34 2.281h6.036l.172-.013.169-.04.168-.064a1.16 1.16 0 00.667-1.05L10.049 5.79h15.799l.002 25.021.012.17.042.176a1.168 1.168 0 001.114.828h6.037l.172-.013.176-.04a1.168 1.168 0 00.827-1.106l-.012-.171-.04-.17-.065-.167a1.163 1.163 0 00-1.049-.667l-4.88-.002.002-25.018-.013-.17-.042-.176a1.168 1.168 0 00-1.114-.828H8.89zm4.023 12.089c-.645 0-1.166.523-1.166 1.166v4.026l.01.169.042.175a1.168 1.168 0 002.282-.339V16.72l-.013-.17-.041-.178a1.168 1.168 0 00-1.114-.827zm8.06 14.103h-6.037l-.17.013-.176.041a1.168 1.168 0 00.34 2.28h6.036l.17-.013.178-.04a1.168 1.168 0 00-.341-2.281z"
    />
  </svg>
);

export default SvgIconBuilding;
