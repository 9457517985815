import React from 'react';

const SvgProfileHead = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={20} cy={20} r={20} fill="#ECEAEE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 20.04A4.52 4.52 0 1020 11a4.52 4.52 0 000 9.04zm0 1.05c2.04 0 3.17.27 3.96.55 1.94.68 4.04 2.1 4.04 5.18 0 .78-.56 1.36-1.54 1.68-1.19.4-4.22.5-6.46.5s-5.27-.1-6.46-.5c-.98-.32-1.54-.9-1.54-1.68 0-3.09 2.1-4.5 4.04-5.18.79-.28 1.92-.55 3.96-.55z"
      fill="#8C8197"
    />
  </svg>
);

export default SvgProfileHead;
