import React from 'react';

const SvgIconCaution = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M24 0c13.255 0 24 10.745 24 24S37.255 48 24 48 0 37.255 0 24 10.745 0 24 0z"
      fill="#FFC543"
    />
    <path
      d="M24 15.779a3.634 3.634 0 100-7.269 3.634 3.634 0 000 7.269zm-.105 23.711a3.13 3.13 0 003.13-3.13V21.75a3.13 3.13 0 00-6.26 0v14.61a3.13 3.13 0 003.13 3.13z"
      fill="#3F2D51"
      stroke="#FFC543"
      strokeWidth={1.6}
    />
  </svg>
);

export default SvgIconCaution;
