import React from 'react';

const SvgIconCheckin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.063 45.36c-1.196 1.111-2.817 1.736-4.507 1.736h-25.49c-3.521 0-6.375-2.655-6.375-5.93V24.154H44.93v17.014c0 1.572-.672 3.08-1.867 4.192z"
      fill="#FFC543"
    />
    <path
      d="M32.663 0c.796 0 1.44.645 1.44 1.44v1.798l7.534.001a5.398 5.398 0 015.392 5.129l.007.27v33.964A5.398 5.398 0 0141.637 48H6.398A5.398 5.398 0 011 42.602V8.637A5.398 5.398 0 016.398 3.24l8.496-.001V1.44a1.44 1.44 0 112.882 0v1.797H31.22l.001-1.797a1.44 1.44 0 011.441-1.441zm11.673 21.331H3.698l.001 21.27a2.7 2.7 0 002.498 2.692l.201.008h35.24a2.7 2.7 0 002.699-2.7v-21.27zM41.637 5.938l-7.535-.001.002 3.187a1.44 1.44 0 11-2.882 0V5.937H17.774v3.187a1.44 1.44 0 11-2.88 0l-.001-3.187-8.496.001a2.7 2.7 0 00-2.691 2.498l-.008.201-.001 9.623h40.638V8.637a2.7 2.7 0 00-2.497-2.691l-.202-.008z"
      fill="#3F2D51"
    />
    <path
      d="M25.085 37.035v-.269c0-.461.096-.864.288-1.249a2.98 2.98 0 01.768-.96c.922-.807 1.48-1.325 1.652-1.518.461-.614.71-1.402.71-2.362 0-1.172-.383-2.094-1.152-2.766-.768-.692-1.786-1.018-3.035-1.018-1.421 0-2.535.403-3.361 1.21-.846.806-1.249 1.92-1.249 3.342h2.19c0-.807.154-1.44.48-1.882.365-.538.96-.788 1.806-.788.653 0 1.172.173 1.537.538.345.365.537.864.537 1.498 0 .48-.172.942-.518 1.364l-.23.269c-1.25 1.114-1.999 1.92-2.248 2.44-.27.518-.384 1.152-.384 1.882v.269h2.209zm-1.114 3.842a1.48 1.48 0 001.056-.404c.288-.268.442-.633.442-1.056 0-.423-.154-.769-.423-1.037-.288-.27-.653-.404-1.075-.404a1.48 1.48 0 00-1.057.404c-.288.268-.423.614-.423 1.037 0 .422.135.768.423 1.037.288.27.634.423 1.056.423z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgIconCheckin;
