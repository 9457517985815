import React from 'react';

const SvgFacilityChairs = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round" fill="none" fillRule="evenodd">
      <path d="M3.573 12.458c.176.47.416.706.718.709.302.002 1.772.02 4.41.055M2.207 4.787l1.518 8.617-.467 5.077m4.563-5.141l.881 5.141M20.427 12.458c-.176.47-.416.706-.718.709-.302.002-1.772.02-4.41.055m6.494-8.435l-1.518 8.617.467 5.077m-4.563-5.141l-.881 5.141M12 9.024v9.617m-1.75 0h3.418M7 9h9.807" />
    </g>
  </svg>
);

export default SvgFacilityChairs;
