import React from 'react';

const SvgFilePdf = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.425 3.375A2.925 2.925 0 0128.35 6.3v7.2H26.1V6.3a.675.675 0 00-.675-.675h-11.61V10.8c0 1.485-1.17 2.7-2.628 2.79H5.85V29.7a.68.68 0 00.675.675h18.9a.68.68 0 00.675-.675V27h2.25v2.7a2.925 2.925 0 01-2.925 2.925h-18.9A2.925 2.925 0 013.6 29.7V12.6c0-.297.117-.585.333-.792l8.1-8.1c.207-.216.495-.333.792-.333h12.6zM12.402 16.2c.612 0 1.08.045 1.422.144A2.16 2.16 0 0115.3 17.64c.126.315.19.666.19 1.062 0 .837-.27 1.485-.793 1.926-.522.441-1.287.648-2.286.648h-1.25v2.016c0 .351-.09.63-.27.828a.901.901 0 01-.694.306.926.926 0 01-.702-.306c-.18-.198-.26-.468-.26-.819v-5.958c0-.396.09-.684.296-.873.198-.18.486-.27.873-.27h2.007-.009zm17.73 0c.288 0 .522.072.675.216a.722.722 0 01.243.567.722.722 0 01-.243.567c-.153.153-.387.216-.675.216h-2.907v1.62h2.403c.27 0 .495.063.657.207a.746.746 0 01.234.567.721.721 0 01-.243.558c-.153.135-.378.207-.648.207h-2.403v2.367c0 .36-.09.648-.27.837a.901.901 0 01-.684.297.9.9 0 01-.702-.297c-.18-.198-.27-.477-.27-.837v-5.949c0-.252.036-.459.117-.63a.81.81 0 01.405-.396c.171-.081.378-.117.621-.117h3.69zm-10.035 0c.54 0 1.017.054 1.413.153.405.108.774.306 1.107.594.846.72 1.27 1.818 1.27 3.276.001.438-.04.875-.127 1.305a3.402 3.402 0 01-1.08 1.962 2.862 2.862 0 01-1.566.729 6.458 6.458 0 01-.99.072H18.09a1.44 1.44 0 01-.738-.153.783.783 0 01-.36-.468 2.709 2.709 0 01-.072-.657v-5.67c0-.387.09-.675.28-.864.188-.189.476-.279.863-.279h2.034zm-.396 1.602h-.855v4.887h1.152l.252-.009.198-.018c.282-.02.551-.124.774-.297.477-.405.72-1.116.72-2.151a3.15 3.15 0 00-.324-1.62 1.35 1.35 0 00-.747-.648 4.015 4.015 0 00-.945-.144h-.225zm-7.677-.063h-.873v1.998h.873c.36 0 .648-.036.873-.108a.9.9 0 00.477-.315c.108-.144.162-.333.162-.585 0-.297-.09-.531-.252-.702-.162-.171-.522-.27-1.07-.288h-.19zm-.189-10.656L7.308 11.61h3.717a.81.81 0 00.81-.711V7.083z"
    />
  </svg>
);

export default SvgFilePdf;
