import React from 'react';

const SvgBedGrey = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.473 4.871L36.261 30.36l-1.545 1.908L2.928 6.779l1.545-1.908zm3.198.011l22.21.002a3.194 3.194 0 013.23 3.147v8.278a8.399 8.399 0 014.072 6.676v.006l.017.507v3.275a1.838 1.838 0 01-.578 1.327l-.127.12-2.04-1.652.714.003v-3.073a6.371 6.371 0 00-5.597-6.301l-6.694-.003-3.264-2.643.002-1.642c-.022-.607.168-1.2.533-1.677l.007-.01.19-.22a2.614 2.614 0 011.581-.782l.012-.001.29-.016h5.803l.045.044a2.614 2.614 0 012.563 2.36l.002.012.01.287.002 2.419c.143.032.285.068.426.107V8.036a1.131 1.131 0 00-1.187-1.12h-.01L10.18 6.914 7.67 4.882zm6.625 5.364l1.428.002a2.614 2.614 0 012.648 2.36l.001.01.011.289-.003.646-4.085-3.308zm-9.487.485l2.032 1.645v3.079a7.62 7.62 0 01.505-.13l-.001-2.54 2.034 1.647v.687l.847-.002 2.566 2.079H8.428a6.372 6.372 0 00-5.584 5.912l-.013.393v3.07l21.536-.001 2.508 2.031-18.312.002v1.424a.971.971 0 01-1.017 1.034.982.982 0 01-1.014-1.032v-1.426H2.63A1.843 1.843 0 01.818 27.02l-.002-.013-.016-.228v-3.281a8.397 8.397 0 014.01-7.141V10.73zm17.42 1.503a.582.582 0 00-.572.517v2.368h6.964v-2.225a.582.582 0 00-.468-.605l-.204-.015-.06-.04h-5.66z"
    />
  </svg>
);

export default SvgBedGrey;
