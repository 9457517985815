import React from 'react';

const SvgFacilityHob = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#4C4C4C"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 15h18v6H3zm7-12h4l1 3H9zM5 6h14v3H5zm7 9v6"
      />
      <circle fill="#4C4C4C" cx={10} cy={17} r={1} />
      <circle fill="#4C4C4C" cx={14} cy={17} r={1} />
      <path stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round" d="M7.318 13.8h9.785" />
    </g>
  </svg>
);

export default SvgFacilityHob;
