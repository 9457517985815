import React from 'react';

const SvgIconBathroom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#EBEAED" cx={16} cy={16} r={16} />
      <path
        d="M22.192 15.91h-1.787c-.02-.022-6.94.043-6.947 0H11.71c-.596 0-1.112.528-1.112 1.13v6.478c0 .565.516 1.055 1.112 1.055h10.441c.635 0 1.112-.49 1.112-1.055v-6.477c.04-.603-.477-1.13-1.072-1.13zm-9.86-3.777c0-.066.083-.133.166-.133h8.042c.041 0 .125.067.125.133.003.406-.198.796-.558 1.084-.36.288-.849.45-1.359.45h-4.541c-1.042 0-1.876-.667-1.876-1.5v-.034z"
        fill="#FFC543"
      />
      <path
        d="M22.028 13.985c.686 0 1.281.644 1.235 1.38v7.919c0 .69-.549 1.289-1.28 1.289H9.947c-.686 0-1.281-.6-1.281-1.29v-7.917c0-.737.595-1.381 1.281-1.381h2.013c-.869-.461-1.464-1.428-1.464-2.486v-.092c0-.599.503-1.105 1.098-1.105h3.02V8.69c0-.37.32-.691.687-.691h1.372c.366 0 .687.322.687.69v.23h1.372c.275 0 .458.185.458.461s-.183.46-.458.46H17.36v.46h3.02a1.15 1.15 0 011.143 1.06c0 1.15-.64 2.163-1.555 2.624h2.059zM15.53 8.92v1.38h.916V8.92h-.916zm-4.118 2.486v.046c0 1.15.915 2.071 2.06 2.071h4.987a2.106 2.106 0 002.105-2.118c0-.091-.092-.184-.138-.184h-8.83c-.093 0-.184.093-.184.185zm10.982 11.877h-.046v-8.01a.362.362 0 00-.366-.369H9.948c-.183 0-.366.184-.366.368v8.01c0 .185.183.369.366.369h12.08a.362.362 0 00.366-.368zm-7.321-6.077c-.275 0-.458-.184-.458-.46 0-.277.183-.46.458-.46h1.83c.275 0 .458.183.458.46 0 .276-.183.46-.458.46h-1.83zm5.49 1.38c.275 0 .458.185.458.461 0 .277-.183.46-.457.46h-9.152c-.275 0-.458-.183-.458-.46 0-.276.183-.46.458-.46h9.152z"
        fill="#3F2D51"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgIconBathroom;
