import React from 'react';

const SvgIconSuccess = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.115 6.44c-10.874 0-19.674 8.8-19.674 19.675s8.8 19.674 19.674 19.674c10.875 0 19.674-8.8 19.674-19.674 0-10.874-8.8-19.674-19.674-19.674z"
      fill="#FFC543"
    />
    <path
      d="M36.017 20.046L23.172 32.892c-.257.256-.6.428-.985.428a1.425 1.425 0 01-1.285-.386L12.81 24.8a1.42 1.42 0 010-2.012l.685-.685a1.421 1.421 0 012.012 0l6.509 6.508 11.26-11.261a1.421 1.421 0 012.013 0l.728.685a1.421 1.421 0 010 2.012z"
      fill="#3F2D51"
    />
    <path
      d="M24.81 47.19c-12.807.447-23.553-9.573-24-22.38a23.102 23.102 0 012.876-12.033 1.465 1.465 0 012.563 1.419 20.172 20.172 0 00-2.512 10.512c.391 11.19 9.78 19.946 20.97 19.555 11.192-.391 19.947-9.78 19.556-20.97-.391-11.192-9.78-19.947-20.97-19.556a20.171 20.171 0 00-10.31 3.238 1.465 1.465 0 01-1.594-2.457A23.101 23.101 0 0123.189.81c12.81-.447 23.555 9.573 24.001 22.38.447 12.808-9.573 23.553-22.38 24z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgIconSuccess;
