import React from 'react';

const SvgPaymentView = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M25.071 9.957V8.028a.322.322 0 00-.321-.321H9.32a.322.322 0 00-.32.321v1.929c0 .177.144.321.321.321H24.75a.322.322 0 00.321-.321zm-15.75 3.536a.322.322 0 00-.321.321v1.929c0 .176.144.321.321.321h7.393a.322.322 0 00.321-.321v-1.929a.322.322 0 00-.321-.321H9.321zm5.786 18.16H5.785V3.368h22.5v13.821c0 .177.145.322.322.322h2.25a.322.322 0 00.321-.322V1.761c0-.712-.574-1.286-1.285-1.286H4.178c-.71 0-1.285.574-1.285 1.286v31.5c0 .71.574 1.285 1.285 1.285h10.929a.322.322 0 00.321-.321v-2.25a.322.322 0 00-.321-.322zm17.907 2.07l-3.748-3.75a7.071 7.071 0 10-1.752 1.64l3.805 3.805a.323.323 0 00.45 0l1.245-1.245a.32.32 0 000-.45zm-9.39-3.516a4.498 4.498 0 01-4.5-4.5c0-2.487 2.014-4.5 4.5-4.5 2.488 0 4.5 2.013 4.5 4.5s-2.012 4.5-4.5 4.5z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgPaymentView;
