import React from 'react';

const SvgInspectionMore = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M28.193 16.988L10.081 2.842a.32.32 0 00-.518.253V6.2a.65.65 0 00.245.507L24.272 18 9.808 29.295a.638.638 0 00-.245.506v3.106c0 .27.309.418.518.253l18.112-14.147a1.281 1.281 0 00.364-1.575 1.283 1.283 0 00-.364-.45z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgInspectionMore;
