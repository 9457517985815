import React from 'react';

const SvgRulesFemale = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <circle fill="#EBEAED" cx={12} cy={12} r={12} />
      <path
        d="M12 5.4a4.22 4.22 0 01.75 8.38v1.07h1.59c.38 0 .69.28.74.65v.1c0 .41-.33.75-.74.75h-1.59v1.7c0 .38-.28.7-.65.74l-.1.01a.75.75 0 01-.75-.75v-1.7H9.57a.75.75 0 01-.74-.65v-.1c0-.41.33-.75.74-.75h1.68v-1.07A4.22 4.22 0 017.8 9.83l-.01-.2A4.22 4.22 0 0112 5.4zm0 1.5a2.72 2.72 0 100 5.44 2.72 2.72 0 000-5.44z"
        fill="#3F2D51"
      />
    </g>
  </svg>
);

export default SvgRulesFemale;
