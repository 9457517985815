import React from 'react';

const SvgFacilityBase = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round">
      <path d="M15.265 13.24l5.22 2.76L12 20.485 3.515 16l5.15-2.722" />
      <path d="M8.326 8.663s0 0 0 0h-.004A.333.333 0 008 9c0 .186.147.336.329.337 0 0 0 0 0 0 .017 0 1.708.017 2.53 1.06.8 1.018.811 3.244.811 3.267 0 .185.147.335.328.336H12c.18 0 .327-.148.33-.332.008-.633.16-2.443.76-3.204.449-.571 1.117-.847 1.598-.977a4.25 4.25 0 01.983-.15A.334.334 0 0016 9a.333.333 0 00-.33-.337c-.017 0-1.703-.012-2.543-1.08-.569-.722-.769-2.6-.798-3.261A.333.333 0 0012 4h-.006a.333.333 0 00-.324.335c0 .005-.004.575-.109 1.27-.135.894-.37 1.576-.682 1.971-.842 1.07-2.533 1.087-2.55 1.087h-.003 0zM19 12a1.001 1.001 0 000-2 1.001 1.001 0 000 2h0zM5.078 6.156a1.081 1.081 0 001.078-1.078 1.079 1.079 0 00-2.156 0 1.08 1.08 0 001.078 1.078h0z" />
    </g>
  </svg>
);

export default SvgFacilityBase;
