import React from 'react';

const SvgFacilityOokshelves = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round">
      <path d="M2.991 19.305h17.93M5 4.7h5v13H5zm1.48 8.467h2.061m-2.061 2h2.061m-2.061-8h2.061M11.4 5.51l4.698-1.71 4.447 12.216-4.699 1.71zm4.287 7.45l1.936-.705M16.37 14.84l1.937-.705m-4.672-6.813l1.936-.705" />
    </g>
  </svg>
);

export default SvgFacilityOokshelves;
