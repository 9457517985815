import React from 'react';

const SvgFacilityHeater = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g
      transform="translate(2.8 3)"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M3.63 8.046H2.033v2.008H3.63" />
      <rect x={3.63} y={6.663} width={2.429} height={11.3} rx={1.214} />
      <rect x={6.059} y={6.663} width={2.429} height={11.3} rx={1.214} />
      <rect x={8.487} y={6.663} width={2.429} height={11.3} rx={1.214} />
      <rect x={10.916} y={6.663} width={2.429} height={11.3} rx={1.214} />
      <rect x={13.345} y={6.663} width={2.429} height={11.3} rx={1.214} />
      <path d="M2.022 9.077H.672M17.803 4.25v4.827h-1.665M10.019 0c.299.433.434.91.405 1.43-.043.783-.64.716-.712 1.33-.048.408.071.995.358 1.76M6.983.877c.3.349.434.733.406 1.153-.044.63-.64.577-.713 1.07-.048.33.071.803.358 1.42M13.055.877c.299.349.434.733.405 1.153-.043.63-.64.577-.712 1.07-.049.33.07.803.358 1.42" />
    </g>
  </svg>
);

export default SvgFacilityHeater;
