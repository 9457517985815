import React from 'react';

const SvgBookingSummaryBathroom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx={14} cy={14} r={14} />
      <path
        d="M19.42 13.922h-1.564c-.018-.02-6.072.036-6.079 0H10.25c-.522 0-.973.461-.973.989v5.667c0 .494.451.923.973.923h9.136c.556 0 .973-.429.973-.923v-5.667c.034-.528-.417-.989-.939-.989zM10.792 10.617c0-.059.073-.117.145-.117h7.037c.036 0 .11.058.11.117.002.355-.174.696-.488.948a1.917 1.917 0 01-1.19.393h-3.974c-.911 0-1.64-.583-1.64-1.312v-.03z"
        fill="#FFC543"
      />
      <path
        d="M19.276 12.237c.6 0 1.121.563 1.081 1.208v6.928c0 .604-.48 1.128-1.12 1.128H8.705c-.6 0-1.12-.524-1.12-1.128v-6.928c0-.645.52-1.208 1.12-1.208h1.762c-.76-.403-1.281-1.25-1.281-2.176v-.08c0-.524.44-.967.96-.967h2.643v-1.41c0-.322.28-.604.6-.604h1.202c.32 0 .6.282.6.604v.202h1.201c.24 0 .4.16.4.402 0 .242-.16.403-.4.403h-1.2v.403h2.642c.524 0 .96.404 1 .926 0 1.007-.56 1.894-1.36 2.297h1.801zm-5.685-4.431v1.208h.8V7.806h-.8zM9.987 9.98v.04a1.8 1.8 0 001.802 1.813h4.364a1.842 1.842 0 001.842-1.853c0-.08-.08-.161-.12-.161h-7.728c-.08 0-.16.08-.16.16zm9.61 10.392h-.04v-7.009a.317.317 0 00-.32-.322H8.705c-.16 0-.32.161-.32.322v7.01c0 .16.16.321.32.321h10.57c.16 0 .32-.12.32-.322zm-6.407-5.317c-.24 0-.4-.161-.4-.403 0-.241.16-.403.4-.403h1.602c.24 0 .4.162.4.403 0 .242-.16.403-.4.403H13.19zm4.805 1.208c.24 0 .4.162.4.403 0 .242-.16.403-.4.403H9.987c-.24 0-.4-.161-.4-.403 0-.241.16-.403.4-.403h8.008z"
        fill="#4C4C4C"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgBookingSummaryBathroom;
