export const SHOWMODAL = 'SHOWLOGINMODAL';
export const HIDEMODAL = 'HIDELOGINMODAL';
interface ModalAction {
  type: string;
  message?: ModalMessage;
}
interface ModalMessage {
  propertyId?: string;
  isOpen: boolean;
  type: 'LOGIN' | 'CREATE_ACCOUNT' | 'FORGET_PASSWORD';
  onConfirm?: () => void;
  onClose?: () => void;
}
export type ModalActionType = ModalAction;
export type ModalMessageType = ModalMessage;
