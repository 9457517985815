import React from 'react';

const SvgInspectionSuccess = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.116 46.898H11.87c-1.922 0-3.483-1.543-3.483-3.438V9.764c0-1.896 1.561-3.437 3.482-3.437h30.468c1.92 0 3.481 1.541 3.481 3.437V38.3c0 4.74-3.905 8.596-8.704 8.596v.002z"
      fill="#FFC543"
    />
    <path
      d="M41.1 0H7.307C4.53 0 2.24 2.117 2.017 4.823l-.015.264-.002.17v37.487c0 2.752 2.139 5.02 4.87 5.24l.267.014.17.002H35.31c5.937 0 10.798-4.62 11.087-10.444l.012-.33.002-31.97c0-2.752-2.138-5.02-4.87-5.24l-.267-.014L41.1 0zm.003 2.862c1.214 0 2.229.893 2.394 2.065l.02.197.003.131v31.75c0 4.307-3.401 7.855-7.7 8.118l-.3.012-.211.003h-28a2.413 2.413 0 01-2.396-2.065l-.019-.197-.004-.131V5.255c0-1.204.903-2.209 2.084-2.372l.2-.019.133-.003h33.796z"
      fill="#3F2D51"
    />
    <path
      d="M26.33 30.278H12.038a1.57 1.57 0 00-1.579 1.564 1.57 1.57 0 001.466 1.558l.113.005h14.29a1.572 1.572 0 001.579-1.563 1.57 1.57 0 00-1.466-1.558l-.113-.005v-.001zm5.986-10.327H12.039a1.57 1.57 0 00-1.579 1.563 1.57 1.57 0 001.466 1.558l.113.005h20.277a1.57 1.57 0 001.578-1.563 1.57 1.57 0 00-1.465-1.558l-.113-.005zM19.473 9.623H12.04a1.57 1.57 0 00-1.579 1.563 1.57 1.57 0 001.466 1.558l.113.005h7.434a1.57 1.57 0 001.579-1.563 1.57 1.57 0 00-1.466-1.56l-.113-.003z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgInspectionSuccess;
