import React from 'react';

const SvgPaymentGuarantor = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M0 24c0 13.255 10.745 24 24 24s24-10.745 24-24S37.255 0 24 0 0 10.745 0 24z"
      fill="#FFC543"
    />
    <path
      d="M12.017 32.011a10.722 10.722 0 013.967-7.637l.132-.098a.833.833 0 01.446-.134c.528 0 .86.264.86.696a.848.848 0 01-.315.66l-.132.084c-1.949 1.42-3.057 3.585-3.273 6.43v.095a.864.864 0 01-.84.879.854.854 0 01-.845-.826H12l.017-.151v.002zm2.743-14.563c0-4.327 3.473-7.848 7.752-7.848 4.28 0 7.752 3.52 7.752 7.848a7.797 7.797 0 01-2.777 6c-.468.434-1.085.792-1.42.96-.913.43-2.317.893-3.572.893a7.802 7.802 0 01-3.372-.778l-.777-.48-.644-.43a7.878 7.878 0 01-2.942-6.162v-.003zm7.752 6.264c3.42 0 6.197-2.808 6.197-6.264 0-3.456-2.777-6.264-6.197-6.264-3.422 0-6.2 2.808-6.2 6.264 0 3.454 2.778 6.264 6.2 6.264zm8.184 1.4c2.976.287 5.304 2.75 5.304 5.735v1.092h-5.304v2.285c0 1.263-1.056 2.285-2.357 2.285-1.3 0-2.354-1.023-2.354-2.285v-.571h1.176v.571c0 .631.528 1.143 1.178 1.143a1.16 1.16 0 001.179-1.143V31.94h-5.304v-1.09c0-2.987 2.328-5.447 5.304-5.735V23.94h1.178v1.174-.003zm0 5.687h4.126c-.03-2.532-2.132-4.572-4.714-4.572-2.585 0-4.685 2.04-4.714 4.57h5.304l-.002.002z"
      fill="#3F2D51"
      stroke="#3F2D51"
    />
  </svg>
);

export default SvgPaymentGuarantor;
