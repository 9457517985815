import React from 'react';

const SvgFacilityBroom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#4C4C4C" strokeLinecap="round" strokeLinejoin="round">
      <path d="M7 14.5a3.5 3.5 0 013.464 4H3.536A3.5 3.5 0 017 14.5z" />
      <rect x={6} y={3} width={2} height={4} rx={1} />
      <rect x={15.5} y={3} width={2} height={4} rx={1} />
      <path d="M3.5 18.5h7V20a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-1.5h0zM7 7v7.095m9.5-6.86v9.029M13 16.5h7l1 4h-9z" />
    </g>
  </svg>
);

export default SvgFacilityBroom;
