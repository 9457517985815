import React from 'react';

const SvgSharePhone = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M32.67 7.024L28.39 2.75a2.764 2.764 0 00-3.92 0l-4.61 4.6a2.773 2.773 0 000 3.93l3.6 3.604a16.292 16.292 0 01-3.47 5.115 16.324 16.324 0 01-5.111 3.48l-3.6-3.605a2.764 2.764 0 00-3.922 0l-4.612 4.6a2.773 2.773 0 000 3.93l4.275 4.275a4.81 4.81 0 003.383 1.402c.261 0 .514-.02.771-.064 5.32-.876 10.6-3.708 14.862-7.967 4.26-4.267 7.088-9.547 7.972-14.87a4.79 4.79 0 00-1.338-4.155zM31.16 10.7c-.784 4.738-3.332 9.463-7.169 13.3-3.837 3.837-8.558 6.384-13.295 7.167a1.89 1.89 0 01-1.64-.53l-4.198-4.199 4.452-4.455 4.813 4.821.037.036.867-.321a19.336 19.336 0 0011.48-11.483l.32-.868-4.853-4.85 4.452-4.456 4.199 4.2a1.88 1.88 0 01.534 1.638z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgSharePhone;
