import React from 'react';

const SvgIconDocuments = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.474 42.906H9.13c-1.96 0-3.555-1.569-3.555-3.49V15.337c0-1.929 1.594-3.491 3.555-3.491h14.1c1.767 0 2.294 1.1 3.175 2.604l2.514 4.48h18.047c1.96 0 .347 1.57.347 3.492l-.206 16.992c-.013 1.922-3.671 3.491-5.632 3.491z"
      fill="#FFC543"
    />
    <path
      d="M43.667 43.446H4.327A4.332 4.332 0 010 39.12V9.327A4.332 4.332 0 014.327 5h17.146a6.03 6.03 0 015.214 3.022l3.292 5.747h13.694A4.332 4.332 0 0148 18.096v21.03a4.34 4.34 0 01-4.333 4.32zM4.327 7.585c-.965 0-1.742.784-1.742 1.742V39.12c0 .964.784 1.742 1.742 1.742h39.34c.964 0 1.742-.785 1.742-1.742V18.09c0-.965-.784-1.743-1.742-1.743H28.48l-4.037-7.04a3.44 3.44 0 00-2.97-1.722H4.326z"
      fill="#3F2D51"
    />
  </svg>
);

export default SvgIconDocuments;
