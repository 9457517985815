import React from 'react';

const SvgRulesSmokingNo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <circle fill="#EBEAED" cx={12} cy={12} r={12} />
      <path
        d="M17.8 14.07c.52 0 .95.43.95.95v2.6c0 .53-.43.95-.95.95H6.2a.95.95 0 01-.95-.95v-2.6c0-.52.43-.95.95-.95h11.6zm-3.27 1.5H6.75v1.5h7.79v-1.5h-.01zm2.72 0h-1.21v1.5h1.21v-1.5zm-1-10.09a.753.753 0 111.09 1.04c-.83.88-.78 1.27.04 2.5l.15.25c.98 1.55 1.08 2.6-.18 3.97a.75.75 0 01-1.1-1.01c.72-.8.66-1.18-.09-2.31L16 9.67c-1.04-1.65-1.13-2.73.25-4.19zm-3 0a.753.753 0 111.09 1.04c-.83.88-.78 1.27.04 2.5l.17.28c.75 1.18.94 1.93.46 2.98-.15.32-.37.64-.66.96a.75.75 0 11-1.1-1.01c.18-.21.32-.4.4-.58.22-.48.1-.83-.49-1.73L13 9.67c-1.04-1.65-1.13-2.73.25-4.19z"
        fill="#3F2D51"
      />
      <path
        d="M3.47 4.53l16 16a.75.75 0 001.06-1.06l-16-16a.75.75 0 00-1.06 1.06z"
        fill="#3F2D51"
      />
    </g>
  </svg>
);

export default SvgRulesSmokingNo;
