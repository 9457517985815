import React from 'react';

const SvgNominationPaymentMethods = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 70 68" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M64.58 16.575c2.973 0 5.408 2.435 5.408 5.408v11.175h-19.64c-2.973 0-5.407 2.435-5.407 5.407v3.457c0 2.972 2.434 5.407 5.407 5.407h19.64v15.078c0 2.972-2.435 5.407-5.408 5.407H5.415c-2.972 0-5.407-2.435-5.407-5.407V21.983c0-2.973 2.435-5.408 5.407-5.408zM51.035 37.788a3.141 3.141 0 113.142 5.441 3.141 3.141 0 01-3.142-5.44zM46.975.684C49.58-.753 52.884.2 54.32 2.804l5.707 10.338H24.433z"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgNominationPaymentMethods;
