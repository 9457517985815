import React from 'react';

const SvgFacilityNotice = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M17.684 15.816l1.691 4.719M12 3.518v1.877m0 10.421v4.719m-5.609-4.719L4.7 20.535M3.474 5.395h17.053v10.421H3.474zm4.022 8.526h9.008"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityNotice;
