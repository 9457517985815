import React from 'react';

const SvgBedYellow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.547 20.734H8.73V11.96a2.19 2.19 0 012.175-2.145h30.556a1.044 1.044 0 011.086 1.101v9.82z"
      fill="#FFC543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.816 37.09v2.175A1.029 1.029 0 018.73 40.37a1.043 1.043 0 01-1.089-1.104V37.09H2.175A2.19 2.19 0 010 34.915V30.55a10.929 10.929 0 015.346-9.372V9.931a3.933 3.933 0 014.038-3.93h29.127a3.99 3.99 0 014.035 3.93v11.187A10.93 10.93 0 0148 30.55v4.365a2.19 2.19 0 01-2.19 2.175h-5.439v2.175a1.044 1.044 0 01-1.101 1.104 1.043 1.043 0 01-1.089-1.104V37.09H9.816zm15.27-15.27h-2.175v.06H9.891a8.76 8.76 0 00-7.713 8.67v4.365h43.65V30.55a8.76 8.76 0 00-7.713-8.67H25.086v-.06zM38.511 8.173H9.381a1.83 1.83 0 00-1.86 1.758v10.26c.39-.132.792-.24 1.203-.324V16.43a3.22 3.22 0 013.279-3.276h7.629a3.218 3.218 0 013.279 3.273v3.22h2.175v-3.22c-.027-.75.21-1.479.66-2.07l.24-.282a3.22 3.22 0 012.319-.984h7.629l.06.06a3.22 3.22 0 013.276 3.276v3.441c.375.078.744.174 1.104.288V9.931a1.77 1.77 0 00-1.863-1.758zm-2.58 7.092h-7.626a1.045 1.045 0 00-1.029 1.161v3.22h9.819v-3.217a1.043 1.043 0 00-1.074-1.1l-.09-.06v-.004zm-16.296 0v.06h-7.629a1.043 1.043 0 00-1.101 1.101v3.22h9.831v-3.277a1.044 1.044 0 00-1.101-1.104z"
      fill="#3F2D51"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.825 34.915H6.555a1.044 1.044 0 01-1.104-1.1V30.55a5.409 5.409 0 015.454-5.466H41.46a4.364 4.364 0 014.35 4.365v5.466h.015z"
      fill="#FFC543"
    />
  </svg>
);

export default SvgBedYellow;
