import classNames from 'classnames';
import React from 'react';
import styles from './button.module.scss';
import Loading from './loading';
interface ButtonProps {
  fullWidth?: boolean;
  fontWeight?: any;
  loading?: boolean;
  color?: 'primary' | 'secondary' | 'danger';
  size?: 'large' | 'default' | 'small' | 'mini';
}

const Button = React.forwardRef<any, ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>(
  (props, ref) => {
    const {
      size,
      color,
      fullWidth,
      className,
      children,
      fontWeight,
      loading,
      ...otherProp
    } = props;

    return (
      <>
        <button
          disabled={props.disabled || loading}
          className={classNames(
            styles.root,
            {
              [styles.rootDisabled]: !!props?.disabled,
              [styles.fullWidth]: fullWidth,

              [styles.sizeDefault]: !size || size === 'default',
              [styles.sizeSmall]: size === 'small',
              [styles.sizeLarge]: size === 'large',
              [styles.sizeMini]: size === 'mini',

              [styles.colorPrimary]: !color || color === 'primary',
              [styles.colorPrimaryDisabled]: (!color || color === 'primary') && props.disabled,

              [styles.colorSecondary]: color === 'secondary',
              [styles.colorSecondaryDisabled]: color === 'secondary' && props.disabled,

              [styles.colorDanger]: color === 'danger',
              [styles.colorDangerDisabled]: color === 'danger' && props.disabled,
            },
            className
          )}
          ref={ref}
          style={{ fontWeight: fontWeight || 600 }}
          {...otherProp}
        >
          {loading ? <Loading className="button-loading" color={color} /> : <span>{children}</span>}
        </button>
      </>
    );
  }
);

export default Button;
