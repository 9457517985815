import React from 'react';

const SvgFacilityToilet = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M4 3h5.667v7.933H4zm3.285 17.937c.136-1.262-.011-2.065-.443-2.41-.398-.001-1.346-.001-2.842 0V12.86h15.734a1 1 0 01.963 1.268c-.633 2.28-1.562 3.611-2.784 3.996-1.107.285-3.904.362-4.99.403-.838.208-1.2 1.07-1.086 2.583"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityToilet;
