import React from 'react';

const SvgRulesMale = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <circle fill="#EBEAED" cx={12} cy={12} r={12} />
      <path
        d="M16.73 6.45c.41 0 .75.34.75.75v3.36a.75.75 0 11-1.5 0v-1.5l-1.74 1.96c.4.61.66 1.33.7 2.11v.21a4.2 4.2 0 11-1.74-3.4l1.75-1.99h-1.5a.75.75 0 01-.75-.65v-.1c0-.41.33-.75.74-.75h3.29zm-5.98 4.2a2.7 2.7 0 100 5.39 2.7 2.7 0 000-5.4v.01z"
        fill="#3F2D51"
      />
    </g>
  </svg>
);

export default SvgRulesMale;
