import React from 'react';

const SvgIconContactDisabled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 39" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M27.34 36.667H8.111a2.65 2.65 0 01-2.652-2.642V8.14A2.65 2.65 0 018.11 5.497h23.208a2.65 2.65 0 012.652 2.642v21.924c0 3.641-2.974 6.604-6.63 6.604z"
        fill="#d9d9d9"
      />
      <path
        d="M30.376.636H4.635a4.041 4.041 0 00-4.03 3.706l-.01.202-.003.13v28.802a4.04 4.04 0 003.71 4.025l.203.011.13.002h21.33c4.522 0 8.225-3.55 8.445-8.023l.009-.254.002-24.563A4.04 4.04 0 0030.71.65l-.203-.011-.132-.002zm.002 2.199c.925 0 1.698.686 1.823 1.586l.015.152.003.101v24.393c0 3.309-2.59 6.034-5.865 6.236l-.23.01-.16.002H4.635a1.843 1.843 0 01-1.823-1.586l-.015-.152-.002-.101V4.674c0-.924.687-1.697 1.587-1.822l.152-.014.102-.003h25.743z"
        fill="#d9d9d9"
      />
      <path
        d="M19.124 23.9H8.24a1.201 1.201 0 00-.086 2.398l.086.003h10.885a1.201 1.201 0 00.086-2.399l-.086-.003zm4.56-7.936H8.24a1.201 1.201 0 00-.086 2.398l.086.003h15.446a1.201 1.201 0 00.085-2.398l-.085-.003zm-9.782-7.935H8.24a1.201 1.201 0 00-.086 2.398l.086.003h5.663a1.201 1.201 0 00.086-2.398l-.086-.003z"
        fill="#F4F6F7"
      />
    </g>
  </svg>
);

export default SvgIconContactDisabled;
