import React from 'react';

const SvgMasterDisabled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 29" {...props}>
    <g fill="none">
      <path fill="#7A7A7A" d="M16.664 3.067h12.308v22.119H16.664z" />
      <path
        d="M17.446 14.128a14.042 14.042 0 015.372-11.06C17.051-1.466 8.77-.806 3.793 4.583c-4.976 5.388-4.976 13.697 0 19.085 4.976 5.389 13.258 6.05 19.025 1.517a14.042 14.042 0 01-5.372-11.058z"
        fill="#404040"
      />
      <path
        d="M45.578 14.128a14.066 14.066 0 01-22.76 11.058 14.066 14.066 0 000-22.119 14.066 14.066 0 0122.76 11.058v.003z"
        fill="#B8B8B8"
      />
    </g>
  </svg>
);

export default SvgMasterDisabled;
