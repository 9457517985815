import React from 'react';

const SvgDoubleMore = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M18 8.394l13.625 10.663a.32.32 0 00.519-.253v-3.106a.65.65 0 00-.245-.506L18.791 4.935a1.285 1.285 0 00-1.587 0L4.103 15.192a.638.638 0 00-.245.506v3.106c0 .27.31.418.518.253L18.001 8.394zm0 12.214l13.625 10.664a.32.32 0 00.519-.253v-3.106a.65.65 0 00-.245-.506L18.791 17.149a1.285 1.285 0 00-1.587 0L4.103 27.407a.638.638 0 00-.245.506v3.105c0 .27.31.418.518.254l13.625-10.664z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgDoubleMore;
