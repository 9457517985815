import React from 'react';

const SvgProfileWarning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <path
        d="M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0z"
        fill="#FE544A"
      />
      <path
        d="M14 18.796a2.12 2.12 0 100 4.24 2.12 2.12 0 000-4.24zm-.061-1.658a1.826 1.826 0 001.826-1.826V6.79a1.826 1.826 0 10-3.652 0v8.522c0 1.009.817 1.826 1.826 1.826z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default SvgProfileWarning;
