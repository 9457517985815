import React from 'react';

const SvgFacilityPlugsock = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g
      transform="translate(4 4)"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M0 0h16v16H0z" />
      <circle cx={8} cy={8} r={5} />
      <path d="M5.768 7.006v1.818M10 7.048v1.776" />
    </g>
  </svg>
);

export default SvgFacilityPlugsock;
