import React from 'react';

const SvgLocation = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.69 7.888a6.171 6.171 0 00.26-1.694l-.019-.249a7.64 7.64 0 00-.056-.578 8.216 8.216 0 00-.176-.865 5.755 5.755 0 00-.56-1.359 6.06 6.06 0 00-.859-1.185 8.845 8.845 0 00-.577-.55 5.33 5.33 0 00-1.021-.7A5.82 5.82 0 007.613.263 5.569 5.569 0 006.288.027a5.065 5.065 0 00-1.185.057c-.326.058-.65.143-.968.237a5.116 5.116 0 00-1.202.556c-.38.232-.731.504-1.051.82A6.22 6.22 0 00.445 3.864a5.258 5.258 0 00-.338 1.23c-.043.324-.085.65-.101.976-.011.212.008.427.028.64l.004.046c.021.222.044.446.091.664.107.489.272.958.488 1.407.108.223.222.444.343.66.096.172.2.34.312.501.079.113.165.222.251.33l.12.152.566.738.185.243.216.283a105.5 105.5 0 00.952 1.222l.29.378.26.339.24.307a23.019 23.019 0 01.398.52c.102.138.204.275.315.404.288.332.65.47 1.082.41.343-.05.604-.233.815-.507a214.83 214.83 0 00.661-.862l.307-.402a91.37 91.37 0 01.587-.758 214.811 214.811 0 00.633-.818l.192-.25.499-.644a294.628 294.628 0 00.584-.753c.1-.125.199-.25.285-.384.148-.228.279-.467.41-.705l.017-.03c.229-.417.416-.853.554-1.314zm-5.755.764C4.665 8.64 3.607 7.57 3.6 6.202c-.008-1.34 1.069-2.512 2.46-2.46 1.239.046 2.294 1.082 2.285 2.475-.008 1.338-1.04 2.45-2.41 2.435z"
      fill="#8C8197"
    />
  </svg>
);

export default SvgLocation;
