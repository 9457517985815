import React from 'react';

const SvgIconContact = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 34 37" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <path
        d="M26.711 35.946H7.722a2.617 2.617 0 01-2.62-2.609V7.774a2.617 2.617 0 012.62-2.608H30.64c1.444 0 2.62 1.17 2.62 2.608v21.65c0 3.596-2.938 6.522-6.549 6.522z"
        fill="#ffc543"
      />
      <path
        d="M29.709.365H4.289A3.99 3.99 0 00.31 4.025l-.01.2-.003.128v28.442a3.99 3.99 0 003.664 3.975l.2.01.128.003h21.064c4.465 0 8.122-3.505 8.34-7.923l.008-.251.002-24.256A3.99 3.99 0 0030.039.38l-.2-.012-.13-.002zm.002 2.172a1.82 1.82 0 011.8 1.566l.015.15.003.1v24.089c0 3.267-2.558 5.958-5.792 6.157l-.226.01-.158.002H4.289a1.82 1.82 0 01-1.8-1.566l-.015-.15-.003-.1V4.353A1.82 1.82 0 014.04 2.554l.15-.014.1-.003h25.422z"
        fill="#3F2D51"
      />
      <path
        d="M18.598 23.338H7.848a1.186 1.186 0 00-.084 2.368l.084.003h10.75a1.186 1.186 0 00.085-2.368l-.085-.003zm4.503-7.836H7.848a1.186 1.186 0 00-.084 2.368l.084.003h15.253a1.186 1.186 0 00.085-2.368l-.085-.003zm-9.66-7.836H7.848a1.186 1.186 0 00-.084 2.368l.084.003h5.593a1.186 1.186 0 00.085-2.368l-.085-.003z"
        fill="#3F2D51"
      />
    </g>
  </svg>
);

export default SvgIconContact;
