import React from 'react';

const SvgIllustrationsOfferedAccommodationMobile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 192 124" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h192v124H0z" />
      <path
        d="M160.844 59.503s.338 4.56.338 4.729c0 .169-2.364 11.82-2.364 11.82s-3.377 6.417 0 6.248c3.377-.169 2.364-6.079 2.364-6.079l3.546-9.794-1.52-6.924h-2.364z"
        fill="#FFF1E4"
      />
      <circle fill="#FFC543" cx={136.186} cy={11.907} r={10.419} />
      <circle fill="#FFF1BD" cx={125.767} cy={17.116} r={5.953} />
      <circle fill="#FFF1BD" cx={93.023} cy={30.512} r={5.953} />
      <circle fill="#FFF1BD" cx={81.86} cy={34.977} r={3.721} />
      <circle fill="#FFF1BD" cx={25.302} cy={49.86} r={3.721} />
      <path
        d="M171.145 74.87l7.6 2.027s.506 1.688.337 2.195c-.17.506.169.844 0 1.182-.17.338-.338 2.026-.338 2.026v8.275c0 .337.507.844.338.844-.17 0 .169 0 0 .507-.17.506 0 .844 0 1.52 0 .675-.845 15.366-1.013 17.055-.059.583 0 1.773.168 2.702l.845 3.04s3.462 2.786-.422 2.448c-2.775-.241-3.524-.181-3.726-.127l-.033.01c-.032.013-.04.023-.04.029v.003s-1.183-.337-1.352-.675c-.16-.322.753-4.79.838-5.204-.175-1.493.007-4.084.007-4.084l-.676-14.86s.169-1.689-.675-2.364c-.845-.675-2.196-7.599-2.196-7.599l-1.52 10.3s0 1.352-.337 1.52c-.338.17 0 .676 0 .676s.169 1.013 0 1.52c-.169.507.338 11.652.338 11.652l.506 3.208s.507 4.897.338 5.235c-.169.338-1.351.675-1.351.675l.01-.005a.084.084 0 00.01-.007l.008-.008c.019-.024-.012-.064-.385-.07h-.146c-.457.002-1.29.044-2.79.175-3.884.338-.422-2.449-.422-2.449l.844-3.04-.016-.134c-.036-.281-.084-.624-.136-.98l-.054-.36c-.145-.96-.3-1.903-.3-1.903l-2.094-12.542-.048-.793s-.076-.79-.076-1.214c0-.425-.007-.266-.03-.603-.022-.336-.043-.548-.043-1.055 0-.265-.251-2.515-.563-4.843l-.082-.608c-.265-1.923-.56-3.787-.78-4.516-.506-1.688 0-6.754 0-6.754l.845-1.182 8.612-.845z"
        fill="#859094"
      />
      <path fill="#4C4C4C" d="M48.32 58.985h1.503v59.382h-1.504z" />
      <path
        d="M63.697 66.553c.096 20.764-14.513 37.665-14.513 37.665s-14.764-16.765-14.86-37.53c-.096-20.764 14.513-37.665 14.513-37.665s14.764 16.765 14.86 37.53z"
        fill="#4FE0BE"
      />
      <path fill="#F5F6F7" d="M38.08 93.783h116.347v24.655H38.08z" />
      <path fill="#57666A" d="M34.364 94.997L47.03 68.485h101.15l9.457 26.512z" />
      <path fill="#F5F6F7" d="M68.919 70.173h46.944V85.71H68.92z" />
      <path fill="#57666A" d="M64.528 73.044h55.726l-4.39-15.535H71.957z" />
      <path
        fill="#D4D8D9"
        d="M70.08 98.68h37.065v19.758H70.08zM42.639 99.356h19.757v19.082H42.639z"
      />
      <path fill="#57666A" d="M113.562 100.538h10.216v17.9h-10.216z" />
      <path fill="#F5F6F7" d="M0 118.353h192v1H0z" />
      <path fill="#859094" d="M30.38 79.186h1v39.186h-1z" />
      <path
        d="M40.527 84.18c.063 13.702-9.577 24.855-9.577 24.855s-9.743-11.063-9.806-24.766c-.063-13.702 9.577-24.855 9.577-24.855s9.743 11.064 9.806 24.766z"
        fill="#9DE7D6"
      />
      <path fill="#859094" d="M17.483 79.186h1v39.186h-1z" />
      <path
        d="M27.63 84.18c.064 13.702-9.577 24.855-9.577 24.855S8.311 97.972 8.248 84.269c-.063-13.702 9.577-24.855 9.577-24.855s9.742 11.064 9.806 24.766z"
        fill="#9DE7D6"
      />
      <path
        fill="#57666A"
        d="M134.839 101.551h4.982v4.644h-4.982zM141.51 101.551h4.98v4.644h-4.98zM134.839 107.883h4.982v4.813h-4.982zM141.51 107.883h4.98v4.813h-4.98zM73.225 74.424h3.898v3.635h-3.898zM78.445 74.424h3.898v3.635h-3.898zM73.225 79.38h3.898v3.767h-3.898zM78.445 79.38h3.898v3.767h-3.898zM102.438 74.424h3.9v3.635h-3.9zM107.659 74.424h3.898v3.635h-3.898zM102.438 79.38h3.9v3.767h-3.9zM107.659 79.38h3.898v3.767h-3.898zM87.916 74.424h3.899v3.635h-3.899zM93.136 74.424h3.899v3.635h-3.899zM87.916 79.38h3.899v3.767h-3.899zM93.136 79.38h3.899v3.767h-3.899z"
      />
      <path
        fill="#F5F6F7"
        d="M115.842 102.351h2.454v2.288h-2.454zM119.128 102.351h2.455v2.288h-2.455zM115.842 105.472h2.454v2.371h-2.454zM119.128 105.472h2.455v2.371h-2.455z"
      />
      <circle fill="#F5F6F7" cx={122.427} cy={110.754} r={1} />
      <path
        fill="#F5F6F7"
        d="M70.08 100.791h37.15v1H70.08zM70.08 102.31h37.15v1H70.08zM70.08 103.83h37.15v1H70.08zM70.08 105.35h37.15v1H70.08zM70.08 106.87h37.15v1H70.08zM70.08 108.39h37.15v1H70.08zM70.08 109.91h37.15v1H70.08zM70.08 111.43h37.15v1H70.08zM70.08 112.95h37.15v1H70.08zM70.08 114.47h37.15v1H70.08zM70.08 115.989h37.15v1H70.08zM42.639 100.791h19.757v1H42.639zM42.639 102.31h19.757v1H42.639zM42.639 103.83h19.757v1H42.639zM42.639 105.35h19.757v1H42.639zM42.639 106.87h19.757v1H42.639zM42.639 108.39h19.757v1H42.639zM42.639 109.91h19.757v1H42.639zM42.639 111.43h19.757v1H42.639zM42.639 112.95h19.757v1H42.639zM42.639 114.47h19.757v1H42.639zM42.639 115.989h19.757v1H42.639z"
      />
      <path
        d="M147.883 53.581c7.398 0 13.395 5.998 13.395 13.396 0 5.428-3.228 10.102-7.87 12.206l-1.337 2.315-4.188 7.255-4.189-7.255-1.338-2.315c-4.64-2.105-7.869-6.779-7.869-12.206 0-7.398 5.998-13.396 13.396-13.396z"
        fill="#FE544A"
      />
      <circle fill="#FFF3F1" cx={147.991} cy={66.893} r={4.806} />
      <circle fill="#FFF1E4" cx={164.728} cy={41.773} r={4.391} />
      <path
        d="M163.208 44.981s.676 3.377.676 3.884c0 .507 2.87 2.026 2.87 2.026l3.21-4.052s-2.027-2.871-2.027-3.547l-4.729 1.689z"
        fill="#FFF1E4"
      />
      <path
        d="M169.284 45.836l5.407.665 1.689.675s1.857.338 2.364 3.715c.12.795.238 1.45.344 1.986l.077.38c.296 1.435.443 1.856.086 1.856-.237 0-1.694-.037-3.177-.094l.813 14.954s1.182 1.858.844 2.87c-.338 1.014 1.013 4.054 1.013 4.054s-5.235 2.364-9.625.675c-4.39-1.689-7.43-.675-7.43-.675s.844-5.404 1.182-6.417c.337-1.013 0-.507-.338-1.182-.338-.676.675-5.066.675-5.742 0-.675.338-.506 0-.675-.305-.153-.334-2.103-.337-2.473v-.06l-.136-.24-2.397.24s-.31-1.551-1.08-2.72l-.102-.15c-.845-1.183 0-3.716 0-3.716l.01-.074c.188-.818 3.133-3.83 4.24-4.942l.273-.272.144-.143c.447.649 1.918 1.04 1.918 1.04 2.196-.168 3.543-3.535 3.543-3.535z"
        fill="#2A41EA"
      />
      <path
        d="M173.34 54.1s-.978 2.514-.773 5.002c.037.446.187.874.436 1.246l-11.98 5.687s-5.414.56-4.063 2.925c1.351 2.364 6.248-1.182 6.248-1.182s12.834-1.858 13.847-3.715c1.014-1.858 1.69-9.625 1.69-9.625l-5.405-.338z"
        fill="#FFF1E4"
      />
      <path
        d="M161.376 37.133l-.761-.305s1.592-1.753 3.807-1.601l-.623-.686s1.523-.61 2.908.99c.728.842 1.57 1.831 2.095 2.946h.816l-.34.75 1.191.749-1.223-.135a4.148 4.148 0 01-.116 1.941l.033.593s-1.417-2.193-1.417-2.498v.762s-.762-.686-.762-1.143l-.415.533-.208-.838-2.562.838.416-.686-1.593.229.623-.839s-1.8.991-1.869 1.83c-.07.838-.97 1.905-.97 1.905l-.415-.762s-.623-3.43 1.385-4.573z"
        fill="#859094"
      />
    </g>
  </svg>
);

export default SvgIllustrationsOfferedAccommodationMobile;
