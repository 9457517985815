import React from 'react';

const SvgExpireBanner = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 217 241" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.884 161.365s-18.762-41.906-50.98-44.318c-21.45 32.396 11.045 61.81 11.045 61.81l37.834 13.446 2.101-30.938z"
      fill="#4FE0BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.683 133.608l-8.208 18.633-22.81-35.122c-.255-.024-.506-.053-.761-.072-.085.128-.161.255-.244.382l12.217 18.811-18.714-2.75c-.03.177-.056.354-.084.53l-.07.469 19.602 2.88 20.36 31.348-32.998-3.778c.22.37.44.736.662 1.093l33.05 3.784 14.458 22.26.64.227.067-.995-24.716-38.053 8.31-18.865a70.823 70.823 0 00-.761-.782z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.994 180.984s-33.021-19.451-53.225-3.505c5.95 52.221 72.795 48.338 72.795 48.338l1.745-30.689-21.315-14.144z"
      fill="#FFC543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.769 177.475c.023.202.05.399.074.599l46.615 22.822-25.544 12.069c.327.221.657.437.988.651l25.719-12.151 18.207 8.914-.626-1.432-35.448-17.356-7.069-20.283a49.603 49.603 0 00-1.092-.062l6.87 19.714-28.241-13.827c-.151.114-.303.223-.453.341v.001z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.222 151.457s27.105-37.22 59.83-29.04c7.15 57.601-49.519 64.479-49.519 64.479l-10.311-35.439z"
      fill="#FFC543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.94 124.2c.025.201.046.398.069.597l-41.878 34.487 33.375 5.559c-.217.31-.436.614-.659.915l-33.751-5.622-12.084 9.952-.307-1.056 24.09-19.838-1.958-19.514c.319-.177.639-.353.963-.525l1.933 19.267 29.585-24.364c.208.048.414.091.622.142z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.701 179.965s36.04-13.031 52.958 6.365c-15.474 50.229-80.457 34.093-80.457 34.093l27.499-40.458z"
      fill="#4FE0BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M158.66 186.331c-.06.194-.123.384-.184.575l-50.023 13.84 22.882 16.57c-.362.157-.726.309-1.091.458l-23.038-16.683-19.538 5.405.878-1.292 38.04-10.524 10.686-18.633c.362.042.724.089 1.085.14l-10.385 18.109 30.306-8.384c.127.139.256.275.381.418l.001.001z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.617 179.539l-4.844-3.043 15.115-6.74-6.257-14.248c-2.893-6.077-10.365-11.004-17.502-11.008H69.011c-7.138.004-14.61 4.931-17.503 11.008l-6.257 14.248 15.115 6.74-4.844 3.043-2.684 23.154 31.732-.498 31.731.498-2.684-23.154z"
      fill="#5567EE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.888 169.764l3.12 10.308c2.276 7.52-.153 15.594-6.293 20.916l-14.159 11.06-4.552-12.377 7.832-7.303c2.571-2.398 3.337-6.004 1.941-9.133l-3.004-6.732 15.115-6.739zm-61.813 66.36l-12.61 1.712-2.427-2.667 1.231-4.833 12.12-1.748 1.685 7.536z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.466 230.165l-13.044-1.575s-5.034-1.321-9.538-.594v11.846c3.37.385 6.711-.047 6.711-.047l15.87-1.957-.18-.273c-1.46-2.208-1.387-5.265.18-7.4z"
      fill="#595959"
    />
    <mask
      id="expire-banner_svg__a"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={20}
      y={228}
      width={7}
      height={12}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.225 233.231c.875-3.345 3.671-4.746 6.699-5.225v11.856c-3.929-.441-7.914-1.988-6.699-6.631z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#expire-banner_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.225 233.231c.875-3.345 3.671-4.746 6.699-5.225v11.856c-3.929-.441-7.914-1.988-6.699-6.631z"
        fill="#BFBFBF"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.762 236.129h1.304v-5.096h-1.304v5.096zm-3.221 0h1.305v-5.096h-1.304v5.096z"
      fill="#FAFAFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.407 169.764l-3.12 10.308c-2.276 7.52.153 15.594 6.294 20.916l14.158 11.06 4.552-12.377-7.831-7.303c-2.572-2.398-3.338-6.004-1.942-9.133l3.004-6.732-15.115-6.739zm63.011 67.306l12.821.766 2.428-2.667-1.232-4.833-12.119-1.748-1.898 8.482z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.275 227.914c-4.355-.545-8.994.671-8.994.671l-13.045 1.575c1.568 2.134 1.64 5.192.18 7.4l-.18.273 15.871 1.957s2.991.388 6.168.103v-11.979z"
      fill="#595959"
    />
    <mask
      id="expire-banner_svg__b"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={143}
      y={228}
      width={8}
      height={12}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.478 233.231c-.875-3.345-3.671-4.746-6.699-5.225v11.856c3.929-.441 7.914-1.988 6.699-6.631z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#expire-banner_svg__b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.478 233.231c-.875-3.345-3.671-4.746-6.699-5.225v11.856c3.929-.441 7.914-1.988 6.699-6.631z"
        fill="#BFBFBF"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.635 236.124h1.306v-5.095h-1.306v5.095zm3.221 0h1.304v-5.095h-1.304v5.095z"
      fill="#FAFAFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.585 216.154h52.309v-31.306H60.585v31.306z"
      fill="#BFBFBF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.585 217.456h52.309v-2.608h-52.31v2.608z"
      fill="#BAA6B7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.648 197.616a2.093 2.093 0 104.186 0 2.093 2.093 0 00-4.186 0z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.339 207.806c10.452 18.615-94.803 30.04-94.803 30.04l-1.784-8.292s83.7-44.7 96.587-21.748z"
      fill="#655774"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.366 207.803c-8.878 19.414 95.678 31.055 95.678 31.055l.777-9.326s-85.959-44.682-96.455-21.729z"
      fill="#8C8197"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.08 144.437c-.97-2.935-2.726-11.636-2.726-11.636l-11.2 2.187v9.499s3.716 2.636 6.843 2.662c3.417.028 7.204-2.381 7.083-2.712z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.086 117.38l-2.11-12.442-21.523 2.548 1.305 14.334s1.663 17.739 13.12 15.629c12.393-2.284 9.76-20.227 9.208-20.069z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.114 115.688c-.287-1.51.114-3.865.915-5.176 3.717-6.084-1.91-7.852-3.871-8.377-2.17-.581-2.861-3.958-5.775-5.037-4.45-1.646-6.955 2.338-9.368 2.645-2.414.306-4.799-2.272-7.598.394-2.8 2.666-1.602 5.15-2.313 6.587-.711 1.438-3.632-.971-5.755 3.528-1.615 3.422.168 5.429 2.355 6.581 2.545 1.34 5.587 1.34 8.227.198a37.135 37.135 0 008.633-5.245c.838-.682 7.406-1.134 7.952-.202 1.85 3.163 2.002 13.681 2.002 13.681l1.916-2.683s3.552-2.309 2.68-6.894z"
      fill="#8C8C8C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.055 118.936c-.578 1.5-.233 3.03.768 3.415 1.002.385 2.282-.52 2.86-2.021.577-1.501.233-3.029-.77-3.415-1-.385-2.28.519-2.858 2.021z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.088 84.972c10.127-9.97 19.267-9.643 29.24-9.215 14.032.6 26.597-4.58 34.359-16.716 6.691-10.463 7.386-23.448 1.787-34.555-2.339-4.639-5.565-8.564-9.345-11.888C193.912 5.116 181.854 1.14 169.926.277c-17.005-1.23-37.972 1.355-50.984 13.452-12.331 11.464-18.129 30.037-8.961 44.263 5.107 7.926 13.421 13.202 22.549 15.429 5.141 1.253 11.156 3.638 14.558 11.55zM141.78 95.8c-2.529 0-4.579-1.82-4.579-4.064 0-2.243 2.05-4.062 4.579-4.062 2.528 0 4.578 1.82 4.578 4.062 0 2.245-2.05 4.064-4.578 4.064zm-13.903 4.097c0 1.601 1.462 2.9 3.266 2.9 1.803 0 3.266-1.299 3.266-2.9 0-1.6-1.463-2.899-3.266-2.899-1.804 0-3.266 1.299-3.266 2.9zm-7.814 5.62c-1.288 0-2.331-.927-2.331-2.069 0-1.143 1.043-2.069 2.331-2.069 1.287 0 2.33.926 2.33 2.069 0 1.142-1.043 2.069-2.33 2.069z"
      fill="#8C8197"
    />
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M190.8 16.06v25.21h-61.198V16.059c0-1 .81-1.809 1.809-1.809h57.578c1 0 1.811.81 1.811 1.81z"
        fill="#3F2D51"
      />
      <path
        d="M137.693 58.926l-3.792 5.412a.587.587 0 01-.477.249h-2.463a.582.582 0 01-.575-.67l.771-4.991h6.536z"
        fill="#32243F"
      />
      <path
        d="M182.704 58.926l3.793 5.412a.584.584 0 00.476.249h2.463a.582.582 0 00.575-.67l-.771-4.991h-6.536z"
        fill="#4C4C4C"
      />
      <path
        d="M158.202 30.08a79.832 79.832 0 00-17.013 0 .863.863 0 01-.921-1.104c.865-2.865.865-5.73 0-8.594a.863.863 0 01.921-1.104 79.806 79.806 0 0017.013 0 .863.863 0 01.921 1.104c-.865 2.865-.865 5.73 0 8.594a.863.863 0 01-.921 1.103zm21.001 0a79.822 79.822 0 00-17.012 0 .863.863 0 01-.922-1.104c.865-2.865.865-5.73 0-8.594a.864.864 0 01.922-1.104c5.67.607 11.341.607 17.012 0a.863.863 0 01.921 1.104c-.865 2.865-.865 5.73 0 8.594a.863.863 0 01-.921 1.103z"
        fill="#FFFFFE"
      />
      <path
        d="M185.667 30.794c2.42 1.005 4.774 10.475 4.774 10.475h-60.746s2.347-9.486 4.773-10.475c11.421-4.654 39.387-4.91 51.199 0z"
        fill="#FFC543"
      />
      <path
        d="M193.993 41.201v16.372a1.81 1.81 0 01-1.811 1.808h-63.964c-.999 0-1.809-.81-1.809-1.808V41.2c0-.999.81-1.808 1.809-1.808h63.964c.999 0 1.811.81 1.811 1.808z"
        fill="#3F2D51"
      />
      <path
        d="M191.861 39.39v.008h-63.586a6.64 6.64 0 011.701-4.45 6.632 6.632 0 014.24-2.17c15.487-1.65 36.287-1.662 51.71-.006a6.652 6.652 0 015.935 6.619z"
        fill="#FFFFFE"
      />
    </g>
    <rect x={167.173} y={34.217} width={36.992} height={36.992} rx={18.496} fill="#595959" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M185.669 34.252c-10.216 0-18.496 8.28-18.496 18.496 0 10.214 8.281 18.496 18.496 18.496 10.214 0 18.496-8.282 18.496-18.496 0-10.216-8.281-18.496-18.496-18.496zm0 28.4a2.155 2.155 0 01-2.157-2.153c0-1.188.965-2.15 2.157-2.15 1.191 0 2.157.962 2.157 2.15a2.155 2.155 0 01-2.157 2.152zm2.157-9.324a2.155 2.155 0 01-2.157 2.151 2.154 2.154 0 01-2.157-2.151V43.286c0-1.187.965-2.15 2.157-2.15 1.191 0 2.157.963 2.157 2.15v10.042z"
      fill="#D9D9D9"
    />
  </svg>
);

export default SvgExpireBanner;
