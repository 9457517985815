import React from 'react';

const SvgRulesVegetarian = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <circle fill="#EBEAED" cx={12} cy={12} r={12} />
      <path
        d="M12 8.1c2.34 0 4.3 1.5 4.3 3.49 0 2.74-2.07 7.95-4.3 7.95-2.22 0-4.3-5.2-4.3-7.95 0-1.98 1.96-3.5 4.3-3.5v.01zm0 1.5c-1.14 0-2.1.48-2.53 1.15h1.37a.75.75 0 110 1.5H9.25c.1.73.34 1.63.66 2.5h1.54a.75.75 0 110 1.5h-.92c.5 1.03 1.05 1.79 1.47 1.79.67 0 1.66-1.91 2.27-3.79h-1.32a.75.75 0 110-1.5h1.5c.07 0 .15.01.21.03.1-.45.15-.86.15-1.2 0-1.05-1.22-1.99-2.8-1.99L12 9.6zm2.98-5.1h.08a.5.5 0 01.4.59l-.05.24c-.22 1.01-.65 1.71-1.28 2.1-.79.48-1.5.6-2.14.35a1.34 1.34 0 01-.03-.08l-.03.08c-.64.25-1.35.13-2.14-.35-.63-.39-1.06-1.09-1.28-2.1l-.05-.24A.5.5 0 019 4.5a3.6 3.6 0 012.13 1.1c.42.41.7.8.82 1.17.13-.37.4-.76.82-1.17a3.6 3.6 0 012.13-1.1h.15-.07z"
        fill="#3F2D51"
      />
    </g>
  </svg>
);

export default SvgRulesVegetarian;
