import React from 'react';

const SvgCheck = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <rect fill="#FFC543" width={27.796} height={27.796} rx={13.898} />
      <path
        d="M12.009 18.672a.729.729 0 01-.516-.212l-4.28-4.259a.723.723 0 010-1.025.731.731 0 011.031 0l3.765 3.746 7.747-7.71a.731.731 0 011.03 0 .723.723 0 010 1.026l-8.262 8.222a.728.728 0 01-.515.212z"
        stroke="#3F2D51"
        strokeWidth={1.2}
        fill="#3F2D51"
      />
    </g>
  </svg>
);

export default SvgCheck;
