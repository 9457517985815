import React from 'react';

const SvgFacilityBin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g
      transform="translate(4 3)"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M2.133 4.267h11.734v12.866a1 1 0 01-1 1H3.133a1 1 0 01-1-1V4.267h0z" />
      <rect y={2.133} width={16} height={2.133} rx={1.067} />
      <path d="M6.333 0h3.334a1 1 0 011 1v1.133h0-5.334V1a1 1 0 011-1zm-1 6.961v7.411M8 6.961v7.411m2.667-7.411v7.411" />
    </g>
  </svg>
);

export default SvgFacilityBin;
