import React from 'react';

const SvgStep1 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M57.654 22.4C54.025 11.143 43.464 3 31 3"
      stroke="#FFC543"
      strokeWidth={6}
      fill="none"
    />
  </svg>
);

export default SvgStep1;
