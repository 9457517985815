import classNames from 'classnames';
import React from 'react';
import styles from './loading.module.scss';

interface LoadingProps {
  color?: 'primary' | 'secondary' | 'danger';
  className?: string;
}

const Loading = React.forwardRef<any, LoadingProps & React.AllHTMLAttributes<HTMLDivElement>>(
  ({ className, color }, ref) => {
    return (
      <div ref={ref} className={classNames(styles.loading, className)}>
        <div
          className={classNames(styles.dot, styles.dot__one, {
            [styles.dotPurple]: color === 'secondary',
          })}
        ></div>
        <div
          className={classNames(styles.dot, styles.dot__two, {
            [styles.dotPurple]: color === 'secondary',
          })}
        ></div>
        <div
          className={classNames(styles.dot, styles.dot__three, {
            [styles.dotPurple]: color === 'secondary',
          })}
        ></div>
      </div>
    );
  }
);

export default Loading;
