import React from 'react';

const SvgIconPronouns = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.577 6.261a4.6 4.6 0 10-9.154 0A4.6 4.6 0 008 14.242a4.6 4.6 0 004.577-7.98zM8 2.4a3.4 3.4 0 00-3.4 3.358A4.606 4.606 0 018 6.16a4.606 4.606 0 013.4-.401A3.4 3.4 0 008 2.4zm3.2 4.55a3.4 3.4 0 00-2.11.035c.42.43.757.942.983 1.51.51-.394.905-.928 1.127-1.545zm-4.29.035A3.395 3.395 0 004.8 6.95a3.408 3.408 0 001.127 1.545c.226-.568.563-1.08.983-1.51zM7 9.05a3.406 3.406 0 011-1.442c.449.381.798.877 1 1.442a3.4 3.4 0 01-2 0zm-1.377.689a4.617 4.617 0 01-1.897-2.233 3.4 3.4 0 003.184 5.91 4.585 4.585 0 01-1.287-3.677zM8 12.793a3.393 3.393 0 01-1.2-2.551 4.605 4.605 0 002.4 0 3.393 3.393 0 01-1.2 2.55zm1.09.622a4.585 4.585 0 001.287-3.677 4.617 4.617 0 001.896-2.233 3.4 3.4 0 01-3.183 5.91z"
    />
  </svg>
);

export default SvgIconPronouns;
