import React from 'react';

const SvgIconReduce = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M32.464 16.475H3.536a.322.322 0 00-.322.322v2.41c0 .177.145.322.322.322h28.928a.322.322 0 00.322-.322v-2.41a.322.322 0 00-.322-.322z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgIconReduce;
