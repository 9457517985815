import React from 'react';

const SvgAmendmentKitchen = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.833 6.551C4.833 4.402 6.55 2.7 8.647 2.7a3.825 3.825 0 013.854 3.854v.012a1.17 1.17 0 01-1.126 1.12h-.011c-.655 0-1.13-.544-1.13-1.132a1.56 1.56 0 00-1.586-1.587A1.555 1.555 0 007.1 6.547v4.489h27.144A1.075 1.075 0 0135.3 12.14v20.002a1.074 1.074 0 01-1.057 1.1H1.762c-.617 0-1.061-.514-1.061-1.096V12.14a1.074 1.074 0 011.057-1.103h3.074V6.551zM2.93 13.305v17.668h13.937V13.305H2.93zm16.201 0v2.733h13.935v-2.733H19.133zm0 5.003v12.665h13.935V18.308H19.133zm2.131 3.93h9.646a1.106 1.106 0 011.096 1.092v5.084a1.105 1.105 0 01-1.096 1.092h-9.65a1.074 1.074 0 01-1.057-1.1v-5.069a1.075 1.075 0 011.057-1.099h.005zm1.17 2.267v2.734h7.302v-2.734h-7.303z"
    />
  </svg>
);

export default SvgAmendmentKitchen;
