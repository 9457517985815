import React from 'react';

const SvgRefundValidate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={80} height={80} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={80} height={80} rx={40} fill="#FFC543" />
    <path
      d="M57.267 38.126h-1.33v-3.983a1.326 1.326 0 00-1.328-1.33H22.734a3.99 3.99 0 00-3.984 3.985v23.906a3.99 3.99 0 003.984 3.984h34.533a1.328 1.328 0 001.328-1.328V39.454a1.328 1.328 0 00-1.328-1.328z"
      fill="#3F2D51"
    />
    <path d="M22.734 35.469h30.547v2.656H22.734a1.328 1.328 0 010-2.656z" fill="#FAFAFA" />
    <path
      d="M59.922 54.063h-7.969a3.986 3.986 0 010-7.97h7.969a1.329 1.329 0 011.328 1.329v5.312a1.328 1.328 0 01-1.328 1.33z"
      fill="#AAB3F7"
    />
    <path
      d="M52.774 38.126l-4.946-9.892a1.333 1.333 0 00-1.784-.595L25.077 38.126h27.697z"
      fill="#FE544A"
    />
    <path
      d="M21.406 38.126h26.056l-4.947-9.892a1.33 1.33 0 00-1.783-.592l-19.326 9.663v.82z"
      fill="#FF8C9C"
    />
    <circle cx={51.954} cy={50.079} r={1.992} fill="#2A41EA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.019 22.936c3.352-3.368 7.932-5.436 12.977-5.436 7.255 0 13.556 4.28 16.61 10.547a1.25 1.25 0 102.248-1.095C55.408 19.883 48.268 15 39.996 15c-5.83 0-11.103 2.428-14.911 6.337l-2.463-2.035a.5.5 0 00-.812.302l-1.431 8.487a.5.5 0 00.667.551l8.066-3.003a.5.5 0 00.144-.854l-2.237-1.849z"
      fill="#fff"
    />
  </svg>
);

export default SvgRefundValidate;
