import React from 'react';

const SvgClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      d="M20.08 17.998L30.629 5.426a.32.32 0 00-.245-.526h-3.207a.655.655 0 00-.494.229l-8.698 10.37-8.7-10.37a.642.642 0 00-.493-.229H5.584a.32.32 0 00-.245.526l10.547 12.572L5.34 30.57a.32.32 0 00.245.526h3.207c.189 0 .37-.084.494-.229l8.699-10.37 8.698 10.37c.12.145.302.23.494.23h3.207a.32.32 0 00.245-.527L20.08 17.998z"
      fillOpacity={0.85}
    />
  </svg>
);

export default SvgClose;
