import React from 'react';

const SvgFacilityDesk = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M14 6.5v8.962h5.467m.227-8.962v10.791m-5.541-7.346h5.506M16.19 8.253h1.302m-1.386 3.478v2.048M3.174 6.5h18.014M5.104 13.417H14M5.07 6.5v10.791"
      stroke="#4C4C4C"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgFacilityDesk;
