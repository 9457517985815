import React from 'react';

const SvgPinterest = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 51" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 0C11.195 0 0 11.193 0 25c0 10.237 6.156 19.032 14.965 22.898-.07-1.745-.013-3.84.435-5.74l3.217-13.623s-.8-1.596-.8-3.956c0-3.704 2.148-6.471 4.822-6.471 2.274 0 3.373 1.708 3.373 3.753 0 2.286-1.458 5.705-2.208 8.872-.626 2.652 1.33 4.815 3.946 4.815 4.737 0 7.927-6.083 7.927-13.292 0-5.479-3.69-9.58-10.403-9.58-7.583 0-12.308 5.655-12.308 11.973 0 2.178.642 3.714 1.648 4.903.463.546.527.766.36 1.393-.12.46-.396 1.568-.51 2.007-.166.633-.68.86-1.251.625-3.493-1.425-5.12-5.25-5.12-9.55 0-7.102 5.99-15.618 17.867-15.618 9.545 0 15.828 6.907 15.828 14.321 0 9.807-5.453 17.134-13.49 17.134-2.699 0-5.238-1.459-6.107-3.116 0 0-1.452 5.76-1.76 6.873-.53 1.927-1.567 3.853-2.516 5.355a24.976 24.976 0 007.086 1.025c13.805 0 24.999-11.193 24.999-25C50 11.192 38.806 0 25 0"
      fill="#8C8197"
    />
  </svg>
);

export default SvgPinterest;
