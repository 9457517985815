import React from 'react';

const SvgInspectionComplete = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.34 5.856c1.777 0 3.222 1.429 3.222 3.182v16.448a13.06 13.06 0 00-5.144-1.048c-7.236 0-13.102 5.866-13.102 13.102 0 2.11.499 4.105 1.386 5.87H10.136c-1.778 0-3.223-1.427-3.223-3.181V9.039c0-1.756 1.445-3.183 3.223-3.183H38.34z"
      fill="#FFC543"
    />
    <path
      d="M37.194 0l.16.002.246.013c2.529.204 4.509 2.303 4.509 4.85v20.869a13.072 13.072 0 00-2.675-.947V4.866l-.004-.123-.018-.182a2.234 2.234 0 00-2.216-1.912H5.913l-.123.003-.185.018a2.226 2.226 0 00-1.93 2.196v34.7l.004.122.017.183a2.234 2.234 0 002.217 1.911h18.105c.32.936.742 1.824 1.256 2.65H5.912l-.158-.003-.246-.013C2.98 44.212 1 42.114 1 39.566v-34.7l.002-.157.015-.244C1.22 1.96 3.34 0 5.913 0h31.28z"
      fill="#3F2D51"
    />
    <path
      d="M23.52 28.027H10.294a1.454 1.454 0 00-1.461 1.448 1.453 1.453 0 001.356 1.442l.105.005H23.52a1.455 1.455 0 001.46-1.447 1.453 1.453 0 00-1.356-1.442l-.104-.005zm5.542-9.56h-18.77a1.453 1.453 0 00-1.46 1.448 1.453 1.453 0 001.356 1.442l.105.004h18.77a1.453 1.453 0 001.46-1.446 1.454 1.454 0 00-1.356-1.443l-.105-.004zm-11.888-9.56h-6.881a1.454 1.454 0 00-1.461 1.447 1.453 1.453 0 001.356 1.443l.105.004h6.881a1.455 1.455 0 001.461-1.447 1.453 1.453 0 00-1.356-1.443l-.105-.004z"
      fill="#3F2D51"
    />
    <path
      d="M36.418 48c5.777 0 10.46-4.683 10.46-10.46 0-5.777-4.683-10.46-10.46-10.46-5.776 0-10.46 4.683-10.46 10.46 0 5.777 4.684 10.46 10.46 10.46z"
      fill="#4FE0BE"
    />
    <path
      d="M34.829 40.734a.489.489 0 01-.347-.143l-2.874-2.86a.483.483 0 010-.689.491.491 0 01.692 0l2.529 2.516 5.204-5.178a.491.491 0 01.835.344.485.485 0 01-.143.344l-5.55 5.524a.49.49 0 01-.346.143z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.6}
    />
  </svg>
);

export default SvgInspectionComplete;
