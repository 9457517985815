import React from 'react';

const SvgFacilityProtector = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#4C4C4C"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.446 18.323h19.47v-4.109H10.313m10.874-.269V8.92h-6.154M2.51 13.627h2.816m1.244-1.764l2.51 1.717-2.482 1.775zM2.561 8.855c-.913.006.84.006 5.262 0 1.265-.023 2.446-.43 3.544-1.221 1.004-.917 2.193-2.317 3.666-2.315 2.463.067 2.256 2.731 1.526 3.471"
      />
      <path
        d="M10.313 13.078c1.873-1.633 2.918-3.087 3.134-4.36.274-1.12 1.013-1.76 2.217-1.923"
        stroke="#4C4C4C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.333 10.83a.556.556 0 11-1.11 0 .556.556 0 011.11 0m2.445-.001a.556.556 0 11-1.112-.001.556.556 0 011.112 0m-4.889 1.694a.556.556 0 11-1.111 0 .556.556 0 011.11 0m2.445.034a.556.556 0 11-1.111 0 .556.556 0 011.111 0m2.445-.001a.556.556 0 11-1.112 0 .556.556 0 011.112 0"
        fill="#4C4C4C"
      />
    </g>
  </svg>
);

export default SvgFacilityProtector;
