import React from 'react';

const SvgLanguagesBg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 320 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#languages-bg_svg__a)">
      <path fill="#3F2D51" d="M0 0h320v160H0z" />
      <path
        d="M170.53 169.96l-.04.07 15.3 23.6 160-3.06 44.15-88.3c-8.74 6.4-41.44 17.22-102.29 9.18-75.98-10.05-103.98 36.62-117.12 58.5z"
        fill="#46DDB0"
      />
      <path
        d="M171.37 206.31c23.6-37.6-9.62-138.14-83.5-147.32L-22.73 45l-3.94 134.65c51 26.08 179.15 56.74 198.04 26.66z"
        fill="#FA3B3A"
      />
      <path
        d="M-27 29.61L-1.5 155.5C146 139 163.5 50.9 178.5-10.6H-17.24L-27 29.61z"
        fill="#FD758B"
      />
      <path
        d="M291.45 55.63C249.75 18.83 255.82-9.2 280.15-45c26.36 4.39 79.86 13.5 82.99 14.86L374 49.56 325.34 110c2.6-5.85-.52-24.92-33.89-54.37z"
        fill="#1F23E5"
      />
      <path
        d="M193.5 168.5c-18.36-6.7-78.95-16.14-99.5-33C49.5 99 55.5 32.5-44.5 69l-31.13 137.75L193.5 168.5z"
        fill="#FEBA34"
      />
    </g>
    <defs>
      <clipPath id="languages-bg_svg__a">
        <path fill="#fff" d="M0 0h320v160H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLanguagesBg;
