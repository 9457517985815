import { getDomain } from 'tldjs';
import Cookies from 'js-cookie';

let domain = '';
if (process.browser) {
  domain = getDomain(window.location.href) || '';
}

export const domainStr = `domain=${domain};`;
export const setCookie = (cookieName: string, cookieValue: string, expiresDay: number) => {
  Cookies.set(cookieName, cookieValue, { path: '/', domain: domain, expires: expiresDay });
};

export const getCookie = (cookieName: string) => {
  return Cookies.get(cookieName) || '';
};

export const deleteCookie = (name: string) => {
  Cookies.remove(name, { path: '/', domain: domain });
};

export default {
  setCookie,
  getCookie,
  deleteCookie,
};
